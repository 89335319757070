import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import GetProject from '../../APIs/Project/GetProject'

import PageHeading from '../../components/PageHeading'
import ProjectCard from './ProjectCard'

export default function ProjectPDashboard() {

    let [loader, setLoader] = useState(1)
    let [projects, setProjects] = useState([])
    let [pageNumber, setPageNumber] = useState(1)
    let [pageSize, setPageSize] = useState(10)
    let [numberPages, setNumberOfPages] = useState([])

    let getData = async () => {
        try {
            let allProjects = await GetProject();
            if (allProjects.status === 1) {
                setProjects(allProjects.responseValue.reverse());
                // console.log("Math.round(allProjects.responseValue/pageSize)",allProjects.responseValue/pageSize))
                setNumberOfPages(Math.round(allProjects.responseValue.length / pageSize) === 0 ? [0] : new Array(Math.round(allProjects.responseValue.length / pageSize)).fill(0))
                setLoader(0);
            } else {
                setLoader(0);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoader(0);
        }
    }
    useEffect(() => {
        getData()
    }, [])
    return (
        <>
            <div className='d-flex flex-column flex-md-row justify-content-between pe-3 ps-3'>
                <PageHeading pagename={"All Projects List"} />
                {/* <button className="btn btn-primary" type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addproject">+ Add New Project</button> */}
            </div>
            <div className='container-fluid  pt-4'>
                <div className='project-conatiner mb-4'>
                    {
                        projects && projects.slice(((pageSize * pageNumber) - pageSize), pageSize * pageNumber).map((val, ind) => {
                            return (
                                <ProjectCard projectData={val} getData={getData} />
                            )
                        })
                    }

                </div>
                <nav aria-label="Page navigation example">
                    <ul class="pagination justify-content-end">
                        <li class="page-item"><Link class="page-link" to="#" onClick={pageNumber >= 2 ? () => { setPageNumber(state => state - 1) } : () => { console.log("data", pageNumber) }}>Previous</Link></li>
                        {
                            numberPages && numberPages.map((val, ind) => {
                                return (

                                    <li class="page-item"><Link class="page-link" to="#" onClick={() => { setPageNumber(ind + 1) }}>{ind + 1}</Link></li>
                                )
                            })
                        }
                        {/* <li class="page-item"><Link class="page-link" to="#" onClick={() => { setPageNumber(2) }}>2</Link></li>
                        <li class="page-item"><Link class="page-link" to="#" onClick={() => { setPageNumber(3) }}>3</Link></li> */}

                        <li class="page-item"><Link class="page-link" to="#" onClick={pageNumber < (Math.round(projects.length / pageSize) === (projects.length / pageSize) ? Math.round(projects.length / pageSize) : Math.round(projects.length / pageSize) + 1) ? () => { setPageNumber(state => state + 1) } : () => { }}>Next</Link></li>
                    </ul>
                </nav>
            </div>
        </>
    )
}
