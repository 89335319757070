import React, { useEffect, useState } from 'react'
import PageHeading from '../../components/PageHeading'
import GetProjectPlotDetailsByProjectId from '../../APIs/ProjectPlotDetails/GetProjectPlotDetailsByProjectId'
import { useParams } from 'react-router-dom';
import InputBoxAlphaNumarics from '../../components/InputBoxAlphaNumarics';
import InsertProjectPlotDetails from '../../APIs/ProjectPlotDetails/InsertProjectPlotDetails';
import UpdateProjectPlotDetails from '../../APIs/ProjectPlotDetails/UpdateProjectPlotDetails';
import DeleteProjectPlotDetails from '../../APIs/ProjectPlotDetails/DeleteProjectPlotDetails';
import Loader from '../../components/Loader';
import SideMessage from '../../components/SideMessage';

export default function AddPlotDetails() {
    let [editIndex, setEditIndex] = useState("")
    let { id } = useParams();
    let [sideToster, setSideToster] = useState({
        show: 0,
        message: '',
        type: ''
    })
    let [loader, setLoader] = useState(0)
    
    let row = {
        "id": Math.random(),
        "projectId": parseInt(id),
        "plotNo": "",
        "plotArea": "",
        "plotStatus": 0,
        "statusFront": 0,
        "userId": window.userId,
    }

    let [plotDataList, setPlotDataList] = useState([])

    let getdata = async () => {
        setLoader(1)
        let response = await GetProjectPlotDetailsByProjectId(id)
        if (response.status === 1) {
            setPlotDataList([...response.responseValue, row])
            setEditIndex("")
        }
        else {
            setSideToster({
                show: 1,
                message: response.responseValue,
                type: 'Alert'
            })
        }
        setLoader(0)
    }

    let handleSave = async () => {
        let filterData = plotDataList.filter((value) => value.id < 1)
        let validation = filterData.filter(value => value.plotNo === "" || value.plotArea === "" || value.plotStatus === 0)
        if (validation.length === 0) {
            let response = await InsertProjectPlotDetails(validation)
            if (response.status === 1) {
                getdata()
            }
            else {
                setSideToster({
                    show: 1,
                    message: response.responseValue,
                    type: 'Alert'
                })
            }
        }
        else {
            setSideToster({
                show: 1,
                message: "Please Fill All Fields",
                type: 'Alert'
            })
        }

    }

    let handleSaveUpdae = async () => {
        let updatedata = plotDataList[editIndex]
        console.log("daa", updatedata)
        if (updatedata.length !== 0) {
            let response = await UpdateProjectPlotDetails(updatedata)
            if (response.status === 1) {
                getdata()
            }
            else {
                setSideToster({
                    show: 1,
                    message: response.responseValue,
                    type: 'Alert'
                })
            }
        }
        else {
            setSideToster({
                show: 1,
                message: "Please Fill All Fields",
                type: 'Alert'
            })
        }
    }
    let handleAddPlot = () => {
        setPlotDataList((prev) => ([...prev, row]))
    }

    let handleDeleteRow = (ind) => {
        if (plotDataList.length === 1 && ind === 0) {
            console.log("Entered single item deletion")
            setPlotDataList([row]);
        } else if (plotDataList.length > 1) {
            let temp = [...plotDataList];
            temp.splice(ind, 1);
            console.log("temp value ", temp)
            setPlotDataList(temp);
        }
    };

    let handleDeleteRowAPI = async (id, ind) => {
        setLoader(1)
        let response = await DeleteProjectPlotDetails(id)
        if (response.status === 1) {
            let temp = [...plotDataList];
            temp.splice(ind, 1);
            setPlotDataList(temp);
        }
        else {
            setSideToster({
                show: 1,
                message: response.responseValue,
                type: 'Alert'
            })
        }
        setLoader(0)
    }

    let handleChnage = (e, ind) => {
        let temp = [...plotDataList]
        let value = e.target.value
        let name = e.target.name
        temp[ind][name] = value;
        setPlotDataList([...temp])
    }

    useEffect(() => {
        getdata()
    }, [])

    return (
        <>
            <div className='d-flex flex-column flex-md-row justify-content-between pe-3 ps-3'>
                <PageHeading pagename={"Add Plot Details"} />
                {/* <button className="btn btn-primary" type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addproject">+ Add New Project</button> */}
            </div>
            <div className='d-flex flex-row justify-content-end pe-3 mb-2'>

                <button className="btn btn-primary" type="button" onClick={handleAddPlot}>+ Add New Plot</button>
            </div>
            <div className='container-fluid landformconatiner p-4' id="conatinerId" style={{ maxHeight: "75vh" }}>


                {
                    plotDataList && plotDataList.map((val, ind) => {
                        return (<>
                            <div className='wrapform mt-4' key={val.id}>
                                <div className='d-flex flex-column gap-1'>
                                    <label className='form-label'>Plot Number</label>
                                    {/* <InputBoxAlphaNumarics name="plotNo" value={val.plotNo} placeholder='Enter Plot No.' setSendForm={plotDataList[ind]} range={15} /> */}
                                    <input type="text" className='form-input-box' name="plotNo" value={val.plotNo} id={val.id} onChange={(e) => handleChnage(e, ind)} placeholder="Enter Plot No." disabled={editIndex === "" && val.id >= 1 ? true : false} />
                                </div>
                                <div className='d-flex flex-column gap-1'>
                                    <label className='form-label'>Plot Area</label>
                                    {/* <InputBoxAlphaNumarics name="plotArea" value={val.plotArea} placeholder='Enter Plot Area' setSendForm={plotDataList[ind]} range={15} /> */}
                                    <input type="text" className='form-input-box' name="plotArea" value={val.plotArea} id={val.id} onChange={(e) => handleChnage(e, ind)} placeholder="Enter Plot Area" disabled={editIndex === "" && val.id >= 1 ? true : false} />

                                </div>
                                {/* <div className='d-flex flex-column flex-md-row justify-content-center align-items-center gap-3'> */}
                                <div className='d-flex flex-column gap-1'>
                                    <label className='form-label'>Plot Status</label>
                                    <select className='form-input-box form-select pe-5' value={val.plotStatus} name='plotStatus' onChange={(e) => handleChnage(e, ind)} disabled={editIndex === "" && val.id >= 1 ? true : false}>
                                        <option value="-1">--Select Plot Status --</option>
                                        <option value={1}>Free</option>
                                        <option value={2}>Hold</option>
                                        <option value={3}>Sold</option>
                                    </select>
                                </div>
                                <div className='d-flex flex-column gap-1 '>
                                    <label className='form-label'>Actions</label>
                                    <div className='d-flex flex-column flex-md-row gap-2'>
                                        {
                                            val.statusFront === 0 ?
                                                <>
                                                    {/* <button className="btn btn-primary" type="button" onClick={handleAddPlot}>Save</button> */}
                                                    <button className="btn btn-danger" type="button" onClick={() => handleDeleteRow(ind)}>Delete</button>
                                                </> :
                                                <>

                                                    {
                                                        editIndex !== "" && editIndex === ind ?

                                                            <>
                                                                <button className="btn btn-warning text-white" type="button" onClick={() => handleSaveUpdae(ind)}>Update Status</button>
                                                                <button className="btn btn-danger" type="button" onClick={() => setEditIndex("")}>Cancle</button>

                                                            </>
                                                            :
                                                            <>
                                                                <button className="btn btn-warning text-white" type="button" onClick={() => setEditIndex(ind)}>Edit</button>
                                                                <button className="btn btn-danger" type="button" onClick={() => { handleDeleteRowAPI(val.id, ind) }}>Delete</button>
                                                            </>

                                                    }

                                                </>

                                        }

                                    </div>


                                </div>
                            </div>

                            {/* </div> */}
                        </>)
                    })
                }

            </div>
            <div className='d-flex flex-row justify-content-end pe-3 mt-3'>
                <button className="btn btn-primary" type="button" onClick={handleSave}>Save</button>
            </div>
            <Loader show={loader} />

            {
                sideToster.show === 1 ?
                    <SideMessage close={setSideToster} show={sideToster.show} type={sideToster.type} message={sideToster.message} />
                    : ""
            }
        </>
    )
}
