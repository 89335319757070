export default async function UpdateLandMaster(sendForm) {
    let url = window.RealestateService+  `/api/Land/UpdateLandMaster`
    let head = {
        // "content-type":"application/json",
        "accept":"*/*"
    }
    let data ={}
    let response = await fetch(url, {
        method:"POST",
        headers:head,
        body:sendForm
    }).then(res=>res.json()).then(data).catch(res=>res)

    return response
}