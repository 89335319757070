import React, { useEffect, useState } from 'react'
import GetExpenseCategory from '../../APIs/AddExpense/GetExpenseCategory';
import GetUnitMaster from '../../APIs/Master/UnitMaster/GetUnitMaster';
import SideMessage from '../../components/SideMessage';
import InsertVendorTerm from '../../APIs/AddExpense/InsertVendorTerm';
import InputBoxNumber from '../../components/InputBoxNumber';

export default function AddTermModal(props) {
    const [materialList, setMaterialList] = useState([]);
    const [unitList, setUnitList] = useState([]);
    let [sendForm, setSendForm] = useState({
        "vendorId": parseInt(props.VendorId),
        "name": "",
        "materialId": 0,
        "unitId": 0,
        "termNo": 0,
        "quantity": 0,
        "userId": 0,
    })
    let [sideToster, setSideToster] = useState({
        show: 0,
        message: '',
        type: ''
    })
    const handleChange = (e) => {
        const { name, value } = e.target;
        setSendForm((prev) => ({
            ...prev,
            [name]: name === 'materialId' || name === 'unitId' || name === 'quantity'
                ? parseInt(value) || 0
                : value,
            "vendorId": parseInt(props.VendorId),
            "userId": window.userId
        }));
    }

    const GetAllmaterial = async () => {
        const response = await GetExpenseCategory()
        if (response.status === 1) {
            setMaterialList(response.responseValue)
        }
        else {
            setMaterialList([])
        }
    }
    const GetAllUnit = async () => {
        const response = await GetUnitMaster()
        if (response.status === 1) {
            setUnitList(response.responseValue)
        }
        else {
            setUnitList([])
        }
    }
    let clear = () => {
        setSendForm({
            "vendorId": 0,
            "name": "",
            "materialId": 0,
            "unitId": 0,
            "termNo": 0,
            "quantity": '',
        })
    }
    const handleSave = async () => {
        if (sendForm.name.trim() === '' || sendForm.name === undefined) {
            
            setSideToster({
                show: 1,
                message: 'Enter Term Name',
                type: 'Alert'
            })
        }
        else if (sendForm.quantity === '' || sendForm.quantity === undefined || sendForm.quantity === 0) {
            setSideToster({
                show: 1,
                message: 'Please Enter Quantity',
                type: 'Alert'
            })
        }
        else if (sendForm.materialId === '0' || sendForm.materialId === undefined || sendForm.materialId === 0) {
            setSideToster({
                show: 1,
                message: 'Please Select Material',
                type: 'Alert'
            })
        }
        else if (sendForm.unitId === '0' || sendForm.unitId === undefined || sendForm.unitId === 0) {
            setSideToster({
                show: 1,
                message: 'Please Select Unit',
                type: 'Alert'
            })
        }
        else if (sendForm.vendorId === 0 || sendForm.vendorId === '' || sendForm.vendorId === null || sendForm.vendorId === undefined
        ) {
            setSideToster({
                show: 1,
                message: "Please select vendor first !!",
                type: 'Alert'
            })
        }
        else {
            const response = await InsertVendorTerm(sendForm);
            if (response.status === 1) {
                clear();
                props.getTerms(parseInt(props.VendorId));
                props.setShowTermModal(0)
                setSideToster({
                    show: 1,
                    message: 'Data Saved',
                    type: 'Success'
                })
            }
            else {
                setSideToster({
                    show: 1,
                    message: response.message,
                    type: 'Alert'
                })
            }
        }
    }
    useEffect(() => {
        GetAllmaterial();
        GetAllUnit();
    }, [])
    return (
        <div class="modal fade show" id="addTerm" tabindex="-1" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="addTermLabel">Add Term</h1>
                        <button type="button" class="btn-close" onClick={() => props.setShowTermModal(0)}></button>
                    </div>
                    <div class="modal-body">

                        <div className='row pb-4'>
                            <div className='col pb-2 pb-md-0'>
                                <div className='d-flex flex-column gap-1'>
                                    <label className='form-label'>Term Name</label>
                                    <input className='form-input-box' type='text' value={sendForm.name} placeholder='Enter Name' name="name" onChange={handleChange} />
                                </div>
                            </div>
                            <div className="col-6 pb-2 pb-md-0">
                                <div className="d-flex flex-column gap-1">
                                    <label className="form-label">Quantity</label>
                                    {/* <input className='form-input-box' type='number' value={sendForm.quantity} placeholder='Enter Quantity' name="quantity" onChange={handleChange} /> */}
                                    <InputBoxNumber name="quantity" value={sendForm.quantity} placeholder='Enter Quantity' id="quantity" setSendForm={setSendForm} range={100}  />
                                </div>
                            </div>
                            <div className="col-6 pb-2 pb-md-0 mt-3">
                                <div className="d-flex flex-column gap-1">
                                    <label className="form-label">Material</label>
                                    <select className='form-input-box form-select pe-5' name='materialId' onChange={handleChange} value={sendForm.materialId}>
                                        <option value="0">--Select Material--</option>
                                        {
                                            materialList && materialList.map((val, ind) => {
                                                return (
                                                    <option value={val.id}>
                                                        {val.name}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="col-6 pb-2 pb-md-0 mt-3">
                                <div className="d-flex flex-column gap-1">
                                    <label className="form-label">Unit</label>
                                    <select className='form-input-box form-select pe-5' name='unitId' onChange={handleChange} value={sendForm.unitId}>
                                        <option value="0">--Select Unit--</option>
                                        {
                                            unitList && unitList.map((val, ind) => {
                                                return (
                                                    <option value={val.id}>
                                                        {val.name}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" onClick={handleSave}>Save changes</button>
                    </div>
                </div>
            </div>
            {
                sideToster.show === 1 ?
                    <SideMessage close={setSideToster} show={sideToster.show} type={sideToster.type} message={sideToster.message} />
                    : ""
            }
        </div>

    )
}
