import React, { useEffect, useState } from 'react'
import SideMessage from '../../../components/SideMessage'
import Loader from '../../../components/Loader'
import { Link } from 'react-router-dom'
import FilterTable from '../../../components/FilterTable'
import PageHeading from '../../../components/PageHeading'
import GetVendorDetails from '../../../APIs/AddVendor/GetVendorDetails'
import DeleteVendorDetails from '../../../APIs/AddVendor/DeleteVendorDetails'

export default function ShowIndividualVendor() {
    let [vendorList, setVendorList] = useState([])
    let [vendorTempList, setVendorTempList] = useState([])
    let [loader, setLoader] = useState(1)
    let [sideToster, setSideToster] = useState({
        show: 0,
        message: '',
        type: ''
    })
    let getData = async () => {
        let resp = await GetVendorDetails()
        if (resp.status === 1) {
            setVendorList(resp.responseValue.filter(val => val.vendorType === 0))
            setVendorTempList(resp.responseValue.filter(val => val.vendorType === 0))
            setLoader(0)
        }
        else {
            setLoader(0)
        }
    }

    let handleDelete = async (id) => {
        setLoader(1)
        let resp = await DeleteVendorDetails(id)
        if (resp.status === 1) {
            setLoader(0)
            getData()
            setSideToster({
                show: 1,
                message: 'Data Deleted',
                type: 'Success'
            })
        }
        else {
            setLoader(0)
            setSideToster({
                show: 1,
                message: resp.responseValue,
                type: 'Alert'
            })
        }
    }

    useEffect(() => {
        getData()
    }, [])
  return (
    <>
    <div className='container-fluid landformconatiner p-4 mt-3'>
        <div className='d-flex flex-column flex-md-row justify-content-between'>
            <PageHeading pagename="Vendor List" />
            <FilterTable tempList={vendorTempList} tempListMethod={setVendorTempList} originalList={vendorList} isSearch={true} isdate={false} isFilter={false} isExport={false} />
        </div>
        <div className='pt-4 d-none'>
            <table class="table">
                <thead>
                    <tr className='table-head'>
                        {/* <th>S.No.</th> */}
                        <th scope="col">Name</th>
                        <th scope="col">Invested Amount (&#8377;)</th>
                        <th scope="col">Left Amount (&#8377;)</th>
                        <th scope="col">Paid Amount (&#8377;)</th>
                        <th scope="col">Tenure</th>
                        <th scope="col">Status</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody className='table-body'>
                    {
                        vendorTempList && vendorTempList.map((val, ind) => {
                            return (
                                <tr >
                                    {/* <td>{ind + 1}</td> */}
                                    <td>{val.name}</td>
                                    <td>&#8377; {val.investedAmount}</td>
                                    <td>&#8377; {val.payingAmount}</td>
                                    <td>&#8377; {0}</td>
                                    <td>{"5 Year"}</td>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 
                                    <td>

                                        <div class="progress">
                                            <div class="progress-bar" role="progressbar" aria-label="Example with label" style={{ "width": isNaN(((0 / val.payingAmount)*100).toFixed(2))?0:((0 / val.payingAmount)*100).toFixed(2), fontSize: "0.8rem" }}  aria-valuemin="0" aria-valuemax="100">{isNaN(((0 / val.payingAmount)*100).toFixed(2))?0:((0 / val.payingAmount)*100).toFixed(2)}%</div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="dropdown dropend">
                                            {/* <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Dropdown button                                                                                 
                                            </button> */}                                                                                                                                 
                                            <i class="bi bi-three-dots-vertical " data-bs-toggle="dropdown" aria-expanded="false"></i>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          
                                            <ul className="dropdown-menu mr-3">                                                                                                                                                                                                                                                                                                                                                                                                                                         
                                                <li><Link className="dropdown-item" to={`/showvendortransactions/${val.id}`}>View</Link></li>
                                                <li><span className="dropdown-item" onClick={() => { handleDelete(val.id) }}>Delete</span></li>
                                                {/* <li onClick={handleLogout}><Link className="dropdown-item" href="#">Logout <i className="bi bi-box-arrow-right"></i></Link></li> */}
                                            </ul>
                                        </div>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  
                                    </td>
                                </tr>
                            )
                        })
                    }


                </tbody>
            </table>
        </div>





        <div className='pt-4 '>
            <table class="table">
                <thead>
                    <tr className='table-head'>
                        <th>S.No.</th>
                        <th scope="col">Name</th>
                        <th scope="col">Mobile No</th>
                        <th scope="col">EmailId</th>
                        {/* <th scope="col">Paid Amount (&#8377;)</th> */}
                        <th scope="col">Address</th>
                        {/* <th scope="col">Status</th>
                        <th scope="col">Action</th> */}
                    </tr>
                </thead>
                <tbody className='table-body'>
                    {
                        vendorTempList && vendorTempList.map((val, ind) => {
                            return (
                                <tr >
                                    <td>{ind + 1}</td>
                                    <td>{val.name}</td>
                                    <td>{val.mobileNo}</td>
                                    <td>{val.emailId}</td>
                                    <td>{val.address}</td>       
                                </tr>
                            )
                        })
                    }


                </tbody>
            </table>
        </div>


    </div>

    <Loader show={loader} />
    {
        sideToster.show === 1 ?
            <SideMessage handleShow={setSideToster} show={sideToster.show} type={sideToster.type} message={sideToster.message} />
            : ""
    }
</>  )
}
