import React, { useEffect, useState } from 'react'
import FileUpload from '../../components/FileUpload'
import InsertLandMaster from '../../APIs/Land/InsertLandMaster';
import ShowLand from './ShowLand/ShowLand';
import GetUnitMaster from '../../APIs/Master/UnitMaster/GetUnitMaster';
import GetStates from '../../APIs/Master/StateMaster/GetStates';
import GetCitiesByStateId from '../../APIs/Master/CityMaster/GetCitiesByStateId';
import GetUsers from '../../APIs/User/GetUsers';
import GetLandNumber from '../../APIs/Land/GetLandNumber';
import Loader from '../../components/Loader';
import SideMessage from '../../components/SideMessage';
import UpdateLandMaster from '../../APIs/Land/UpdateLandMaster';
import { isNumber } from 'highcharts';
import InputBoxNumber from '../../components/InputBoxNumber';
import InputBoxAlphaNumarics from '../../components/InputBoxAlphaNumarics';

export default function AddLand(props) {
  let [rawAddress, setRawAddress] = useState([])

  let [sendForm, setSendForm] = useState(
    {
      "landNo": 0,
      "projectId": JSON.parse(window.sessionStorage.getItem("ProjectData")).projectId,
      "length": 0,
      "breadth": 0,
      "landArea": "",
      "price": "",
      "unitId": "-1",
      "cityId": "-1",
      "stateId": "-1",
      "countryId": 0,
      "tahseelName": "",
      "villageName": "",
      "khasraNo": "",
      "pincode": "",
      "address": rawAddress,
      "landmark": "",
      "perUnitPrice": "",
      "noOfOwners": 1,
      "noOfOwnedBy": 0,
      "ownedByCategoryId": "1",
      "createdBy": window.userId
    }
  )

  let [isNewForm, setIsNewForm] = useState(1)
  let [loader, setLoader] = useState(0)
  let [sideToster, setSideToster] = useState({
    show: 0,
    message: '',
    type: ''
  })
  let [getland, setGetland] = useState(0)
  let [allImage, setAllImage] = useState([])
  let [unitList, setUnitList] = useState([])
  let [stateList, setStateList] = useState([])
  let [cityList, setCityList] = useState([])
  let [userList, setUserList] = useState([])
  let [unitName, setUnitName] = useState([])
  let [deletedImages, setDeletedImages] = useState([])


  let getData = async () => {
    let respUnit = await GetUnitMaster()
    let respState = await GetStates()
    let respLandNumber = await GetLandNumber()
    if (respUnit.status === 1) {
      setUnitList(respUnit.responseValue)
    }
    if (respState.status === 1) {
      setStateList(respState.responseValue)
    }
    if (respLandNumber.status === 1) {
      setSendForm({ ...sendForm, ["landNo"]: respLandNumber.responseValue.slice(4) })

      document.getElementById("landNo").value = respLandNumber.responseValue
      document.getElementById("landNo").disabled = true

    }


  }
  let getCity = async (id) => {
    let respCity = await GetCitiesByStateId(id);
    if (respCity.status === 1) {

      setCityList(respCity.responseValue)
    }
  }

  let handleChnage = async (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setSendForm({ ...sendForm, [name]: value })

    if (name === "stateId") {
      getCity(value)

      var dropdown = document.getElementsByName(name)[0];
      setRawAddress([rawAddress[0], rawAddress[1], rawAddress[2], dropdown.options[dropdown.selectedIndex].text, rawAddress[4]])
      setSendForm({ ...sendForm, [name]: value, ["address"]: [rawAddress[0], rawAddress[1], rawAddress[2], dropdown.options[dropdown.selectedIndex].text, rawAddress[4]].join(",") })
    }
    else if (name === "cityId") {
      var dropdown = document.getElementsByName(name)[0];

      setRawAddress([rawAddress[0], rawAddress[1], dropdown.options[dropdown.selectedIndex].text, rawAddress[3], rawAddress[4]])
      setSendForm({ ...sendForm, [name]: value, ["address"]: [rawAddress[0], rawAddress[1], dropdown.options[dropdown.selectedIndex].text, rawAddress[3], rawAddress[4]].join(",") })

    }
    else if (name === "tahseelName") {
      setRawAddress([rawAddress[0], value, rawAddress[2], rawAddress[3], rawAddress[4]])
      setSendForm({ ...sendForm, [name]: value, ["address"]: [rawAddress[0], value, rawAddress[2], rawAddress[3], rawAddress[4]].join(",") })

    }
    else if (name === "villageName") {
      setRawAddress([value, rawAddress[1], rawAddress[2], rawAddress[3], rawAddress[4]])
      setSendForm({ ...sendForm, [name]: value, ["address"]: [value, rawAddress[1], rawAddress[2], rawAddress[3], rawAddress[4]].join(",") })

    }
    else if (name === "pincode") {
      setRawAddress([rawAddress[0], rawAddress[1], rawAddress[2], rawAddress[3], value])
      setSendForm({ ...sendForm, [name]: value, ["address"]: [rawAddress[0], rawAddress[1], rawAddress[2], rawAddress[3], value].join(",") })

    }
    else if (name === "landArea") {
      if (sendForm.price !== 0) {
        let perunitprice = sendForm.landArea / value;
        setSendForm({ ...sendForm, ["perUnitPrice"]: parseFloat(perunitprice).toFixed(2), ["price"]: sendForm.price, [name]: value })

        document.getElementById("perUnitPrice").value = parseFloat(perunitprice).toFixed(2)
      }
    }
    else if (name === "price") {
      if (sendForm.landArea !== 0) {
        let perunitprice = value / sendForm.landArea;
        setSendForm({ ...sendForm, ["perUnitPrice"]: parseFloat(perunitprice).toFixed(2), ["price"]: value })

        document.getElementById("perUnitPrice").value = parseFloat(perunitprice).toFixed(2)
      }
    }
    else if (name === "ownedByCategoryId") {
      if (value === "1") {
        // setSendForm({ ...sendForm, ["noOfOwnedBy"]: 1 })
        document.getElementById("noOfOwnedBy").disabled = true;
        document.getElementById("noOfOwnedBy").value = 0;
      }
      else {
        document.getElementById("noOfOwnedBy").disabled = false;
      }
    }
    else if (name === "unitId") {
      setSendForm({ ...sendForm, "unitName": e.target.options[e.target.selectedIndex].text, [name]: value })

    }
  }
  let handleVerification = () => {
    console.log("sendForm.noOfOwners",)
    if (sendForm.ownedByCategoryId.toString() === "2" || sendForm.ownedByCategoryId.toString() === "3") {
      if (sendForm.noOfOwnedBy !== 0) {
        if (sendForm.noOfOwners.toString().trim() !== "" && sendForm.unitId.toString() !== "-1" && sendForm.landNo !== 0 && sendForm.landArea.toString().length > 0 && sendForm.price.toString().trim() !== "" && sendForm.unitId !== 0 && sendForm.cityId.toString() !== "-1" && sendForm.stateId.toString() !== "-1" && sendForm.tahseelName.trim() !== '' && sendForm.villageName.trim() !== '' && sendForm.khasraNo.trim() !== '' && (sendForm.pincode.trim() !== '' && sendForm.pincode.trim().length === 6) && sendForm.address.trim() !== '' && sendForm.landmark.trim() !== '') {
          return [true, '']
        }
        else if (sendForm.landNo === 0) {
          return [false, 'Please Enter Land Number']

        }

        else if (sendForm.noOfOwners.toString().trim() === "") {
          return [false, 'Please Enter Number of Land Owners']

        }

        else if (sendForm.landArea.toString().trim() === '') {
          return [false, 'Please Enter Landarea']
        }

        else if (sendForm.unitId.toString() === "-1") {
          return [false, 'Please Select Unit']

        }
        else if (sendForm.stateId.toString() === "-1") {
          return [false, 'Please Select State']

        }
        else if (sendForm.cityId.toString() === "-1") {
          return [false, 'Please Select City']

        }

        // else if (sendForm.landNo === 0) {
        //   return [false, 'Please Enter Land Number']

        // }
        else if (sendForm.tahseelName.trim() === '') {
          return [false, 'Please Enter Tahseel Name']

        }
        else if (sendForm.villageName.trim() === '') {
          return [false, 'Please Enter Village Name']

        }
        else if (sendForm.khasraNo.trim() === '') {
          return [false, 'Please Enter KhasraNo']

        }
        else if (sendForm.pincode.trim() === '' || sendForm.pincode.trim().length < 6) {
          if (sendForm.pincode.trim() === '') {
            return [false, 'Please Enter Pin Code']
          }
          else {
            return [false, 'Please Enter 6 Digits Pin Code']

          }

        }

        else if (sendForm.landmark.trim() === '') {
          return [false, 'Please Enter Landmark']

        }
        else if (sendForm.price === 0 || sendForm.price === "") {
          return [false, 'Please Enter Price']

        }
        else if (sendForm.address.trim() === '') {
          return [false, 'Please Enter Address']

        }

      }
      else {
        return [false, 'Please Enter Number Of Investor']

      }
    }
    else {
      if (sendForm.ownedByCategoryId.toString() === "1") {
        if (sendForm.noOfOwners.toString().trim() !== "" && sendForm.unitId.toString() !== "-1" && sendForm.landNo !== 0 && Number(sendForm.landArea) > 0 && sendForm.price.toString().trim() !== "" && sendForm.unitId !== 0 && sendForm.cityId.toString() !== "-1" && sendForm.stateId.toString() !== "-1" && sendForm.tahseelName.trim() !== '' && sendForm.villageName.trim() !== '' && sendForm.khasraNo.trim() !== '' && (sendForm.pincode.trim() !== '' && sendForm.pincode.trim().length === 6) && sendForm.address.trim() !== '' && sendForm.landmark.trim() !== '') {
          return [true, '']
        }
        else if (sendForm.landNo === 0) {
          return [false, 'Please Enter Land Number']

        }
        else if (sendForm.noOfOwners.toString().trim() === "") {
          return [false, 'Please Enter Number of Land Owners']

        }
        else if (sendForm.landArea.toString().trim() === '') {
          return [false, 'Please Enter Landarea']
        }

        else if (sendForm.unitId.toString() === "-1") {
          return [false, 'Please Select Unit']

        }
        else if (sendForm.stateId.toString() === "-1") {
          return [false, 'Please Select State']

        }
        else if (sendForm.cityId.toString() === "-1") {
          return [false, 'Please Select City']

        }
        // else if (sendForm.landNo === 0) {
        //   return [false, 'Please Enter Land Number']

        // }
        else if (sendForm.tahseelName.trim() === '') {
          return [false, 'Please Enter Tahseel Name']

        }
        else if (sendForm.villageName.trim() === '') {
          return [false, 'Please Enter Village Name']

        }
        else if (sendForm.khasraNo.trim() === '') {
          return [false, 'Please Enter KhasraNo']

        }
        else if (sendForm.pincode.trim() === '' || sendForm.pincode.trim().length < 6) {
          if (sendForm.pincode.trim() === '') {
            return [false, 'Please Enter Pin Code']
          }
          else {
            return [false, 'Please Enter 6 Digits Pin Code']

          }

        }

        else if (sendForm.landmark.trim() === '') {
          return [false, 'Please Enter Landmark']

        }
        else if (sendForm.price === 0 || sendForm.price === "") {
          return [false, 'Please Enter Price']

        }
        else if (sendForm.address.trim() === '') {
          return [false, 'Please Enter Address']

        }

      }
      else {
        return [false, 'Somthing went wrong']

      }


    }

    // else if (sendForm.percentage.trim() === '') {
    //   return [false, 'Please Enter percentage']

    // }
  }
  let handleSave = async () => {
    let validate = handleVerification()
    if (validate[0]) {
      setLoader(1)
      let formData = new FormData()
      Object.keys(sendForm).map((name) => {
        formData.append(name, sendForm[name]);
      })
      allImage.map((val, ind) => {
        formData.append("landFiles", val);
      })
      let resp = await InsertLandMaster(formData)
      if (resp.status === 1) {
        setLoader(0)
        let sessiondata = JSON.parse(window.sessionStorage.getItem("ProjectData"))
      console.log("form", sessiondata)

        window.sessionStorage.setItem("ProjectData", JSON.stringify({ ...sessiondata, "landData": resp.responseValue }))
        setGetland(getData + 1)
        if (sendForm["noOfOwnedBy"] !== 0) {
          props.handleActiveTab(1)
        }
        else {
          props.handleActiveTab(2)

        }
      }
      else {
        setSideToster({
          show: 1,
          message: resp.responseValue,
          type: 'Alert'
        })
        setLoader(0)

      }
    }
    else {
      setSideToster({
        show: 1,
        message: validate[1],
        type: 'Alert'
      })
    }
  }
  let handleUpdate = async () => {
    let validate = handleVerification()
    console.log("vali", validate)
    if (validate[0]) {
      setLoader(1)
      let formData = new FormData()
      Object.keys(sendForm).map((name) => {
        if (name === "landNo") {

          formData.append(name, sendForm[name].slice(4));

        }
        else {

          formData.append(name, sendForm[name]);
        }
      })
      if (allImage.length !== 0) {
        allImage.map((val, ind) => {
          formData.append("landFiles", val);
        })
      }
      else {
        formData.append("landFiles", []);

      }
      if (deletedImages.length !== 0) {
        deletedImages.map((val, ind) => {
          formData.append("landImageIdList", val);
        })
      }

      // console.log("delete Id", deletedImages)
      else {
        formData.append("landImageIdList", [0]);

      }

      // formData.append("landImageIdList", deletedImages)
      console.log("form", sendForm)
      let resp = await UpdateLandMaster(formData)
      if (resp.status === 1) {
        setLoader(0)
        let sessiondata = JSON.parse(window.sessionStorage.getItem("ProjectData"))
        console.log("sendForm", sendForm)
        window.sessionStorage.setItem("ProjectData", JSON.stringify({ ...sessiondata, "landData": resp.responseValue }))
        setGetland(getData + 1)
        if (parseInt(sendForm.ownedByCategoryId) !== 1) {
          props.handleActiveTab(1)
        }
        else {
          props.handleActiveTab(2)
        }
      }
      else {
        setSideToster({
          show: 1,
          message: resp.responseValue,
          type: 'Alert'
        })
        setLoader(0)

      }
    }
    else {
      setSideToster({
        show: 1,
        message: validate[1],
        type: 'Alert'
      })
    }
  }

  // let handleEdit = (data) => {
  //   getCity(data.stateId)
  //   console.log("data0", data)
  //   setSendForm(data)
  // }
  useEffect(() => {
    getData()
    if (JSON.parse(window.sessionStorage.getItem("ProjectData")).landData) {
      console.log("data", JSON.parse(window.sessionStorage.getItem("ProjectData")).landData)
      setLoader(1)
      let data = JSON.parse(window.sessionStorage.getItem("ProjectData")).landData
      getCity(data.stateId)
      setAllImage(data.imageDetails === undefined ? [] : data.imageDetails)
      // console.log("on get time", data.imageDetails)
      setIsNewForm(0)
      setTimeout(() => {
        let temp = data
        console.log("data", data)
        temp.perUnitPrice = data.price / data.landArea
        document.getElementById("perUnitPrice").value = parseFloat(temp.perUnitPrice).toFixed(2)
        setSendForm(temp)
        setLoader(0)

      }, 1000)

    }
    document.body.style.overflowY = 'scroll';
  }, [])
  return (

    <>
      {/* <div className='display-container'> */}

      {/* <ShowLand handleEdit={handleEdit} change={getland} /> */}
      {/* </div> */}
      <div className='container-fluid landformconatiner p-4' id="conatinerId">
        <div className='wrapform'>
          <div className='d-flex flex-column gap-1 d-none' >
            <label className='form-label'>Land Number</label>
            <input type='text' name='landNo' id="landNo" value={sendForm.landNo} onChange={handleChnage} placeholder='Enter Land Number' className='form-input-box' />
          </div>
          <div className='d-flex flex-column gap-1'>
            <label className='form-label'>Owned Category</label>
            <select className='form-input-box form-select pe-5' name='ownedByCategoryId' value={sendForm.ownedByCategoryId} onChange={handleChnage}>
              <option value="1">Vifa</option>
              <option value="2">Investor</option>
              <option value="3">Vifa and Investor</option>
            </select>
          </div>
          <div className='row'>
            {
              (parseInt(sendForm.ownedByCategoryId) !== 1 && parseInt(sendForm.ownedByCategoryId) !== 0) ?
                <div className='col-6 d-flex flex-column gap-1'>
                  <label className='form-label'>Number of Investor</label>
                  {/* <input type='number' min={1} name='noOfOwnedBy' onChange={handleChnage} value={sendForm.noOfOwnedBy} id="noOfOwnedBy" placeholder='Enter No. Of Owned' className='form-input-box' /> */}
                  {
                    isNewForm !== 1 ?

                      <InputBoxNumber name="noOfOwnedBy" value={sendForm.noOfOwnedBy} placeholder='Enter No. Of Owned' setSendForm={setSendForm} range={10} disabled={true} />
                      :
                      <InputBoxNumber name="noOfOwnedBy" value={sendForm.noOfOwnedBy} placeholder='Enter No. Of Owned' setSendForm={setSendForm} range={10} />

                  }

                </div> : ""
            }

            <div className='col-6 d-flex flex-column gap-1'>
              <label className='form-label'>Number of Land Owners</label>
              {/* <input type='number' name='noOfOwners' min={1} onChange={handleChnage} value={sendForm.noOfOwners} id="noOfOwners" defaultValue={1} placeholder='Enter No. Of Owners' className='form-input-box' /> */}
              
              {
                    isNewForm !== 1 ?

                      <InputBoxNumber name="noOfOwners" value={sendForm.noOfOwners} placeholder='Enter No. Of Owners' id="noOfOwners" setSendForm={setSendForm} range={10}  disabled={true} />
                      :
                      <InputBoxNumber name="noOfOwners" value={sendForm.noOfOwners} placeholder='Enter No. Of Owners' id="noOfOwners" setSendForm={setSendForm} range={10}  />

                  }
              

            </div>
          </div>
          {/* <div className='row pb-4'>
          <div className='col col-md-4 pb-2 pb-md-0'>
            <div className='d-flex flex-column gap-1'>
              <label className='form-label'>Land Number</label>
              <input type='text' name='landNo' id="landNo" value={sendForm.landNo} onChange={handleChnage} placeholder='Enter Land Number' className='form-input-box' />
            </div>
          </div>
          <div className='col col-md-4 pb-2 pb-md-0'>
            <div className='d-flex flex-column gap-1'>
              <label className='form-label'>Owned Category</label>
              <select className='form-input-box pe-5' name='ownedByCategoryId' value={sendForm.ownedByCategoryId} onChange={handleChnage}>
                <option value="1">Vifa</option>
                <option value="2">Investor</option>
                <option value="3">Vifa and Investor</option>
              </select>
            </div>
          </div>
          <div className='col col-md-4 pb-2 pb-md-0'>
            <div className='row  pe-3 me-0'>
              <div className='col-6 d-flex flex-column gap-1'>
                <label className='form-label'>Number of OwnedBy</label>
                <input type='number' name='noOfOwnedBy' onChange={handleChnage} value={sendForm.noOfOwnedBy} id="noOfOwnedBy" placeholder='Enter No. Of Owned' className='form-input-box' disabled />
              </div>
              <div className='col-6 d-flex flex-column gap-1'>
                <label className='form-label'>Number of Owners</label>
                <input type='number' name='noOfOwners' onChange={handleChnage} value={sendForm.noOfOwners} id="noOfOwners" defaultValue={1} placeholder='Enter No. Of Owners' className='form-input-box' />
              </div>
            </div>

          </div>

        </div> */}
          <div className='d-flex flex-column gap-1'>
            <label className='form-label'>Land Area</label>
            {/* <input type='number' name='landArea'  onChange={handleChnage} value={sendForm.landArea} placeholder='Enter Land Area' className='form-input-box' /> */}
            <InputBoxNumber name="landArea" value={sendForm.landArea} placeholder='Enter Land Area' setSendForm={setSendForm} range={10} />
          </div>
          <div className='d-flex flex-column gap-1'>
            <label className='form-label'>Choose Unit</label>
            <select className='form-input-box form-select pe-5' name='unitId' value={sendForm.unitId} onChange={handleChnage}>
              <option value="-1">--Select Unit--</option>
              {
                unitList && unitList.map((val, ind) => {
                  return (
                    <option value={val.id}>
                      {val.name}
                    </option>
                  )
                })
              }
            </select>
          </div>
          <div className='d-flex flex-column gap-1'>
            <label className='form-label'>Select State</label>
            <select className='form-input-box form-select pe-5' name='stateId' value={sendForm.stateId} onChange={handleChnage}>
              <option value="-1">--Select State--</option>
              {
                stateList && stateList.map((val, ind) => {
                  return (
                    <option value={val.id}>
                      {val.stateName}
                    </option>
                  )
                })
              }
            </select>
          </div>
          {/* <div className='row pb-4'>
          <div className='col col-md-4 pb-2 pb-md-0'>
            <div className='d-flex flex-column gap-1'>
              <label className='form-label'>Land Area</label>
              <input type='number' name='landArea' onChange={handleChnage} value={sendForm.landArea} placeholder='Enter Land Area' className='form-input-box' />
            </div>
          </div>
          <div className='col col-md-4 pb-2 pb-md-0'>
            <div className='d-flex flex-column gap-1'>
              <label className='form-label'>Choose Unit</label>
              <select className='form-input-box pe-5' name='unitId' value={sendForm.unitId} onChange={handleChnage}>
                <option value="-1">--select Unit--</option>
                {
                  unitList && unitList.map((val, ind) => {
                    return (
                      <option value={val.id}>
                        {val.name}
                      </option>
                    )
                  })
                }
              </select>
            </div>
          </div>
          <div className='col col-md-4 pb-2 pb-md-0'>
            <div className='d-flex flex-column gap-1'>
              <label className='form-label'>Select State</label>
              <select className='form-input-box pe-5' name='stateId' value={sendForm.stateId} onChange={handleChnage}>
                <option value="-1">--select State--</option>
                {
                  stateList && stateList.map((val, ind) => {
                    return (
                      <option value={val.id}>
                        {val.stateName}
                      </option>
                    )
                  })
                }
              </select>
            </div>
          </div>
        </div> */}

          <div className='d-flex flex-column gap-1'>
            <label className='form-label'>Select City</label>
            <select className='form-input-box form-select pe-5' name='cityId' value={sendForm.cityId} onChange={handleChnage}>
              <option value="-1">--Select City--</option>
              {
                cityList && cityList.map((val, ind) => {
                  return (
                    <option value={val.id}>
                      {val.cityName}
                    </option>
                  )
                })
              }
            </select>
          </div>
          <div className='d-flex flex-column gap-1'>
            <label className='form-label'>Tahsheel</label>
            <input type='text' name='tahseelName' onChange={handleChnage} value={sendForm.tahseelName} placeholder='Enter Tahseel Name' className='form-input-box' />

            {/* <select className='form-input-box pe-5' name='price' onChange={handleChnage}>
                <option value="-1">--select Tahsheel--</option>

              </select> */}
          </div>
          <div className='d-flex flex-column gap-1'>
            <label className='form-label'>Village</label>
            <input type='text' name='villageName' onChange={handleChnage} value={sendForm.villageName} placeholder='Enter Village Name' className='form-input-box' />


            {/* <select className='form-input-box pe-5' name='price' onChange={handleChnage}>
                <option value="-1">--select Village--</option>

              </select> */}
          </div>
          {/* <div className='row pb-4'>


          <div className='col col-md-4 pb-2 pb-md-0'>
            <div className='d-flex flex-column gap-1'>
              <label className='form-label'>Select City</label>
              <select className='form-input-box pe-5' name='cityId' value={sendForm.cityId} onChange={handleChnage}>
                <option value="-1">--select City--</option>
                {
                  cityList && cityList.map((val, ind) => {
                    return (
                      <option value={val.id}>
                        {val.cityName}
                      </option>
                    )
                  })
                }
              </select>
            </div>
          </div>
          <div className='col col-md-4 pb-2 pb-md-0'>
            <div className='d-flex flex-column gap-1'>
              <label className='form-label'>Tahsheel</label>
              <input type='text' name='tahseelName' onChange={handleChnage} value={sendForm.tahseelName} placeholder='Enter Tahseel Name' className='form-input-box' />

              
            </div>
          </div>
          <div className='col col-md-4 pb-2 pb-md-0'>
            <div className='d-flex flex-column gap-1'>
              <label className='form-label'>Village</label>
              <input type='text' name='villageName' onChange={handleChnage} value={sendForm.villageName} placeholder='Enter Tahseel Name' className='form-input-box' />

              
            </div>
          </div>
        </div> */}

          <div className='d-flex flex-column gap-1'>
            <label className='form-label'>Khasra No</label>
            {/* <input type='string' name='khasraNo' onChange={handleChnage} value={sendForm.khasraNo} placeholder='Enter Khasra No' className='form-input-box' /> */}
            <InputBoxAlphaNumarics name="khasraNo" value={sendForm.khasraNo} placeholder='Enter Khasra No.' setSendForm={setSendForm} range={15} />

          </div>
          <div className='d-flex flex-column gap-1'>
            <label className='form-label'>Pin Code</label>
            {/* <input type='number' name='pincode' min={0} onChange={handleChnage} value={sendForm.pincode} placeholder='Enter Pin Code' className='form-input-box' /> */}
            <InputBoxNumber name="pincode" value={sendForm.pincode} placeholder='Enter Pin Code' errorMessage={"Please Enter min 6 digits"} setSendForm={setSendForm} range={6} />

          </div>
          <div className='d-flex flex-column gap-1'>
            <label className='form-label'>Land Mark</label>
            <input type='text' name='landmark' onChange={handleChnage} value={sendForm.landmark} placeholder='Enter Land Mark' className='form-input-box' />
          </div>
          {/* <div className='row pb-4'>


          <div className='col col-md-4 pb-2 pb-md-0'>
            <div className='d-flex flex-column gap-1'>
              <label className='form-label'>Khasra No</label>
              <input type='number' name='khasraNo' onChange={handleChnage} value={sendForm.khasraNo} placeholder='Enter Khasra No' className='form-input-box' />
            </div>
          </div>
          <div className='col col-md-4 pb-2 pb-md-0'>
            <div className='d-flex flex-column gap-1'>
              <label className='form-label'>Pin Code</label>
              <input type='number' name='pincode' onChange={handleChnage} value={sendForm.pincode} placeholder='Enter Pin Code' className='form-input-box' />
            </div>
          </div>
          <div className='col col-md-4 pb-2 pb-md-0'>
            <div className='d-flex flex-column gap-1'>
              <label className='form-label'>Land Mark</label>
              <input type='text' name='landmark' onChange={handleChnage} value={sendForm.landmark} placeholder='Enter Land Mark' className='form-input-box' />
            </div>
          </div>

        </div> */}

          <div className='d-flex flex-column gap-1'>
            <label className='form-label'>Total Land Price (&#8377;)</label>
            {/* <input type='number' name='price' min={0} onChange={handleChnage} value={sendForm.price} placeholder='Enter Total price' className='form-input-box' /> */}
            <InputBoxNumber name="price" value={sendForm.price} placeholder='Enter Total Price' setSendForm={setSendForm} range={10} />

          </div>
          <div className='d-flex flex-column gap-1'>
            <label className='form-label'>Per Unit Price ({sendForm.unitName})</label>
            <input type='number' name='perUnitPrice' min={0} id="perUnitPrice" value={parseFloat(sendForm.price / sendForm.landArea).toFixed(2)} onChange={handleChnage} placeholder='Enter Per Unit Price' className='form-input-box' disabled />
          </div>

          {/* <div className='row pb-4'>


          <div className='col col-md-4 pb-2 pb-md-0'>
            <div className='d-flex flex-column gap-1'>
              <label className='form-label'>Total Land price</label>
              <input type='number' name='price' onChange={handleChnage} value={sendForm.price} placeholder='Enter Total price' className='form-input-box' />
            </div>
          </div>
          <div className='col col-md-4 pb-2 pb-md-0'>
            <div className='d-flex flex-column gap-1'>
              <label className='form-label'>Per Unit price</label>
              <input type='number' name='perUnitPrice' id="perUnitPrice" value={sendForm.perUnitPrice} onChange={handleChnage} placeholder='Enter Per Unit price' className='form-input-box' disabled />
            </div>
          </div>
          <div className='col col-md-4 pb-2 pb-md-0'>
            <div className='d-flex flex-column gap-1'>
              <label className='form-label'>address</label>
              <textarea className='form-input-box' name='address' value={sendForm.address} placeholder='Enter address' onChange={handleChnage}></textarea>

            </div>
          </div>
        </div> */}

          {/* <div className='row pb-4'>

        </div> */}
        </div>
        <div className='row mt-3'>
          <div className='d-flex flex-column gap-1'>
            <label className='form-label'>Address</label>
            <textarea className='form-input-box pt-2' name='address' value={sendForm.address} disabled></textarea>
            {/* <input type='text' className='form-input-box' /> */}
          </div>
        </div>
        <FileUpload setDeletedImages={setDeletedImages} deletedImages={deletedImages} setAllImage={setAllImage} allImage={allImage} name="Property Pictures" />
      </div>
      <div className='d-flex flex-row justify-content-md-end'>
        {
          isNewForm === 1 ?
            <button className='btn export-save-btn' onClick={handleSave}>Save Land & Next</button>

            :
            <button className='btn export-save-btn' onClick={handleUpdate}>Update Land & Next</button>
        }
      </div>

      <Loader show={loader} />

      {
        sideToster.show === 1 ?
          <SideMessage close={setSideToster} show={sideToster.show} type={sideToster.type} message={sideToster.message} />
          : ""
      }
    </>


  )
}
