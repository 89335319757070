export default async function InsertProjectExpense(sendForm) {
    let url = window.RealestateService+  `/api/ExpenseDetails/InsertExpenseDetails`
    let head = {
        // "content-type":"multipart/form-data",
        "accept":"*/*"
    }
    let data ={}
    let response  = await fetch(url, {
        method:"POST",
        headers:head,
        body:sendForm
    }).then(res=>res.json()).then(data)

    return response
}