import React, { useEffect, useRef, useState } from 'react'
import PageHeading from '../../../components/PageHeading'
import graph from "../../../assets/images/graph.png"
import LandCost from "../../../assets/images/SalesIcon.png"
import ExpensesCost from "../../../assets/images/expensesicon.png"
import projectCost from "../../../assets/images/totalcosticon.png"
import ShowIndividualInvestor from './ShowIndividualInvestor'
import { useNavigate } from 'react-router-dom'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts';
// import GetInvestorDetails from '../../../APIs/AddInvestor/GetInvestorDetails'
import GetInvestorInvestmentDetails from '../../../APIs/AddInvestor/GetInvestorInvestmentDetails'
export default function IndividualInvestor() {

    let navigate = useNavigate()
    let [investorList, setInvestorList] = useState([])
    let [investorsTransactionOverview, setInvestorsTransactionOverview] = useState([])
    let [loader, setLoader] = useState(1)
    const chartRef = useRef(null);
    let getData = async () => {
        // let resp = await GetInvestorDetails()
        let resp = await GetInvestorInvestmentDetails()
        if (resp.status === 1) {
            setInvestorList(resp.responseValue.investorsTransactionDetails)
            setInvestorsTransactionOverview(resp.responseValue.investorsTransactionOverview)
            setLoader(0)
        }
        else {
            setLoader(0)
        }
    }
    useEffect(() => {
        getData();
        if (chartRef.current) {
            const chart = chartRef.current.chart;

            // Calculate the exact center of the plot area
            const centerX = (chart.plotWidth / 2.5) + chart.plotLeft;
            const centerY = (chart.plotHeight / 2.5) + chart.plotTop;
            const center1X = (chart.plotWidth / 2.5) + chart.plotLeft;
            const center1Y = (chart.plotHeight / 2) + chart.plotTop;

            // Add a custom label in the center
            // chart.renderer
            //     .label('Total Amount ', centerX, centerY)
            //     .attr({
            //         zIndex: 99,
            //     })
            //     .css({
            //         color: '#000',
            //         fontSize: '10px',
            //         fontWeight: "600"
            //     })
            //     .add();

            // chart.renderer
            //     .label('₹ ' + investorsTransactionOverview.amountTobePaid, center1X, center1Y)
            //     .attr({
            //         zIndex: 99,
            //     })
            //     .css({
            //         color: '#000',
            //         fontSize: '12px',
            //         fontWeight: "800"
            //     })
            //     .add();
        }
    }, []);

    const options = {
       chart: {
            type: 'pie',
            margin: [0, 0, 0, 0],
            spacingTop: 0,
            spacingBottom: 0,
            spacingLeft: 0,
            spacingRight: 0,
            height: '150px'
        },
        title: {
            verticalAlign: 'middle',
            floating: true,
            text:'₹ ' + investorsTransactionOverview.amountTobePaid,
            style: {
                fontSize: '14px',  // Set your desired font size here
                
            }


        },
        plotOptions: {
            pie: {
                innerSize: '70%',
                dataLabels: {
                    enabled: false,
                },
            },
            borderRadius: 600,
        },
        credits: {
            enabled: false, // Disable the Highcharts credits
        },
        series: [
            {
                name: 'Amount',
                data: [
                    { name: 'Amount To Be Paid', y: investorsTransactionOverview && investorsTransactionOverview.amountTobePaid, color: '#03D4C6' },
                    { name: 'Pending Amount', y: investorsTransactionOverview && investorsTransactionOverview.pendingAmount, color: '#00AFF0' },
                    { name: 'Paid Amount', y: investorsTransactionOverview && investorsTransactionOverview.givenAmount, color: '#e55353' },
                    { name: 'Processing Amount', y: investorsTransactionOverview && investorsTransactionOverview.processingAmount, color: '#E88B00' },
                    { name: 'In Request Amount', y: investorsTransactionOverview && investorsTransactionOverview.inRequestAmount, color: '#3399FF' },
                ],
            },
        ],
    };
    return (
        <>
            <div className='row'>
                <div className='col col-md-9'>
                    <div className='d-flex flex-column gap-3'>
                        <div className='container-fluid landformconatiner p-4' style={{ maxHeight: "420px" }}>
                            <div className='d-flex flex-column flex-md-row justify-content-between pe-3 ps-3'>
                                <PageHeading pagename="Individual Investors Overview" />
                                <button className="btn btn-primary" type="button" class="btn btn-primary" onClick={() => { navigate("/saveindividualinvestor/null") }}>+ Add Investor</button>
                            </div>

                            <div className=' expenses-conatiner mt-3'>
                                <div className='d-flex flex-column gap-3 p-3 expenses-card' style={{ background: "#006CFF" }}>
                                    <div className='projecticon'>
                                        <img src={LandCost} alt="Total No of Investors " width="20px" height="20px" />
                                    </div>
                                    <div className='d-flex flex-column gap-2'>
                                        <span className='proj-heading'>Total No of Investors </span>
                                        <span className='proj-cost'>{investorsTransactionOverview && investorsTransactionOverview.numberOfInvestor}</span>
                                    </div>

                                </div>
                                <div className='d-flex flex-column gap-3 p-3 expenses-card' style={{ background: "#03D4C6" }}>
                                    <div className='projecticon'>
                                        <img src={ExpensesCost} alt="Total Invested" width="20px" height="20px" />
                                    </div>
                                    <div className='d-flex flex-column gap-2'>
                                        <span className='proj-heading'>Amount To Be Paid (&#8377;)</span>
                                        <span className='proj-cost'>&#8377; {investorsTransactionOverview && investorsTransactionOverview.amountTobePaid}</span>
                                    </div>

                                </div>
                                <div className='d-flex flex-column gap-3 p-3 expenses-card' style={{ background: "#00AFF0" }}>
                                    <div className='projecticon'>
                                        <img src={projectCost} alt="Return Amount" width="20px" height="20px" />
                                    </div>
                                    <div className='d-flex flex-column gap-2'>
                                        <span className='proj-heading'>Pending Amount (&#8377;)</span>
                                        <span className='proj-cost'>&#8377; {investorsTransactionOverview && investorsTransactionOverview.pendingAmount}</span>
                                    </div>

                                </div>
                                <div className='d-flex flex-column gap-3 p-3 expenses-card' style={{ background: "#e55353" }}>
                                    <div className='projecticon'>
                                        <img src={projectCost} alt="Return Amount" width="20px" height="20px" />
                                    </div>
                                    <div className='d-flex flex-column gap-2'>
                                        <span className='proj-heading'>Paid Amount (&#8377;)</span>
                                        <span className='proj-cost'>&#8377; {investorsTransactionOverview && investorsTransactionOverview.givenAmount}</span>
                                    </div>

                                </div>
                                <div className='d-flex flex-column gap-3 p-3 expenses-card' style={{ background: "#E88B00" }}>
                                    <div className='projecticon'>
                                        <img src={projectCost} alt="Return Amount" width="20px" height="20px" />
                                    </div>
                                    <div className='d-flex flex-column gap-2'>
                                        <span className='proj-heading'>Processing Amount (&#8377;)</span>
                                        <span className='proj-cost'>&#8377; {investorsTransactionOverview && investorsTransactionOverview.processingAmount}</span>
                                    </div>

                                </div>
                                <div className='d-flex flex-column gap-3 p-3 expenses-card' style={{ background: "#3399FF" }}>
                                    <div className='projecticon'>
                                        <img src={projectCost} alt="Return Amount" width="20px" height="20px" />
                                    </div>
                                    <div className='d-flex flex-column gap-2'>
                                        <span className='proj-heading'>In Request Amount (&#8377;)</span>
                                        <span className='proj-cost'>&#8377; {investorsTransactionOverview && investorsTransactionOverview.inRequestAmount}</span>
                                    </div>

                                </div>
                            </div>



                        </div>
                    </div>
                </div>
                <div className='col col-md-3'>
                    <div className='d-flex flex-column gap-3' >
                        <div className='container-fluid landformconatiner p-4'>
                            <PageHeading pagename="Investors Summery" />
                            <div className='row pt-3' style={{ height: "150px" }}>
                                <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ShowIndividualInvestor />
        </>
    )
}
