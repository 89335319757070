import React, { useEffect, useState } from 'react'
import InsertProject from '../../APIs/Project/InsertProject';
import { useNavigate } from 'react-router-dom';
// import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min.js';
import GetProjectNumber from '../../APIs/Project/GetProjectNumber';
import Loader from '../../components/Loader';
import SideMessage from '../../components/SideMessage';

export default function AddProjectModal() {

    let navigate = useNavigate()
    let [loader, setLoader] = useState(1)

    let [sendForm, setSendForm] = useState(
        {
            "name": "",
            "userId": window.userId,
            "projectNo": "",
            "landmark": "",
        }
    )
    let [sideToster, setSideToster] = useState({
        show: 0,
        message: '',
        type: ''
    })
    let handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value
        setSendForm({ ...sendForm, [name]: value })
    }

    let handleSaveForm = async () => {
        if (sendForm.name.trim() !== "" && sendForm.landmark.trim() !== "" && sendForm.projectNo.trim() !== "") {
            setLoader(1)
            sendForm.projectNo = sendForm.projectNo.slice(7, 10)
            console.log("eneter", sideToster)
            let resp = await InsertProject(sendForm);
            if (resp.status === 1) {
                setLoader(0)
                clear()
                window.sessionStorage.setItem("ProjectData", JSON.stringify({ "projectId": resp.responseValue.id, "projectData": [resp.responseValue.name, "#null", resp.responseValue.id, 0, "0x0", resp.responseValue.landmark ]}))
                document.querySelector(".modal-backdrop ").remove()
                navigate("/addland/")
                setSideToster(
                    {
                        show: 0,
                        message: "",
                        type: ''
                    }
                )

            }
            else {
                setLoader(0)
                setSideToster(
                    {
                        show: 1,
                        message: resp.responseValue,
                        type: 'Alert'
                    }
                )
            }
        }
        else {
            setSideToster(
                {
                    show: 1,
                    message: "Please Fill All Fields",
                    type: 'Alert'
                }
            )
        }

    }
    let clear = () => {
        setSendForm(
            {
                "name": "",
                "userId": window.userId,
                "projectNo": "",
            }
        )

    }
    let getProjectNumber = async () => {
        let resp = await GetProjectNumber()
        if (resp.status === 1) {
            setLoader(0)
            setSendForm({ ...sendForm, ["projectNo"]: resp.responseValue })
        }
    }

    useEffect(() => {
        getProjectNumber()
    }, [])
    return (
        <>
            <div className="modal  fade " id="addproject" tabindex="-1" aria-labelledby="addprojectLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content pt-3 ps-3 pe-3 pb-1" style={{ maxWidth: "500px" }}>
                        <div className="modal-header">
                            <h1 className="modal-title fs-5 text-primary" id="addprojectLabel">Add New Project</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className='row pb-4'>
                                <div className='col pb-2 pb-md-0'>
                                    <div className='d-flex flex-column gap-1'>
                                        <label className='form-label'>Project Name</label>
                                        <input type='text' className='form-input-box' value={sendForm.name} placeholder='Enter Project Name' name="name" onChange={handleChange} />
                                    </div>
                                </div>
                            </div>
                            <div className='row pb-4'>
                                <div className='col pb-2 pb-md-0'>
                                    <div className='d-flex flex-column gap-1'>
                                        <label className='form-label'>Landmark</label>
                                        <input type='text' className='form-input-box' value={sendForm.landmark} placeholder='Enter Landmark' name="landmark" onChange={handleChange} />
                                    </div>
                                </div>
                            </div>
                            <div className='row '>
                                <div className='col pb-2 pb-md-0'>
                                    <div className='d-flex flex-column gap-1'>
                                        <label className='form-label'>Unique Id</label>
                                        <input type='text' className='form-input-box' value={sendForm.projectNo} disabled />
                                    </div>
                                </div>
                                <span className='pt-1 fw-bolder'><p style={{ fontSize: "0.66rem", color: "#FE996C" }}>Unique ID generated automatically</p></span>
                            </div>
                        </div>
                        <div className="modal-footer" >
                            {/* <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}
                            <button type="button" style={{ width: "100%" }} className="btn btn-primary" onClick={handleSaveForm} >Next Step</button>
                        </div>
                    </div>
                </div>
            </div>
            <Loader show={loader} />
            {
                sideToster.show === 1 ?
                    <SideMessage handleShow={setSideToster} show={sideToster.show} type={sideToster.type} message={sideToster.message} />
                    : ""
            }
        </>
    )
    // data-bs-dismiss="modal" aria-label="Close"
}
