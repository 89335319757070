import React, { useEffect, useState } from 'react'
import PageHeading from '../../../../components/PageHeading'
import GetInvestorByLand from '../../../../APIs/Investor/GetInvestorByLand'
import { Link } from 'react-router-dom'

export default function InvestorDetails({ projectData, landId, projectName }) {
  let [investorList, setInvestorList] = useState([])

  let getData = async (landId) => {
    // let response = await GetInvestorByLand(landId);
    if (projectData.LandData && projectData.LandData.length !== 0) {
      const landDataItem = projectData.LandData.find(val => val.id === landId && val.landInvestors.length > 0);
      if (landDataItem) {
        setInvestorList(landDataItem.landInvestors);
      } else {
        setInvestorList([]);
      }
    } else {
      setInvestorList([]);
    }
  }
  useEffect(() => {
    getData(landId)
  }, [landId])
  return (
    <div className='container-fluid landformconatiner p-4'>
      <div className='d-flex flex-row justify-content-between align-items-center'>
        <PageHeading pagename="Land Owned By" />
        <Link to={`/landinvestor/${landId}`} className='text-blue-color fw-semibold'>
          View All
        </Link>

      </div>
      {/* <div className='d-flex flex-row justify-content-between pb-2 pt-2 rowBox' >

        <label className='heading-project fw-semibold'>No Data Found</label>

      </div> */}


      <div className='d-flex flex-column gap-3 pt-3'>

        {
          investorList && investorList.map((val, ind) => {
            return (
              // <div className='d-flex flex-row justify-content-between pb-2 rowBox pointer' onClick={() => { setEditData(val); setShowModal(1) }}>
              <Link to={`/investorportfoliooverview/Project/${val.payeeType}/${val.referenceId}/${val.paidTo}/${val.paidToId}/1/1/${projectName}`}>
                <div className='d-flex flex-row justify-content-between pb-2 rowBox pointer' >
                  <div className='d-flex flex-row gap-2'>
                    <div style={{ width: "30px", height: "30px", backgroundColor: "#E4E4E4", borderRadius: "15px" }}></div>
                    <span className='d-flex flex-column gap-1'>
                      <label className='heading-project fw-semibold'>{val.name}</label>
                      <label className='sub-heading-project' style={{ color: "#737791" }}>Ownership {val.percentage}%</label>
                    </span>
                  </div>
                  <div className='d-flex flex-row'>
                    <i class="bi bi-chevron-right"></i>
                  </div>
                </div>
              </Link>
            )
          })
        }


      </div>
    </div>
  )
}
