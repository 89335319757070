import React, { useEffect, useRef } from 'react'
import PageHeading from '../../../../components/PageHeading'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

export default function ProjectOver({projectData}) {

    const chartRef = useRef(null);
    useEffect(() => {
        // console.log("projectData.totalLandArea", projectData.totalLandArea)
        if (chartRef.current) {
            const chart = chartRef.current.chart;

            // Calculate the exact center of the plot area
            const centerX = (chart.plotWidth / 2.5) + chart.plotLeft;
            const centerY = (chart.plotHeight / 2.5) + chart.plotTop;
            const center1X = (chart.plotWidth / 2.5) + chart.plotLeft;
            const center1Y = (chart.plotHeight / 2) + chart.plotTop;

            // Add a custom label in the center
            chart.renderer
                .label('Total Area ', centerX, centerY)
                .attr({
                    zIndex: 99,
                })
                .css({
                    color: '#000',
                    fontSize: '18px',
                    fontWeight: "600"
                })
                .add();

            chart.renderer
                .label(projectData.totalLandArea, center1X, center1Y)
                .attr({
                    zIndex: 99,
                })
                .css({
                    color: '#000',
                    fontSize: '20px',
                    fontWeight: "800"
                })
                .add();
        }
    }, [projectData]);

    const options = {
        chart: {
            type: 'pie',
        },
        title: {
            text: '',
        },
        plotOptions: {
            pie: {
                innerSize: '70%',
                dataLabels: {
                    enabled: false,
                },
            },
            borderRadius: 600,
        },
        series: [
            {
                name: 'Amount',
                data: [
                    { name: 'Total Land Cost', y: 300, color: '#006CFF' },
                    { name: 'Total amount from booking', y: 50, color: '#FE996C' },
                    { name: 'Sale Amount received', y: 100, color: '#03D4C6' },
                    { name: 'Amount left to be received', y: 100, color: '#F15642' },
                ],
            },
        ],
    };

    return (
        <div className='container-fluid landformconatiner p-4'>
            <PageHeading pagename="Project Overview" />
            <div className='row' style={{ height: "350px" }}>
                <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
            </div>

            <div className='d-flex flex-column gap-3 pt-3'>
                <div className='d-flex flex-row justify-content-between pb-2 rowBox' >
                    <div className='d-flex flex-row gap-2'>
                        <div style={{ width: "15px", height: "15px", backgroundColor: "#006CFF", borderRadius: "15px" }}></div>
                        <span className='d-flex flex-column '>
                            <label className='heading-project fw-semibold'>Total Area</label>
                            <label className='sub-heading-project' >{projectData.totalLandArea} {projectData.unitName==="SquareFoot"?"Sq":projectData.unitName}</label>
                        </span>
                    </div>
                    <div className='d-flex flex-row'>
                        <PageHeading pagename="₹ 0" />
                    </div>
                </div>
                <div className='d-flex flex-row justify-content-between  pb-2 rowBox' >
                    <div className='d-flex flex-row gap-2'>
                        <div style={{ width: "15px", height: "15px", backgroundColor: "#03D4C6", borderRadius: "15px" }}></div>
                        <span className='d-flex flex-column '>
                            <label className='heading-project fw-semibold'>Available Area</label>
                            <label className='sub-heading-project' >-</label>
                        </span>
                    </div>
                    <div className='d-flex flex-row'>
                        <PageHeading pagename="₹ 0" />
                    </div>
                </div>
                <div className='d-flex flex-row justify-content-between  pb-2 rowBox' >
                    <div className='d-flex flex-row gap-2'>
                        <div style={{ width: "15px", height: "15px", backgroundColor: "#006CFF", borderRadius: "15px" }}></div>
                        <span className='d-flex flex-column '>
                            <label className='heading-project fw-semibold'>Sale Area</label>
                            <label className='sub-heading-project'>-</label>
                        </span>
                    </div>
                    <div className='d-flex flex-row'>
                        <PageHeading pagename="₹ 0" />
                    </div>
                </div>
                <div className='d-flex flex-row justify-content-between pb-2 rowBox' >
                    <div className='d-flex flex-row gap-2'>
                        <div style={{ width: "15px", height: "15px", backgroundColor: "#FE996C", borderRadius: "15px" }}></div>
                        <span className='d-flex flex-column '>
                            <label className='heading-project fw-semibold'>Pending Area</label>
                            <label className='sub-heading-project'>-</label>
                        </span>
                    </div>
                    <div className='d-flex flex-row'>
                        <PageHeading pagename="₹ 0" />
                    </div>
                </div>
            </div>
        </div>
    )
}

