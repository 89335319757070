export default async function DeleteVendorDetails(id) {
    let url = window.RealestateService+  `/api/Vendor/DeleteVendorDetails?id=${id}`
    let head = {
         "content-type":"application/json",
        "accept":"*/*"
    }
    let response = await fetch(url, {
        method:"POST",
        headers:head,
        body:JSON.stringify("")
    }).then(res=>res.json()).catch(res=>res)

    return response
}