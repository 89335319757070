import React from 'react'
import PageHeading from '../../../../components/PageHeading'
import LandCost from "../../../../assets/images/SalesIcon.png"
import ExpensesCost from "../../../../assets/images/expensesicon.png"
import projectCost from "../../../../assets/images/totalcosticon.png"
export default function ProjectSummery({projectData}) {
    return (
        <div className='container-fluid landformconatiner p-4' style={{maxHeight:"420px"}}>
            <PageHeading pagename="Project Summery" />
            <div className=' expenses-conatiner'>
                <div className='d-flex flex-column gap-3 p-3 expenses-card' style={{background:"#006CFF"}}>
                    <div className='projecticon'>
                        <img src={LandCost} alt="Total Land Cost" width="20px" height="20px" />
                    </div>
                    <div className='d-flex flex-column gap-2'>
                        <span className='proj-heading'>Total Land Cost</span>
                        <span className='proj-cost'>₹ {projectData.totalLandPrice} </span>
                    </div>

                </div>
                {/* <div className='d-flex flex-column gap-3 p-3 expenses-card' style={{background:"#03D4C6"}}>
                    <div className='projecticon'>
                        <img src={ExpensesCost} alt="Total Land Cost" width="20px" height="20px" />
                    </div>
                    <div className='d-flex flex-column gap-2'>
                        <span className='proj-heading'>Total Land Cost</span>
                        <span className='proj-cost'>₹ 0Cr.</span>
                    </div>

                </div> */}
                {/* <div className='d-flex flex-column gap-3 p-3 expenses-card' style={{background:"#00AFF0"}}>
                    <div className='projecticon'>
                        <img src={projectCost} alt="Total Land Cost" width="20px" height="20px" />
                    </div>
                    <div className='d-flex flex-column gap-2'>
                        <span className='proj-heading'>Total Land Cost</span>
                        <span className='proj-cost'>₹ 0Cr.</span>
                    </div>

                </div> */}


                <div className='d-flex flex-column gap-3 p-3 expenses-card' style={{background:"#FE996C"}}>
                    <div className='projecticon'>
                        <img src={LandCost} alt="Total Land Cost" width="20px" height="20px" />
                    </div>
                    <div className='d-flex flex-column gap-2'>
                        <span className='proj-heading'>Total amount from booking</span>
                        <span className='proj-cost'>₹ 0 Cr.</span>
                    </div>

                </div>
                <div className='d-flex flex-column gap-3 p-3 expenses-card' style={{background:"#03D4C6"}}>
                    <div className='projecticon'>
                        <img src={ExpensesCost} alt="Total Land Cost" width="20px" height="20px" />
                    </div>
                    <div className='d-flex flex-column gap-2'>
                        <span className='proj-heading'>Sale Amount received</span>
                        <span className='proj-cost'>₹ 0 Cr.</span>
                    </div>

                </div>
                <div className='d-flex flex-column gap-3 p-3 expenses-card' style={{background:"#F15642"}}>
                    <div className='projecticon'>
                        <img src={projectCost} alt="Total Land Cost" width="20px" height="20px" />
                    </div>
                    <div className='d-flex flex-column gap-2'>
                        <span className='proj-heading'>Amount left to be received</span>
                        <span className='proj-cost'>₹ 0 Cr.</span>
                    </div>

                </div>

                
                
            </div>
        </div>
    )
}
