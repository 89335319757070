import React, { useEffect, useRef, useState } from 'react'
import PageHeading from '../../../components/PageHeading'
import graph from "../../../assets/images/graph.png"
import LandCost from "../../../assets/images/SalesIcon.png"
import ExpensesCost from "../../../assets/images/expensesicon.png"
import projectCost from "../../../assets/images/totalcosticon.png"
import FilterTable from '../../../components/FilterTable'
import { Link, useNavigate } from 'react-router-dom'
import GetInvestorDetails from '../../../APIs/AddInvestor/GetInvestorDetails'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts';
import DeleteInvestorDetails from '../../../APIs/AddInvestor/DeleteInvestorDetails'
import Loader from '../../../components/Loader'
import SideMessage from '../../../components/SideMessage'
export default function CompanyInvestor() {
    let navigate = useNavigate()
    let [investorList, setInvestorList] = useState([])
    let [investorTempList, setInvestorTempList] = useState([])
    let [loader, setLoader] = useState(1)
    const chartRef = useRef(null);

    let [sideToster, setSideToster] = useState({
        show: 0,
        message: '',
        type: ''
    })
    let getData = async () => {
        let resp = await GetInvestorDetails()
        if (resp.status === 1) {
            setInvestorList(resp.responseValue.filter(val => val.investorType === 1))
            setInvestorTempList(resp.responseValue.filter(val => val.investorType === 1))
            setLoader(0)
        }
        else {
            setLoader(0)
        }
    }

    const options = {
        chart: {
            type: 'pie',
            height: 150, // Set the height of the chart
        },
        title: {
            text: '',
        },
        plotOptions: {
            pie: {
                innerSize: '70%',
                dataLabels: {
                    enabled: false,
                },
            },
            borderRadius: 600,
        },
        credits: {
            enabled: false, // Disable the Highcharts credits
        },
        series: [
            {
                name: 'Categories',
                data: [
                    { name: 'Red', y: 300, color: '#FF6384' },
                    { name: 'Green', y: 50, color: '#36A2EB' },
                    { name: 'Yellow', y: 100, color: '#FFCE56' },
                    { name: 'whute', y: 100, color: '#fff' },
                ],
            },
        ],
    };

    let handleDelete = async (id) => {
        setLoader(1)
        let resp = await DeleteInvestorDetails(id)
        if (resp.status === 1) {
            setLoader(0)
            getData()
            setSideToster({
                show: 1,
                message: 'Data Deleted',
                type: 'Success'
            })
        }
        else {
            setLoader(0)
            setSideToster({
                show: 1,
                message: resp.responseValue,
                type: 'Alert'
            })
        }
    }

    useEffect(() => {
        getData()
        if (chartRef.current) {
            const chart = chartRef.current.chart;

            // Calculate the exact center of the plot area
            const centerX = (chart.plotWidth / 2.5) + chart.plotLeft;
            const centerY = (chart.plotHeight / 2.5) + chart.plotTop;
            const center1X = (chart.plotWidth / 2.5) + chart.plotLeft;
            const center1Y = (chart.plotHeight / 2) + chart.plotTop;

            // Add a custom label in the center
            chart.renderer
                .label('Total Area ', centerX, centerY)
                .attr({
                    zIndex: 99,
                })
                .css({
                    color: '#000',
                    fontSize: '10px',
                    fontWeight: "600"
                })
                .add();

            chart.renderer
                .label('0 x 0', center1X, center1Y)
                .attr({
                    zIndex: 99,
                })
                .css({
                    color: '#000',
                    fontSize: '12px',
                    fontWeight: "800"
                })
                .add();
        }
    }, [])
    return (
        <>
            <div className='row'>
                <div className='col col-md-9'>
                    <div className='d-flex flex-column gap-3'>
                        <div className='container-fluid landformconatiner p-4' style={{ maxHeight: "420px" }}>
                            <div className='d-flex flex-column flex-md-row justify-content-between pe-3 ps-3'>
                                <PageHeading pagename="Company Investors Overview" />
                                <button className="btn btn-primary" type="button" class="btn btn-primary" onClick={() => { navigate("/addcompanyinvestor/null") }}>+ Add Investor</button>
                            </div>
                            {investorTempList && investorTempList.map((val) => {
                                return (
                                    <div className=' expenses-conatiner mt-3'>
                                        <div className='d-flex flex-column gap-3 p-3 expenses-card' style={{ background: "#006CFF" }}>
                                            <div className='projecticon'>
                                                <img src={LandCost} alt="Total No of Investors " width="20px" height="20px" />
                                            </div>
                                            <div className='d-flex flex-column gap-2'>
                                                <span className='proj-heading'>Total No of Investors </span>
                                                <span className='proj-cost'>{investorTempList.length}</span>
                                            </div>

                                        </div>
                                        <div className='d-flex flex-column gap-3 p-3 expenses-card' style={{ background: "#03D4C6" }}>
                                            <div className='projecticon'>
                                                <img src={ExpensesCost} alt="Total Invested" width="20px" height="20px" />
                                            </div>
                                            <div className='d-flex flex-column gap-2'>
                                                <span className='proj-heading'>Total Invested (&#8377;)</span>
                                                <span className='proj-cost'>&#8377; {val.investedAmount}</span>
                                            </div>

                                        </div>
                                        <div className='d-flex flex-column gap-3 p-3 expenses-card' style={{ background: "#00AFF0" }}>
                                            <div className='projecticon'>
                                                <img src={projectCost} alt="Return Amount" width="20px" height="20px" />
                                            </div>
                                            <div className='d-flex flex-column gap-2'>
                                                <span className='proj-heading'>Left Amount (&#8377;)</span>
                                                <span className='proj-cost'>&#8377; {val.payingAmount}</span>
                                            </div>

                                        </div>
                                        <div className='d-flex flex-column gap-3 p-3 expenses-card' style={{ background: "#e55353" }}>
                                            <div className='projecticon'>
                                                <img src={projectCost} alt="Return Amount" width="20px" height="20px" />
                                            </div>
                                            <div className='d-flex flex-column gap-2'>
                                                <span className='proj-heading'>Paid Amount (&#8377;)</span>
                                                <span className='proj-cost'>&#8377; {0}</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}

                        </div>
                    </div>
                </div>
                <div className='col col-md-3'>
                    <div className='d-flex flex-column gap-3' >
                        <div className='container-fluid landformconatiner p-4'>
                            <PageHeading pagename="Investors Summery" />
                            <div className='row pt-3' style={{ height: "150px" }}>
                                <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* show Data */}
            <div className='container-fluid landformconatiner p-4 mt-3'>
                <div className='d-flex flex-column flex-md-row justify-content-between'>
                    <PageHeading pagename="Investors List" />
                    <FilterTable tempList={investorTempList} tempListMethod={setInvestorTempList} originalList={investorList} isSearch={true} isdate={false} isFilter={false} isExport={false} />
                </div>
                <div className='pt-4'>
                    <table class="table">
                        <thead>
                            <tr className='table-head'>
                                {/* <th scope="col">S.No.</th> */}
                                <th scope="col">Name</th>
                                <th scope="col">Invested Amount (&#8377;)</th>
                                <th scope="col">Left Amount (&#8377;)</th>
                                <th scope="col">Paid Amount (&#8377;)</th>
                                <th scope="col">Tenure</th>
                                <th scope="col">Status</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody className='table-body'>
                            {
                                investorTempList && investorTempList.map((val, ind) => {
                                    return (
                                        <tr >
                                            {/* <td scope="row">{ind + 1}</td> */}
                                            <td>{val.name}</td>
                                            <td>&#8377; {val.investedAmount}</td>
                                            <td>&#8377; {val.payingAmount}</td>
                                            {/* <td>{val.investedAmount - val.payingAmount}</td> */}
                                            <td>&#8377; {0}</td>
                                            <td>{"5 Year"}</td>
                                            <td>

                                                <div class="progress">
                                                    <div class="progress-bar" role="progressbar" aria-label="Example with label" style={{ "width": isNaN(((0 / val.payingAmount) * 100).toFixed(2)) ? 0 : ((0 / val.payingAmount) * 100).toFixed(2), fontSize: "0.8rem" }} aria-valuemin="0" aria-valuemax="100">{isNaN(((0 / val.payingAmount) * 100).toFixed(2)) ? 0 : ((0 / val.payingAmount) * 100).toFixed(2)}%</div>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="dropdown dropend">
                                                    {/* <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        Dropdown button
                                                    </button> */}
                                                    <i class="bi bi-three-dots-vertical " data-bs-toggle="dropdown" aria-expanded="false"></i>
                                                    <ul className="dropdown-menu mr-3">
                                                        <li><Link className="dropdown-item" to={`/showcompanytransactions/${val.id}`}>View</Link></li>
                                                        <li><span className="dropdown-item" onClick={() => { handleDelete(val.id) }}>Delete</span></li>
                                                        {/* <li onClick={handleLogout}><Link className="dropdown-item" href="#">Logout <i className="bi bi-box-arrow-right"></i></Link></li> */}
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }


                        </tbody>
                    </table>
                </div>


            </div>

            <Loader show={loader} />

            {
                sideToster.show === 1 ?
                    <SideMessage handleShow={setSideToster} show={sideToster.show} type={sideToster.type} message={sideToster.message} />
                    : ""
            }
        </>
    )
}
