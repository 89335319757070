export default async function DeleteProjectPlotDetails(id) {
    let url = window.RealestateService + `/api/ProjectPlotDetails/DeleteProjectPlotDetails?id=${id}`
    let head = {
        "content-type": "application/json",
        "accept": "*/*",
        "Authorization": window.token
    }
    let response = await fetch(url, {
        method:"POST",
        headers: head,
        // body: JSON.stringify(sendForm)
    }).then(res => res.json()).catch(res => res)

    return response
}