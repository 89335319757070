import React from 'react'
import GetLandDetailsByProjectId from '../../APIs/Land/GetLandDetailsByProjectId'
import { useNavigate } from 'react-router-dom'
import landimage from "../../assets/images/landimg.png"
export default function ProjectCard(props) {
    let navigate = useNavigate()
    let handleShow = async () => {
        let resp = await GetLandDetailsByProjectId(props.projectData.id)
        if (resp.status === 1) {
            console.log("all Land", resp.responseValue)
            // window.sessionStorage.setItem("ProjectData", JSON.stringify({ "allLandData": resp.responseValue, "projectData": [props.projectData.name, props.projectData.projectNo, props.projectData.id, props.projectData.projectPrice, props.projectData.projectArea, props.projectData.landmark], }))
            navigate("/addplotdeatils/" + props.projectData.id)
        }
        else {

        }
    }

    // let handleDelete = async(id)=>{
    //     let resp = await DeleteProject(id)
    //     if(resp.status === 1)
    //     {
    //         props.getData()
    //     }
    // }
    return (
        <>
            <div className="card " style={{ "width": "100%", border: "none" }}>
                <img src={landimage} className="card-img-top p-3" alt="..." />
                <div className="card-body pt-0">
                    <div className='d-flex flex-row justify-content-between'>
                        <div className='d-flex flex-column pb-2'>
                            <h6 className="card-title">{props.projectData.name}</h6>
                            <span className='card-refrence'>
                                #{props.projectData.projectNo}
                            </span>
                        </div>

                        <div class="dropdown dropend">

                            <i class="bi bi-three-dots-vertical " data-bs-toggle="dropdown" aria-expanded="false"></i>
                            <ul className="dropdown-menu mr-3">
                                <li>
                                    {/* <span className="dropdown-item" onClick={() => { handleDelete(props.projectData.id) }}>Delete</span> */}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="d-flex flex-row justify-content-between card-text pb-3 pt-1">
                        <div className='d-flex flex-row gap-2'>
                            <i class="fa-solid fa-indian-rupee-sign rupee-icon"></i>
                            <span className='price text-secondary'>{props.projectData.projectPrice}</span>
                        </div>
                        <div className='d-flex flex-row gap-2'>
                            <i class="fa-solid fa-location-dot rupee-icon"></i>
                            <span className='price text-secondary'>{props.projectData.landmark}</span>
                        </div>
                    </div>
                    <span className="btn btn-primary" onClick={handleShow}>View Details</span>
                </div>
            </div>
        </>
    )
}
