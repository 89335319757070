import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom';
import PageHeading from '../../../components/PageHeading';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

export default function ProjectByName() {
    const chartRef = useRef(null);
    useEffect(() => {
        if (chartRef.current) {
            const chart = chartRef.current.chart;

            // Calculate the exact center of the plot area
            const centerX = (chart.plotWidth / 2.7) + chart.plotLeft;
            const centerY = (chart.plotHeight / 2.5) + chart.plotTop;
            const center1X = (chart.plotWidth / 2.7) + chart.plotLeft;
            const center1Y = (chart.plotHeight / 2) + chart.plotTop;

            // Add a custom label in the center
            chart.renderer
                .label('Total Unit Area', centerX, centerY)
                .attr({
                    zIndex: 99,
                })
                .css({
                    color: '#737791',
                    fontSize: '12px',
                    fontWeight: "400"
                })
                .add();

            chart.renderer
                .label('0 SF', center1X, center1Y)
                .attr({
                    zIndex: 99,
                })
                .css({
                    color: '#000',
                    fontSize: '18px',
                    fontWeight: "600"
                })
                .add();
        }
    }, []);

    const options = {
        chart: {
            type: 'pie',
            margin: [0, 0, 0, 0],
            spacingTop: 0,
            spacingBottom: 0,
            spacingLeft: 0,
            spacingRight: 0,
            height: '250px'
        },
        title: {
            text: '',
        },
        plotOptions: {
            pie: {
                innerSize: '70%',
                size: "100%",
                dataLabels: {
                    enabled: false,
                },
            },
            borderRadius: 600,
        },
        series: [
            {
                name: 'Categories',
                data: [
                    { name: 'Red', y: 300, color: '#FF6384' },
                    { name: 'Green', y: 50, color: '#36A2EB' },
                    { name: 'Yellow', y: 100, color: '#FFCE56' },
                    { name: 'whute', y: 100, color: '#fff' },
                ],
            },
        ],

    };

    return (
        <div className='container-fluid landformconatiner p-4'>
            <div className='d-flex flex-row justify-content-between'>
                <PageHeading pagename="Project by Name" />
                <select className='form-input-box pe-5' style={{ height: "35px" }}>
                    <option>
                        Vifa
                    </option>
                    {/* <option>
                        Year
                    </option>
                    <option>
                        Week
                    </option> */}
                </select>

            </div>
            {/* <div className='d-flex flex-row justify-content-between pt-3'>

                <select className='form-input-box pe-5' style={{ height: "35px", paddingRight: "0px", paddingLeft: "0px", backgroundColor: "white", border: "1px solid #EDEDED", borderRadius: "5px" }}>
                    <option>
                        Week
                    </option>
                    <option>
                        Week
                    </option>
                    <option>
                        Week
                    </option>
                </select>
                <select className='form-input-box pe-5' style={{ height: "35px", paddingRight: "0px", paddingLeft: "0px", backgroundColor: "white", border: "1px solid #EDEDED", borderRadius: "5px" }}>
                    <option>
                        Month
                    </option>
                    <option>
                        Month
                    </option>
                    <option>
                        Month
                    </option>
                </select>
                <select className='form-input-box pe-5' style={{ height: "35px", paddingRight: "0px", paddingLeft: "0px", backgroundColor: "white", border: "1px solid #EDEDED", borderRadius: "5px" }}>
                    <option>
                        Year
                    </option>
                    <option>
                        Year
                    </option>
                    <option>
                        Year
                    </option>
                </select>

            </div> */}
            <div className='row pt-3 mt-2' style={{ height: "295px" }}>
                <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
            </div>

            <div className='d-flex flex-column gap-3 pt-5 mt-2'>
                <div className='d-flex flex-row justify-content-between pb-2 rowBox' >
                    <div className='d-flex flex-row gap-2'>
                        <div className='projecticon' style={{ backgroundColor: "#03D4C6" }}>
                            <svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M2 0C0.895432 0 0 0.895447 0 2V16C0 17.1046 0.895432 18 2 18H16C17.1046 18 18 17.1046 18 16V2C18 0.895447 17.1046 0 16 0H2ZM5 10C5 9.44769 4.55228 9 4 9C3.44772 9 3 9.44769 3 10V14C3 14.5523 3.44772 15 4 15C4.55228 15 5 14.5523 5 14V10ZM9 6C9.55228 6 10 6.44769 10 7V14C10 14.5523 9.55228 15 9 15C8.44772 15 8 14.5523 8 14V7C8 6.44769 8.44772 6 9 6ZM15 4C15 3.44769 14.5523 3 14 3C13.4477 3 13 3.44769 13 4V14C13 14.5523 13.4477 15 14 15C14.5523 15 15 14.5523 15 14V4Z" fill={"rgba(255, 255, 255, 1)"} />
                            </svg>

                        </div>
                        <span className='d-flex flex-column '>
                            <label className='heading-project fw-semibold'>Sold Out</label>
                            <label className='sub-heading-project' >0 SF (₹ 0)</label>
                        </span>
                    </div>
                    <div className='d-flex flex-row'>
                        <PageHeading pagename="0" />
                    </div>
                </div>
                <div className='d-flex flex-row justify-content-between  pb-2 rowBox' >
                    <div className='d-flex flex-row gap-2'>
                        <div className='projecticon' style={{ backgroundColor: "#F59E0B" }}>
                            <svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M2 0C0.895432 0 0 0.895447 0 2V16C0 17.1046 0.895432 18 2 18H16C17.1046 18 18 17.1046 18 16V2C18 0.895447 17.1046 0 16 0H2ZM5 10C5 9.44769 4.55228 9 4 9C3.44772 9 3 9.44769 3 10V14C3 14.5523 3.44772 15 4 15C4.55228 15 5 14.5523 5 14V10ZM9 6C9.55228 6 10 6.44769 10 7V14C10 14.5523 9.55228 15 9 15C8.44772 15 8 14.5523 8 14V7C8 6.44769 8.44772 6 9 6ZM15 4C15 3.44769 14.5523 3 14 3C13.4477 3 13 3.44769 13 4V14C13 14.5523 13.4477 15 14 15C14.5523 15 15 14.5523 15 14V4Z" fill={"rgba(255, 255, 255, 1)"} />
                            </svg>

                        </div>
                        <span className='d-flex flex-column '>
                            <label className='heading-project fw-semibold'>On Hold</label>
                            <label className='sub-heading-project' >0 SF (₹ 0)</label>
                        </span>
                    </div>
                    <div className='d-flex flex-row'>
                        <PageHeading pagename="0" />
                    </div>
                </div>
                <div className='d-flex flex-row justify-content-between  pb-2 rowBox' >
                    <div className='d-flex flex-row gap-2'>
                        <div className='projecticon' style={{ backgroundColor: "#00AFF0" }}>
                            <svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M2 0C0.895432 0 0 0.895447 0 2V16C0 17.1046 0.895432 18 2 18H16C17.1046 18 18 17.1046 18 16V2C18 0.895447 17.1046 0 16 0H2ZM5 10C5 9.44769 4.55228 9 4 9C3.44772 9 3 9.44769 3 10V14C3 14.5523 3.44772 15 4 15C4.55228 15 5 14.5523 5 14V10ZM9 6C9.55228 6 10 6.44769 10 7V14C10 14.5523 9.55228 15 9 15C8.44772 15 8 14.5523 8 14V7C8 6.44769 8.44772 6 9 6ZM15 4C15 3.44769 14.5523 3 14 3C13.4477 3 13 3.44769 13 4V14C13 14.5523 13.4477 15 14 15C14.5523 15 15 14.5523 15 14V4Z" fill={"rgba(255, 255, 255, 1)"} />
                            </svg>

                        </div>
                        <span className='d-flex flex-column '>
                            <label className='heading-project fw-semibold'>Booked</label>
                            <label className='sub-heading-project'>0 SF (₹ 0)</label>
                        </span>
                    </div>
                    <div className='d-flex flex-row'>
                        <PageHeading pagename="0" />
                    </div>
                </div>
                <div className='d-flex flex-row justify-content-between pb-2 rowBox' >
                    <div className='d-flex flex-row gap-2'>
                        <div className='projecticon' style={{ backgroundColor: "#40BE54" }}>
                            <svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M2 0C0.895432 0 0 0.895447 0 2V16C0 17.1046 0.895432 18 2 18H16C17.1046 18 18 17.1046 18 16V2C18 0.895447 17.1046 0 16 0H2ZM5 10C5 9.44769 4.55228 9 4 9C3.44772 9 3 9.44769 3 10V14C3 14.5523 3.44772 15 4 15C4.55228 15 5 14.5523 5 14V10ZM9 6C9.55228 6 10 6.44769 10 7V14C10 14.5523 9.55228 15 9 15C8.44772 15 8 14.5523 8 14V7C8 6.44769 8.44772 6 9 6ZM15 4C15 3.44769 14.5523 3 14 3C13.4477 3 13 3.44769 13 4V14C13 14.5523 13.4477 15 14 15C14.5523 15 15 14.5523 15 14V4Z" fill={"rgba(255, 255, 255, 1)"} />
                            </svg>

                        </div>
                        <span className='d-flex flex-column '>
                            <label className='heading-project fw-semibold'>Available</label>
                            <label className='sub-heading-project'>0 SF (₹ 0)</label>
                        </span>
                    </div>
                    <div className='d-flex flex-row'>
                        <PageHeading pagename="0" />
                    </div>
                </div>
                <div className='d-flex flex-row justify-content-between pb-2 rowBox' >
                    <div className='d-flex flex-row gap-2'>
                        <div className='projecticon' style={{ backgroundColor: "#006CFF" }}>
                            <svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M2 0C0.895432 0 0 0.895447 0 2V16C0 17.1046 0.895432 18 2 18H16C17.1046 18 18 17.1046 18 16V2C18 0.895447 17.1046 0 16 0H2ZM5 10C5 9.44769 4.55228 9 4 9C3.44772 9 3 9.44769 3 10V14C3 14.5523 3.44772 15 4 15C4.55228 15 5 14.5523 5 14V10ZM9 6C9.55228 6 10 6.44769 10 7V14C10 14.5523 9.55228 15 9 15C8.44772 15 8 14.5523 8 14V7C8 6.44769 8.44772 6 9 6ZM15 4C15 3.44769 14.5523 3 14 3C13.4477 3 13 3.44769 13 4V14C13 14.5523 13.4477 15 14 15C14.5523 15 15 14.5523 15 14V4Z" fill={"rgba(255, 255, 255, 1)"} />
                            </svg>

                        </div>
                        <span className='d-flex flex-column '>
                            <label className='heading-project fw-semibold'>Given to Owner</label>
                            <label className='sub-heading-project'>₹ 0</label>
                        </span>
                    </div>
                    <div className='d-flex flex-row'>
                        <PageHeading pagename="0%" />
                    </div>
                </div>
                <div className='d-flex flex-row justify-content-between pb-2 rowBox' >
                    <div className='d-flex flex-row gap-2'>
                        <div className='projecticon' style={{ backgroundColor: "#3E4095" }}>
                            <svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M2 0C0.895432 0 0 0.895447 0 2V16C0 17.1046 0.895432 18 2 18H16C17.1046 18 18 17.1046 18 16V2C18 0.895447 17.1046 0 16 0H2ZM5 10C5 9.44769 4.55228 9 4 9C3.44772 9 3 9.44769 3 10V14C3 14.5523 3.44772 15 4 15C4.55228 15 5 14.5523 5 14V10ZM9 6C9.55228 6 10 6.44769 10 7V14C10 14.5523 9.55228 15 9 15C8.44772 15 8 14.5523 8 14V7C8 6.44769 8.44772 6 9 6ZM15 4C15 3.44769 14.5523 3 14 3C13.4477 3 13 3.44769 13 4V14C13 14.5523 13.4477 15 14 15C14.5523 15 15 14.5523 15 14V4Z" fill={"rgba(255, 255, 255, 1)"} />
                            </svg>

                        </div>
                        <span className='d-flex flex-column '>
                            <label className='heading-project fw-semibold'>Given to Investor</label>
                            <label className='sub-heading-project'>₹ 0</label>
                        </span>
                    </div>
                    <div className='d-flex flex-row'>
                        <PageHeading pagename="0%" />
                    </div>
                </div>
                <div className='d-flex flex-row justify-content-between pb-2 ' >
                <Link to="" style={{fontWeight:"600", fontSize:"1rem", lineHeight:"24px"}}>View More</Link>
                </div>
            </div>
        </div>
    )
}
