export default async function GetStates(data) {
    let url = window.RealestateService + "/api/StateCity/GetStates?countryId=101";
    let head = {
         "content-type":"application/json",
        "accept":"*/*"
    }
    let response = await fetch(url, {
        method:"GET",
        headers:head,
    }).then(res=>res.json()).catch(res=>res)

    return response;
}