import React from 'react'
import PageHeading from '../../../components/PageHeading'
import Summerybox from '../../../components/Summerybox'

export default function SalesOverview() {
  return (
    <>
         <div className='container-fluid landformconatiner p-4' style={{ maxHeight: "420px" }}>
            <PageHeading pagename="Sales Overview" />
            <div className='expenses-conatiner pt-3' style={{columnGap:"30px"}}>
                <Summerybox color="#006CFF" title="Total Sales" money={0} subheading="+ 0% from last month"/>
                <Summerybox color="#03D4C6" title="Booking Amount" money={0} subheading="+ 0% from last month"/>
                <Summerybox color="#FE996C" title="Amount to be Received" money={0} subheading="- 0% from  last month"/>
                <Summerybox color="#00AFF0" title="Cancelled" money={0} subheading="- 0% from  last month"/>
            </div>
        </div>
    </>
  )
}
