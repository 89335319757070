import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import PageHeading from '../../../components/PageHeading'
import FilterTable from '../../../components/FilterTable'
import LandCost from "../../../assets/images/SalesIcon.png"
import ExpensesCost from "../../../assets/images/expensesicon.png"
import projectCost from "../../../assets/images/totalcosticon.png"
import GetInvestorAllTransactionData from '../../../APIs/AddInvestor/GetInvestorAllTransactionData'

export default function ShowSingleIndividualCompanyTransactions() {
    let id = useParams()
    let [investorData, setInvestorData] = useState([])
    let [investorOverviewData, setInvestorOverviewData] = useState([])

    let getData = async (id) => {

        let resp = await GetInvestorAllTransactionData(id)
        if (resp.status === 1) {
            setInvestorData(resp.responseValue.transactions)
            setInvestorOverviewData(resp.responseValue.portfolioOverview)
        }
    }
    useEffect(() => {
        getData(id.id)
    }, [])
    return (
        <>
            <div className='row'>
                <div className='col col-md-12'>
                    <div className='d-flex flex-column gap-3'>
                        <div className='container-fluid landformconatiner p-4' style={{ maxHeight: "420px" }}>
                            <div className='d-flex flex-column flex-md-row justify-content-between pe-3 ps-3'>
                                <PageHeading pagename="Individual Company Overview" />
                                <Link className="btn btn-primary" type="button" class="btn btn-primary" to={`/viewprofileinvestor/${id.id}`}>View Profile</Link>
                            </div>

                            <div className=' expenses-conatiner mt-3'>
                                <div className='d-flex flex-column gap-3 p-3 expenses-card' style={{ background: "#006CFF" }}>
                                    <div className='projecticon'>
                                        <img src={LandCost} alt="Total Invested" width="20px" height="20px" />
                                    </div>
                                    <div className='d-flex flex-column gap-2'>
                                        <span className='proj-heading'>Amount To Be Paid</span>
                                        <span className='proj-cost'>₹ {investorOverviewData && investorOverviewData.amountTobePaid}</span>
                                    </div>

                                </div>
                                <div className='d-flex flex-column gap-3 p-3 expenses-card' style={{ background: "#03D4C6" }}>
                                    <div className='projecticon'>
                                        <img src={ExpensesCost} alt="Return Amount" width="20px" height="20px" />
                                    </div>
                                    <div className='d-flex flex-column gap-2'>
                                        <span className='proj-heading'>Pending Amount</span>
                                        <span className='proj-cost'>₹ {investorOverviewData && investorOverviewData.pendingAmount}</span>
                                    </div>

                                </div>
                                <div className='d-flex flex-column gap-3 p-3 expenses-card' style={{ background: "#00AFF0" }}>
                                    <div className='projecticon'>
                                        <img src={projectCost} alt="Paid Amount" width="20px" height="20px" />
                                    </div>
                                    <div className='d-flex flex-column gap-2'>
                                        <span className='proj-heading'>Given Amount</span>
                                        <span className='proj-cost'>₹ {investorOverviewData && investorOverviewData.givenAmount}</span>
                                    </div>

                                </div>


                                <div className='d-flex flex-column gap-3 p-3 expenses-card' style={{ background: "#006CFF" }}>
                                    <div className='projecticon'>
                                        <img src={LandCost} alt="Left Amount" width="20px" height="20px" />
                                    </div>
                                    <div className='d-flex flex-column gap-2'>
                                        <span className='proj-heading'>Processing Amount</span>
                                        <span className='proj-cost'>₹ {investorOverviewData && investorOverviewData.processingAmount}</span>
                                    </div>

                                </div>


                                <div className='d-flex flex-column gap-3 p-3 expenses-card' style={{ background: "#ff8100" }}>
                                    <div className='projecticon'>
                                        <img src={LandCost} alt="Left Amount" width="20px" height="20px" />
                                    </div>
                                    <div className='d-flex flex-column gap-2'>
                                        <span className='proj-heading'>InRequest Amount</span>
                                        <span className='proj-cost'>₹ {investorOverviewData && investorOverviewData.inRequestAmount}</span>
                                    </div>

                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Show Table */}

            <div className='container-fluid landformconatiner p-4 mt-3'>
                <div className='d-flex flex-column flex-md-row justify-content-between'>
                    <PageHeading pagename="Recent Transactions" />
                    <FilterTable tempList={[]} tempListMethod={() => { }} originalList={[]} isSearch={true} isdate={false} isFilter={false} isExport={false} />
                </div>
                <div className='pt-4'>
                    <table class="table">
                        <thead>
                            <tr className='table-head'>

                                <th scope="col">S.No</th>
                                <th scope="col">Date & Time</th>
                                <th scope="col">Investment Type</th>
                                <th scope="col">Payment Type</th>
                                <th scope="col">AMOUNT</th>
                                <th scope="col">Transaction Type</th>
                                <th scope="col">Transaction Status</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody className='table-body'>
                            {
                                investorData && investorData.map((val, ind) => {
                                    return (
                                        <tr >
                                            <td>{ind + 1}</td>
                                            <td>{val.date.split("T")[0]}</td>
                                            <td>{val.investmentType}</td>
                                            <td>{val.paymentType}</td>
                                            <td>&#8377; {val.amount}</td>
                                            <td>{val.transactionType}</td>
                                            <td>{val.transactionStatus}</td>
                                            <td>
                                                <div class="dropdown dropend">

                                                    <i class="bi bi-three-dots-vertical " data-bs-toggle="dropdown" aria-expanded="false"></i>
                                                    <ul className="dropdown-menu mr-3">
                                                        {/* <li><Link className="dropdown-item" to={`/showinvestortransactions/${val.id}`}>View</Link></li> */}
                                                        {/* <li><span className="dropdown-item" onClick={() => { handleDelete(val.id) }}>Delete</span></li> */}
                                                        {/* <li onClick={handleLogout}><Link className="dropdown-item" href="#">Logout <i className="bi bi-box-arrow-right"></i></Link></li> */}
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }


                        </tbody>
                    </table>
                </div>


            </div>
        </>
    )
}
