import React from 'react'
import PageHeading from '../../../../components/PageHeading'
import Summerybox from '../../../../components/Summerybox'
import { useNavigate } from 'react-router-dom'

export default function FinancialOverview() {
    let navigate = useNavigate()
    return (
        <div className='container-fluid landformconatiner p-4' style={{ maxHeight: "420px" }}>
            {/* <PageHeading pagename="Financial Overview" /> */}
            <div className='d-flex flex-column flex-md-row justify-content-between pe-3 ps-3 pb-3'>
                <PageHeading pagename="Financial Overview" />
                <button className="btn btn-primary" type="button" class="btn btn-primary" onClick={() => { navigate("") }}>+ Add</button>
            </div>
            <div className='expenses-conatiner'>
                <Summerybox color="#006CFF" title="Total Sales" money={0} subheading="+ 0% from last month" />
                <Summerybox color="#03D4C6" title="Total Expenses" money={0} subheading="+ 0% from last month" />
                <Summerybox color="#FE996C" title="Net Profit" money={0} subheading="- 0% from  last month" />
            </div>
        </div>
    )
}
