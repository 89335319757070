export default async function GetProject(id) {
    let url = window.RealestateService + "/api/Project/GetProjectById?id="+id
    let response = {}
    let head = {
        "Content-Type": "application/json",
        'accept': '*/*'
    }
    response = await fetch(url, {
        method: "GET",
        headers: head,
        // body: 
    }).then(res => res.json()).then(response)
    return response;
}