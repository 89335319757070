import React, { useEffect, useRef } from 'react'
import PageHeading from '../../../components/PageHeading';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

export default function SalesAnalytics() {
    const chartRef = useRef(null);
    useEffect(() => {
        if (chartRef.current) {
            const chart = chartRef.current.chart;

            // Calculate the exact center of the plot area
            const centerX = (chart.plotWidth / 2.5) + chart.plotLeft;
            const centerY = (chart.plotHeight / 2.5) + chart.plotTop;
            const center1X = (chart.plotWidth / 2.5) + chart.plotLeft;
            const center1Y = (chart.plotHeight / 2) + chart.plotTop;

            // Add a custom label in the center
            chart.renderer
                .label('Total Sell ', centerX, centerY)
                .attr({
                    zIndex: 99,
                })
                .css({
                    color: '#737791',
                    fontSize: '14px',
                    fontWeight: "400"
                })
                .add();

            chart.renderer
                .label('&#8377; 0K', center1X, center1Y)
                .attr({
                    zIndex: 99,
                })
                .css({
                    color: '#000',
                    fontSize: '18px',
                    fontWeight: "600"
                })
                .add();
        }
    }, []);

    const options = {
        chart: {
            type: 'pie',
            margin: [0, 0, 0, 0],
            spacingTop: 0,
            spacingBottom: 0,
            spacingLeft: 0,
            spacingRight: 0,
            height:'200px'
        },
        title: {
            text: '',
        },
        plotOptions: {
            pie: {
                innerSize: '70%',
                size:"100%",
                dataLabels: {
                    enabled: false,
                },
            },
            borderRadius: 600,
        },
        series: [
            {
                name: 'Categories',
                data: [
                    { name: 'Red', y: 300, color: '#FF6384' },
                    { name: 'Green', y: 50, color: '#36A2EB' },
                    { name: 'Yellow', y: 100, color: '#FFCE56' },
                    { name: 'whute', y: 100, color: '#fff' },
                ],
            },
        ],

    };

    return (
        <div className='container-fluid landformconatiner p-4'>
            <div className='d-flex flex-row justify-content-between'>
                <PageHeading pagename="Sales Analytics" />
                <select className='form-input-box pe-5' style={{ height: "35px" }}>
                    <option>
                        Month
                    </option>
                    <option>
                        Year
                    </option>
                    <option>
                        Week
                    </option>
                </select>

            </div>
            <div className='row' style={{ height: "230px" }}>
                <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
            </div>

            <div className='d-flex flex-column gap-2 pt-3'>
                <div className='d-flex flex-row justify-content-between pb-2_ rowBox' >
                    <div className='d-flex flex-row align-items-center gap-2'>
                        <div style={{ width: "15px", height: "15px", backgroundColor: "#E4E4E4", borderRadius: "15px" }}></div>
                        {/* <span className='d-flex flex-column '> */}
                            {/* <label className='heading-project fw-semibold'>Total Area</label> */}
                            <label className='sub-heading-project' >Online Sell</label>
                        {/* </span> */}
                    </div>
                    <div className='d-flex flex-row'>
                        <PageHeading pagename="₹ 0Cr." />
                    </div>
                </div>
                <div className='d-flex flex-row justify-content-between  pb-2_ rowBox' >
                    <div className='d-flex flex-row align-items-center gap-2'>
                        <div style={{ width: "15px", height: "15px", backgroundColor: "#03D4C6", borderRadius: "15px" }}></div>
                        {/* <span className='d-flex flex-column '> */}
                            {/* <label className='heading-project fw-semibold'>Available Area</label> */}
                            <label className='sub-heading-project' >Offline Sell</label>
                        {/* </span> */}
                    </div>
                    <div className='d-flex flex-row'>
                        <PageHeading pagename="₹ 0Cr." />
                    </div>
                </div>
                <div className='d-flex flex-row justify-content-between  pb-2_ rowBox' >
                    <div className='d-flex flex-row align-items-center gap-2'>
                        <div style={{ width: "15px", height: "15px", backgroundColor: "#006CFF", borderRadius: "15px" }}></div>
                        {/* <span className='d-flex flex-column '> */}
                            {/* <label className='heading-project fw-semibold'>Sale Area</label> */}
                            <label className='sub-heading-project'>Agent Sell</label>
                        {/* </span> */}
                    </div>
                    <div className='d-flex flex-row'>
                        <PageHeading pagename="₹ 0Cr." />
                    </div>
                </div>
                
            </div>
        </div>
    )
}
