import React, { useEffect, useState } from 'react'
import GetUsers from '../../APIs/User/GetUsers'
import InsertLandOwnedBy from '../../APIs/Investor/InsertLandOwnedBy'
import InvestorShow from './ShowLand/InvestorShow'
import Loader from '../../components/Loader'
import SideMessage from '../../components/SideMessage'
import GetInvestorDetails from '../../APIs/AddInvestor/GetInvestorDetails'
import PageHeading from '../../components/PageHeading'
import GetLandOwnedBy from '../../APIs/Investor/GetLandOwnedBy'
import UpdateLandOwnedBy from '../../APIs/Investor/UpdateLandOwnedBy'
import InputBoxNumber from '../../components/InputBoxNumber'

export default function AddInvestor(props) {
    let [userList, setUserList] = useState([])
    let [numberOfInvestor, setNumberOfInvestor] = useState([0])
    let [investorCategory, setInvestorCategory] = useState(2)
    let [loader, setLoader] = useState(0)
    let [vifaData, setVifaData] = useState({
        "percentage": "",
        "inflationPercentage": 0,
        "ownedById": 14,
        "ownedByCategoryId": investorCategory,
        "numberOfInvestor": numberOfInvestor,
        "landId": JSON.parse(window.sessionStorage.getItem("ProjectData")).landData ? JSON.parse(window.sessionStorage.getItem("ProjectData")).landData.id : ""
    })
    let [sideToster, setSideToster] = useState({
        show: 0,
        message: '',
        type: ''
    })
    let [sendForm, setSendForm] = useState([])
    let [ownershippercent, setOwnershippercent] = useState([0])
    let [inflationpercent, setInflationpercent] = useState(0)
    let [isUpdate, setIsUpdate] = useState(0)
    let getData = async () => {
        let respUser = await GetInvestorDetails()
        if (respUser.status === 1) {
            setUserList(respUser.responseValue)
        }
    }

    let handleChnage = (e, ind, values) => {
        let name = e.target.name;
        let value = e.target.value !== "" ? parseInt(e.target.value) : "";

        setSendForm(prevForm => {
            let data = [...prevForm];
            data[ind] = {
                ...data[ind],
                [name]: value,
                "ownedByCategoryId": investorCategory,
                "numberOfInvestor": numberOfInvestor,
                "landId": JSON.parse(window.sessionStorage.getItem("ProjectData")).landData ? JSON.parse(window.sessionStorage.getItem("ProjectData")).landData.id : ""
            };
            return data;
        });

        if (investorCategory === 3) {
            if (name === "percentage") {
                setVifaData((data) => ({
                    ...data, 
                    [name]: (100 - value), 
                    "ownedByCategoryId": investorCategory,
                    "numberOfInvestor": numberOfInvestor,
                    "ownedById": 14,
                    "inflationPercentage": 0,
                }))
            }
        }
    };

    let handleChangeVifa = (e) => {
        let name = e.target.name;
        let value = e.target.value !== "" ? parseInt(e.target.value) : "";
        setVifaData((data) => ({
            ...data, [name]: value, "ownedByCategoryId": investorCategory,
            "numberOfInvestor": numberOfInvestor,
            "ownedById": 14,
            "inflationPercentage": 0,
        }))
    }

    let verification = () => {

        let bool = false
        let error = ""
        let temp = [...sendForm]
        let temps = []
        if (investorCategory === 3) {
            temp = [vifaData, ...temp]
        }

        for (let ind = 0; ind < temp.length; ind++) {
            const val = temp[ind];
            console.log("test", temp)
            if (val.percentage.toString().trim() !== "" && (val.ownedById !== 14 ? val.inflationPercentage.toString().trim() !== "" : val.inflationPercentage === 0) && val.ownedById.toString() !== "-1") {
                bool = true;
                error = "";
            } else if (val.ownedById.toString() === "-1") {
                bool = false;
                error = 'Please Fill Owned By At Row ' + (ind + 1);
                return [bool, error]  // This will exit the entire loop
            } else if (val.percentage.toString().trim() === "") {
                bool = false;
                error = 'Please Fill Percentage At Row ' + (ind + 1);
                return [bool, error] // This will exit the entire loop
            } else if (val.inflationPercentage.toString().trim() === "") {
                bool = false;
                error = 'Please Fill Inflation Percentage At Row ' + (ind + 1);
                return [bool, error]  // This will exit the entire loop
            }
        }

        return [bool, error, temp]

    }

    let handleSave = async () => {
        let bool = verification()

        let temp = [...sendForm]
        if (investorCategory === 3) {
            temp = [vifaData, ...temp]
        }


        let allPercentage = temp.map(value => value.percentage).reduce((oldVlaue, currentValue) => { return oldVlaue + currentValue })
        let allInflationPercentage = temp.map(value => value.inflationPercentage).reduce((oldVlaue, currentValue) => { return oldVlaue + currentValue })
        if (allPercentage <= 100) {
            if (allInflationPercentage <= 100) {
                if (bool[0]) {
                    setLoader(1)
                    // let temp = []
                    // return
                    // if (investorCategory === 3) {
                    //     temp = [vifaData, ...temp]
                    // }
                    // else {
                    //     temp = [...temp]
                    // }
                    let resp = await InsertLandOwnedBy(temp)
                    if (resp.status === 1) {
                        setLoader(0)
                        props.handleActiveTab(2)
                        setSideToster({
                            show: 1,
                            message: "Investor Details Updated Successfully!!",
                            type: 'Success'
                        })
                    }
                    else {
                        setLoader(0)
                        setSideToster({
                            show: 1,
                            message: "Something Went Wrong!!",
                            type: 'Alert'
                        })
                    }
                }
                else {

                    setSideToster({
                        show: 1,
                        message: bool[1],
                        type: 'Alert'
                    })
                    // alert(bool[1])
                }
            }
            else {
                setSideToster({
                    show: 1,
                    message: "Inflation Percentage Not More Than 100",
                    type: 'Alert'
                })
            }
        }
        else {
            setSideToster({
                show: 1,
                message: "Ownership Percentage Not More Than 100",
                type: 'Alert'
            })
        }
    }
    let handleUpdate = async () => {
        let bool = verification()

        let temp = [...sendForm]
        if (investorCategory === 3) {
            temp = [vifaData, ...temp]
        }
        let allPercentage = temp.map(value => value.percentage).reduce((oldVlaue, currentValue) => { return oldVlaue + currentValue })

        let allInflationPercentage = temp.map(value => value.inflationPercentage).reduce((oldVlaue, currentValue) => { return oldVlaue + currentValue })
        if (allPercentage <= 100) {
            if (allInflationPercentage <= 100) {
                if (bool[0]) {
                    setLoader(1)
                    // let temp = []
                    // return
                    // if (investorCategory === 3) {
                    //     temp = [vifaData, ...sendForm]
                    // }
                    // else {
                    //     temp = [...sendForm]
                    // }
                    let resp = await UpdateLandOwnedBy(temp)
                    if (resp.status === 1) {
                        setLoader(0)
                        // props.handleActiveTab(2)
                        setLoader(0)
                        setSideToster({
                            show: 1,
                            message: resp.message,
                            type: 'Success'
                        })
                    }
                    else {
                        setLoader(0)
                        setSideToster({
                            show: 1,
                            message: resp.message,
                            type: 'Alert'
                        })
                    }
                }
                else {

                    setSideToster({
                        show: 1,
                        message: bool[1],
                        type: 'Alert'
                    })
                    // alert(bool[1])
                }
            }
            else {
                setSideToster({
                    show: 1,
                    message: "Inflation Percentage Not More Than 100",
                    type: 'Alert'
                })
            }
        }
        else {
            setSideToster({
                show: 1,
                message: "Ownership Percentage Not More Than 100",
                type: 'Alert'
            })
        }
    }

    let getOldData = async (id, data) => {
        let resp = await GetLandOwnedBy(id)
        if (resp.status === 1 && resp.responseValue.length !== 0) {
            setIsUpdate(1)
            let tempvifa = {
                "percentage": "",
                "inflationPercentage": 0,
                "ownedById": 14,
                "ownedByCategoryId": investorCategory,
                "numberOfInvestor": numberOfInvestor,
                "landId": JSON.parse(window.sessionStorage.getItem("ProjectData")).landData ? JSON.parse(window.sessionStorage.getItem("ProjectData")).landData.id : ""
            }
            let tempinvestor = [...resp.responseValue]
            resp.responseValue.map((val, ind) => {
                console.log("valll", val)
                if (val["ownedById"] === 14) {
                    tempvifa = val
                    console.log("find", val)
                    tempinvestor.splice(ind, 1);
                }
            })
            if (Number(data.ownedByCategoryId) > 1) {
                if (Number(data.ownedByCategoryId) === 2) {
                    let temp = {
                        "percentage": "",
                        "inflationPercentage": "",
                        "ownedById": "-1",
                        "ownedByCategoryId": data.ownedByCategoryId,
                        "numberOfInvestor": data.noOfOwnedBy,
                    };
                    let noForm = data ? new Array(Number(data.noOfOwnedBy) !== 1 ? Number(data.ownedByCategoryId) === 3 ? Number(data.noOfOwnedBy) - 1 : Number(data.noOfOwnedBy) : 1).fill(temp) : [temp];
                    if (Number(data.noOfOwnedBy) - tempinvestor.length !== 0) {
                        noForm = noForm.splice(0, Number(data.noOfOwnedBy) - tempinvestor.length)
                        setSendForm([...tempinvestor, ...noForm])
                    }
                    else {
                        setSendForm([...tempinvestor])
                    }
                }
                else {

                    if ((Number(data.noOfOwnedBy)) >= tempinvestor.length) {
                        let temp = {
                            "percentage": "",
                            "inflationPercentage": "",
                            "ownedById": "-1",
                            "ownedByCategoryId": data.ownedByCategoryId,
                            "numberOfInvestor": data.noOfOwnedBy,
                        };
                        // let noForm = data ? new Array(data.noOfOwnedBy !== 1 ? data.ownedByCategoryId === 3 ? data.noOfOwnedBy - 1 : data.noOfOwnedBy : 1).fill(temp) : [temp];
                        // console.log("grate", data.noOfOwnedBy, tempinvestor.length, noForm)

                        let noForm = data ? new Array(Number(data.noOfOwnedBy) !== 1 ? Number(data.ownedByCategoryId) === 3 ? Number(data.noOfOwnedBy) - 1 : Number(data.noOfOwnedBy) : 1).fill(temp) : [temp];
                        if (Number(data.noOfOwnedBy) - tempinvestor.length !== 0) {
                            noForm = noForm.splice(0, Number(data.noOfOwnedBy) - tempinvestor.length)
                            setSendForm([...tempinvestor, ...noForm])
                        }
                        else {

                            setSendForm([...tempinvestor])
                        }
                        console.log("tempvifa", tempvifa)
                        setVifaData(tempvifa)
                    }
                    // else {
                    //     console.log("cnsdkjbsn", Number(data.noOfOwnedBy), tempinvestor.length, (Number(data.noOfOwnedBy) - 1) > tempinvestor.length)
                    //     tempinvestor.pop()
                    //     setSendForm([...tempinvestor])
                    // }
                }
            }
            else {
                setSendForm(tempinvestor)
                setVifaData(tempvifa)
            }
            // setSendForm(tempinvestor)

        }
    }

    useEffect(() => {
        let data = JSON.parse(window.sessionStorage.getItem("ProjectData")).landData;
        let temp = {
            "percentage": "",
            "inflationPercentage": "",
            "ownedById": "-1",
            "ownedByCategoryId": data.ownedByCategoryId,
            "numberOfInvestor": data.noOfOwnedBy,
        };
        // setSendForm(prevForm => {
        //     return data ? new Array(data.noOfOwnedBy !== 1 ? data.noOfOwnedBy - 1 : 1).fill(temp) : [temp];
        // });

        let noForm = data ? new Array(Number(data.noOfOwnedBy) !== 1 ? Number(data.ownedByCategoryId) === 3 ? Number(data.noOfOwnedBy) : Number(data.noOfOwnedBy) : 1).fill(temp) : [temp];
        setSendForm(noForm)
        setNumberOfInvestor(data ? Number(data.noOfOwnedBy) : 1);
        setInvestorCategory(data ? Number(data.ownedByCategoryId) : 2);
        getOldData(data.id, data);
        getData();
    }, []);


    if (investorCategory === 2) {
        return (
            <div className='container-fluid landformconatiner p-4'>
                <PageHeading pagename="Investor Details" />
                {
                    sendForm && sendForm.map((val, ind) => {
                        return (

                            <div className='wrapform mt-4'>
                                <div className='d-flex flex-column gap-1'>
                                    <label className='form-label'>Inverstor</label>
                                    <select className='form-input-box form-select pe-5' value={val.ownedById} name='ownedById' onChange={(e) => handleChnage(e, ind)}>
                                        <option value="-1">--Select Inverstor --</option>
                                        {
                                            userList && userList.map((val, ind) => {
                                                return (
                                                    <option value={val.id}>
                                                        {val.name}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <div className='d-flex flex-column gap-1' >
                                    <label className='form-label'>Ownership Percentage (%)</label>
                                    <div className='d-flex flex-row' >
                                        {/* <input type='number' name='percentage' value={val.percentage} onChange={(e) => handleChnage(e, ind)} placeholder='Enter Owner Percentage' id={"percentage" + ind} className='form-input-box' style={{ width: "100%", borderRadius: "5px 0px 0px 5px" }} /> */}
                                        <InputBoxNumber name="percentage" value={val.percentage} placeholder='Enter  Owner Percentage' id={"percentage" + ind} setSendForm={handleChnage} isFun={1} index={ind} range={3} min={1} max={100} />

                                        <span class="input-group-text" id="basic-addon1" style={{ borderRadius: "0px 5px 5px 0px", fontSize: "17px", fontWeight: "550" }}>%</span>
                                    </div>
                                </div>
                                <div className='d-flex flex-column gap-1'>
                                    <label className='form-label'>Inflation Percentage (%)</label>
                                    <div className='d-flex flex-row' >
                                        {/* <input type='number' name='inflationPercentage' value={val.inflationPercentage} onChange={(e) => handleChnage(e, ind)} placeholder='Enter Inflation Percentage' id={"inflationPercentage" + ind} className='form-input-box' style={{ width: "100%", borderRadius: "5px 0px 0px 5px" }} /> */}
                                        <InputBoxNumber name="inflationPercentage" value={val.inflationPercentage} placeholder='Enter Inflation Percentage' id={"inflationPercentage" + ind} setSendForm={handleChnage} isFun={1} index={ind} range={3} min={1} max={100} />

                                        <span class="input-group-text" id="basic-addon1" style={{ borderRadius: "0px 5px 5px 0px", fontSize: "17px", fontWeight: "550" }}>%</span>
                                    </div>
                                </div>
                            </div>

                        )
                    })
                }
                <div className='d-flex flex-row justify-content-md-end'>
                    {
                        isUpdate === 0 ?
                            <button className='btn export-save-btn' onClick={handleSave}>Save Investor & Next</button> :
                            <button className='btn export-save-btn' onClick={handleUpdate}>Update Investor & Next</button>
                    }
                </div>

                <Loader show={loader} />
                {
                    sideToster.show === 1 ?
                        <SideMessage close={setSideToster} show={sideToster.show} type={sideToster.type} message={sideToster.message} />
                        : ""
                }
            </div>
        )
    }
    else if (investorCategory === 3) {
        return (
            <>

                <div className='container-fluid landformconatiner p-4'>
                    <PageHeading pagename="Other Investor Details" />
                    {
                        sendForm && sendForm.map((val, ind) => {
                            return (
                                <div className='wrapform mt-4'>
                                    <div className='d-flex flex-column gap-1'>
                                        <label className='form-label'>Inverstor</label>
                                        <select className='form-input-box form-select pe-5' name='ownedById' value={val.ownedById} onChange={(e) => handleChnage(e, ind)}>
                                            <option value="-1">--Select Inverstor--</option>
                                            {
                                                userList && userList.map((val, ind) => {
                                                    return (
                                                        <option value={val.id}>
                                                            {val.name}
                                                        </option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className='d-flex flex-column gap-1' >
                                        <label className='form-label'>Ownership Percentage (%)</label>
                                        <div className='d-flex flex-row'>
                                            <InputBoxNumber name="percentage" value={val.percentage} placeholder='Enter  Owner Percentage' id={"percentage" + ind} setSendForm={handleChnage} isFun={1} index={ind} range={3} min={1} max={100} />
                                            <span class="input-group-text" id="basic-addon1" style={{ borderRadius: "0px 5px 5px 0px", fontSize: "17px", fontWeight: "550" }}>%</span>
                                        </div>
                                    </div>
                                    <div className='d-flex flex-column gap-1'>
                                        <label className='form-label'>Inflation Percentage (%)</label>
                                        <div className='d-flex flex-row' >
                                            <InputBoxNumber name="inflationPercentage" value={val.inflationPercentage} placeholder='Enter Inflation Percentage' id={"inflationPercentage" + ind} setSendForm={handleChnage} isFun={1} index={ind} range={3} min={1} max={100} />
                                            <span class="input-group-text" id="basic-addon1" style={{ borderRadius: "0px 5px 5px 0px", fontSize: "17px", fontWeight: "550" }}>%</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>


                <div className='container-fluid landformconatiner p-4'>
                    <PageHeading pagename="Vifa Details" />
                    <div className='wrapform'>
                        {/* <div className='d-flex flex-column gap-1'>
                            <label className='form-label'>Inverstor</label>
                            <select className='form-input-box form-select pe-5' name='ownedById' disabled>
                                <option value="-1">Vifa</option>
                                {
                                    userList && userList.map((val, ind) => {
                                        return (
                                            <option value={val.id}>
                                                {val.name}
                                            </option>
                                        )
                                    })
                                }
                            </select>
                        </div> */}

                        {/* <div class="input-group mb-3">
                            <span class="input-group-text" id="basic-addon1">@</span>
                            <input type="text" class="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" />
                        </div> */}
                        <div className='d-flex flex-column gap-1' >
                            <label className='form-label'>Ownership Percentage (%)</label>
                            <div className='input-group mb-3' >
                                {/* <input type='number' name='percentage' value={vifaData.percentage} onChange={(e) => handleChangeVifa(e)} placeholder='Enter Owner Percentage' id="percentage" className='form-input-box' style={{ width: "100%", borderRadius: "5px 0px 0px 5px" }} /> */}
                                <InputBoxNumber name="percentage" value={vifaData.percentage} placeholder='Enter  Owner Percentage' id="percentage" isFun={1} range={3} min={1} max={100} disabled={true}/>

                                <span class="input-group-text" id="basic-addon1" style={{ borderRadius: "0px 5px 5px 0px", fontSize: "17px", fontWeight: "550" }}>%</span>
                            </div>
                        </div>
                        {/* <div className='d-flex flex-column gap-1'>
                            <label className='form-label'>Inflation Percentage (%)</label>
                            <div className='d-flex flex-row' >
                                <InputBoxNumber name="inflationPercentage" value={vifaData.inflationPercentage} placeholder='Enter  Inflation Percentage' id="percentage" setSendForm={handleChangeVifa} isFun={1} range={3} min={1} max={100} disabled={true} />

                                <span class="input-group-text" id="basic-addon1" style={{ borderRadius: "0px 5px 5px 0px", fontSize: "17px", fontWeight: "550" }}>%</span>
                            </div>
                        </div> */}

                    </div>
                </div>
                {
                    isUpdate === 0 ?
                        <div className='d-flex flex-row justify-content-md-end'>
                            <button className='btn export-save-btn' onClick={handleSave}>Save Investor & Next</button>
                        </div>
                        :
                        <div className='d-flex flex-row justify-content-md-end'>
                            <button className='btn export-save-btn' onClick={handleUpdate}>Update Investor & Next</button>
                        </div>

                }


                <Loader show={loader} />
                {
                    sideToster.show === 1 ?
                        <SideMessage close={setSideToster} show={sideToster.show} type={sideToster.type} message={sideToster.message} />
                        : ""
                }

            </>
        )

    }
    // return (
    //     <>
    //         {/* <div className='display-container'>
    //             <InvestorShow />
    //         </div> */}
    //         <div className='container-fluid landformconatiner p-4'>

    //             {

    //                 investorCategory === 2 || investorCategory === 3 ?

    //                     new Array(numberOfInvestor).fill(0).map((val, ind) => {
    //                         return (

    //                             <div className='wrapform mt-4'>
    //                                 <div className='d-flex flex-column gap-1'>
    //                                     <label className='form-label'>Inverstor</label>
    //                                     <select className='form-input-box form-select pe-5' name='ownedById' onChange={(e) => handleChnage(e, ind)}>
    //                                         <option value="-1">--select Inverstor --</option>
    //                                         {
    //                                             userList && userList.map((val, ind) => {
    //                                                 return (
    //                                                     <option value={val.id}>
    //                                                         {val.name}
    //                                                     </option>
    //                                                 )
    //                                             })
    //                                         }
    //                                     </select>
    //                                 </div>
    //                                 <div className='d-flex flex-column gap-1' >
    //                                     <label className='form-label'>Ownership Percentage (%)</label>
    //                                     <div className='d-flex flex-row' >
    //                                         <input type='number' name='percentage' onChange={(e) => handleChnage(e, ind)} placeholder='Enter Owner Percentage' id={"percentage" + ind} className='form-input-box' style={{ width: "100%", borderRadius: "5px 0px 0px 5px" }} />
    //                                         <span class="input-group-text" id="basic-addon1" style={{ borderRadius: "0px 5px 5px 0px", fontSize: "17px", fontWeight: "550" }}>%</span>
    //                                     </div>
    //                                 </div>
    //                                 <div className='d-flex flex-column gap-1'>
    //                                     <label className='form-label'>Inflation Percentage (%)</label>
    //                                     <div className='d-flex flex-row' >
    //                                         <input type='number' name='inflationPercentage' onChange={(e) => handleChnage(e, ind)} placeholder='Enter Inflation Percentage' id={"inflationPercentage" + ind} className='form-input-box' style={{ width: "100%", borderRadius: "5px 0px 0px 5px" }} />
    //                                         <span class="input-group-text" id="basic-addon1" style={{ borderRadius: "0px 5px 5px 0px", fontSize: "17px", fontWeight: "550" }}>%</span>
    //                                     </div>
    //                                 </div>
    //                             </div>

    //                         )
    //                     })
    //                     :
    //                     <>

    //                         <div className='wrapform'>
    //                             <div className='d-flex flex-column gap-1'>
    //                                 <label className='form-label'>Inverstor</label>
    //                                 <select className='form-input-box form-select pe-5' name='ownedById' disabled>
    //                                     <option value="-1">Vifa</option>
    //                                     {
    //                                         userList && userList.map((val, ind) => {
    //                                             return (
    //                                                 <option value={val.id}>
    //                                                     {val.name}
    //                                                 </option>
    //                                             )
    //                                         })
    //                                     }
    //                                 </select>
    //                             </div>


    //                             <div className='d-flex flex-column gap-1' >
    //                                 <label className='form-label'>Ownership Percentage (%)</label>
    //                                 <div className='d-flex flex-row' >
    //                                     <input type='number' name='percentage' onChange={(e) => handleChnage(e, 0, 1)} placeholder='Enter Owner Percentage' id="percentage" className='form-input-box' style={{ width: "100%", borderRadius: "5px 0px 0px 5px" }} />
    //                                     <span class="input-group-text" id="basic-addon1" style={{ borderRadius: "0px 5px 5px 0px", fontSize: "17px", fontWeight: "550" }}>%</span>
    //                                 </div>
    //                             </div>
    //                             <div className='d-flex flex-column gap-1'>
    //                                 <label className='form-label'>Inflation Percentage (%)</label>
    //                                 <div className='d-flex flex-row' >
    //                                     <input type='number' name='inflationPercentage' placeholder='Enter Inflation Percentage' className='form-input-box' style={{ width: "100%", borderRadius: "5px 0px 0px 5px" }} disabled />
    //                                     <span class="input-group-text" id="basic-addon1" style={{ borderRadius: "0px 5px 5px 0px", fontSize: "17px", fontWeight: "550" }}>%</span>
    //                                 </div>
    //                             </div>

    //                         </div>

    //                         {
    //                             new Array(numberOfInvestor !== 1 ? numberOfInvestor - 1 : 1).fill(0).map((val, ind) => {
    //                                 return (
    //                                     <div className='wrapform mt-4'>
    //                                         <div className='d-flex flex-column gap-1'>
    //                                             <label className='form-label'>Inverstor</label>
    //                                             <select className='form-input-box form-select pe-5' name='ownedById' onChange={(e) => handleChnage(e, ind)}>
    //                                                 <option value="-1">--select Inverstor--</option>
    //                                                 {
    //                                                     userList && userList.map((val, ind) => {
    //                                                         return (
    //                                                             <option value={val.id}>
    //                                                                 {val.name}
    //                                                             </option>
    //                                                         )
    //                                                     })
    //                                                 }
    //                                             </select>
    //                                         </div>
    //                                         <div className='d-flex flex-column gap-1' >
    //                                             <label className='form-label'>Ownership Percentage (%)</label>
    //                                             <div className='d-flex flex-row' >
    //                                                 <input type='number' name='percentage' onChange={(e) => handleChnage(e, ind)} id={"percentage" + ind} placeholder='Enter Owner Percentage' className='form-input-box' style={{ width: "100%", borderRadius: "5px 0px 0px 5px" }} />
    //                                                 <span class="input-group-text" id="basic-addon1" style={{ borderRadius: "0px 5px 5px 0px", fontSize: "17px", fontWeight: "550" }}>%</span>
    //                                             </div>
    //                                         </div>
    //                                         <div className='d-flex flex-column gap-1'>
    //                                             <label className='form-label'>Inflation Percentage (%)</label>
    //                                             <div className='d-flex flex-row' >
    //                                                 <input type='number' name='inflationPercentage' onChange={(e) => handleChnage(e, ind)} id={"inflationPercentage" + ind} placeholder='Enter Inflation Percentage' className='form-input-box' style={{ width: "100%", borderRadius: "5px 0px 0px 5px" }} />
    //                                                 <span class="input-group-text" id="basic-addon1" style={{ borderRadius: "0px 5px 5px 0px", fontSize: "17px", fontWeight: "550" }}>%</span>
    //                                             </div>
    //                                         </div>
    //                                     </div>
    //                                 )
    //                             })
    //                         }

    //                     </>
    //             }




    //         </div>
    //         <div className='d-flex flex-row justify-content-md-end'>
    //             <button className='btn export-save-btn' onClick={handleSave}>Save Investor & Next</button>
    //         </div>

    //         <Loader show={loader} />
    //         {
    //             sideToster.show === 1 ?
    //                 <SideMessage handleShow={setSideToster} show={sideToster.show} type={sideToster.type} message={sideToster.message} />
    //                 : ""
    //         }


    //     </>
    // )
}
