import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import PageHeading from '../../../../components/PageHeading'
import ProjectImage from "../../../../assets/images/landimg.png"
import AllKhasra from './AllKhasra'
import UpdateProject from '../../../../APIs/Project/UpdateProject'
import Loader from '../../../../components/Loader'
import GetLandDetailsByProjectId from '../../../../APIs/Land/GetLandDetailsByProjectId'
import SideMessage from '../../../../components/SideMessage'

export default function ProjectDetails({ setLandId, projectDataOut, setProjectNameGlobal }) {
    const [projectData, setProjectData] = useState([])
    const [projectName, setProjectName] = useState("")
    const [landmark, setLandmark] = useState("")
    const [loader, setLoader] = useState(false)
    const [sideToster, setSideToster] = useState({
        show: false,
        message: '',
        type: ''
    })

    // const handleShow = async () => {
    //     const projectSessionData = JSON.parse(window.sessionStorage.getItem("ProjectData"))
    //     const projectId = projectSessionData?.projectId

    //     if (projectId) {
    //         const resp = await GetLandDetailsByProjectId(projectId)
    //         if (resp.status === 1) {
    //             window.sessionStorage.setItem("ProjectData", JSON.stringify({
    //                 ...projectSessionData,
    //                 allLandData: resp.responseValue,
    //             }))
    //         }
    //     }
    // }

    // const getData = () => {
    //     const data = JSON.parse(window.sessionStorage.getItem("ProjectData"))?.projectData
    //     if (data) {
    //         setProjectName(data.projectName)
    //         setLandmark(data.landmark)
    //     }
    // }

    const handleSaveForm = async () => {
        if (projectName && landmark) {
            setLoader(true)
            const sendForm = {
                id: projectData.id,
                projectNo: projectData.projectNo.slice(7, 10),
                name: projectName,
                landmark: landmark
            }

            const resp = await UpdateProject(sendForm)
            if (resp.status === 1) {
                const projectSessionData = JSON.parse(window.sessionStorage.getItem("ProjectData"))
                window.sessionStorage.setItem("ProjectData", JSON.stringify({
                    ...projectSessionData,
                    projectData: {
                        projectName: projectName,
                        projectNo: projectData.projectNo,
                        projectId: projectData.id,
                        projectPrice: projectData.totalLandPrice,
                        projectArea: projectData.totalLandArea,
                        landmark: landmark
                    },
                }))
                setProjectNameGlobal(sendForm.name)
                setProjectName(sendForm.name)
                setLandmark(sendForm.landmark)
                setSideToster({
                    show: true,
                    message: "Project Details Updated!",
                    type: 'Success'
                })
            } else {
                setSideToster({
                    show: true,
                    message: "Something Went Wrong!",
                    type: 'Alert'
                })
            }
            setLoader(false)
        } else {
            setSideToster({
                show: true,
                message: "Please Fill Fields!",
                type: 'Alert'
            })
        }
    }

    useEffect(() => {
        // handleShow()
        // const timeoutId = setTimeout(getData, 1000)
        // return () => clearTimeout(timeoutId)
    }, [])

    useEffect(() => {
        setProjectData(projectDataOut)
        setProjectName(projectDataOut.name)
        setLandmark(projectDataOut.landmark)
    }, [projectDataOut])

    return (
        <>
            {projectName && (
                <div className='container-fluid landformconatiner p-4' style={{ maxHeight: "100%" }}>
                    <div className='d-flex flex-row justify-content-between'>
                        <PageHeading pagename={projectName} />
                        <button className='editProject' data-bs-toggle="modal" data-bs-target="#addproject">
                            <i className="fa-regular fa-pen-to-square fa-lg"></i>&nbsp; Edit Details
                        </button>
                    </div>
                    <div className='row'>
                        <span className='text-secondary'>#{landmark}</span>
                    </div>
                    <div className='row pt-3'>
                        <img src={ProjectImage} alt="Project" width="100%" height="100%" />
                    </div>
                    <div className='d-flex flex-row justify-content-between pt-3'>
                        <PageHeading pagename={`₹ ${projectData.totalLandPrice}`} />
                        <div className='d-flex flex-row justify-content-center align-items-center gap-2'>
                            <i className="fa-solid fa-location-arrow locationIcon"></i>
                            <Link to={`/addplotdeatils/${projectData.id}`}>
                                <span className='locationtext'>Update Project Portfolio</span>
                            </Link>
                        </div>
                    </div>
                    <div className='d-flex flex-column gap-3 pt-3'>
                        <div className='d-flex flex-row align-items-center gap-3'>
                            <i className="fa-solid fa-up-right-and-down-left-from-center project-icon"></i>
                            <span className='d-flex flex-column gap-1'>
                                <label className='heading-project'>Total Land Area</label>
                                <label className='heading-project' style={{ color: "#737791" }}>
                                    {projectData.totalLandArea} {projectData.unitName || "sq"}
                                </label>
                            </span>
                        </div>
                        <div className='d-flex flex-row align-items-center gap-3'>
                            <i className="bi bi-geo-alt-fill project-icon"></i>
                            <span className='d-flex flex-column gap-1'>
                                <label className='heading-project'>Location</label>
                                <label className='heading-project' style={{ color: "#737791" }}>{landmark}</label>
                            </span>
                        </div>
                    </div>
                    <AllKhasra setLandId={setLandId} />

                    {/* Modal */}
                    <div className="modal  fade " id="addproject" tabindex="-1" aria-labelledby="addprojectLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content pt-3 ps-3 pe-3 pb-1" style={{ maxWidth: "500px" }}>
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5 text-primary" id="addprojectLabel">Update Project</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setProjectName(projectData.projectName ?? projectData.name) }}></button>
                                </div>
                                <div className="modal-body">
                                    <div className='row pb-4'>
                                        <div className='col pb-2 pb-md-0'>
                                            <div className='d-flex flex-column gap-1'>
                                                <label className='form-label'>Project Name</label>
                                                <input type='text' className='form-input-box' placeholder='Enter Project Name' value={projectName} name="name" onChange={(e) => { setProjectName(e.target.value) }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row pb-4'>
                                        <div className='col pb-2 pb-md-0'>
                                            <div className='d-flex flex-column gap-1'>
                                                <label className='form-label'>Landmark</label>
                                                <input type='text' className='form-input-box' placeholder='Enter Landmark' value={landmark} name="landmark" onChange={(e) => { setLandmark(e.target.value) }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row '>
                                        <div className='col pb-2 pb-md-0'>
                                            <div className='d-flex flex-column gap-1'>
                                                <label className='form-label'>Unique Id</label>
                                                <input type='text' className='form-input-box' value={"#" + projectData.projectNo} disabled />
                                            </div>
                                        </div>
                                        {/* <span className='pt-1 fw-bolder'><p style={{ fontSize: "0.66rem", color: "#FE996C" }}>Unique ID generated automatically</p></span> */}
                                    </div>
                                </div>
                                <div className="modal-footer" >
                                    {/* <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}
                                    <button type="button" style={{ width: "100%" }} className="btn btn-primary" onClick={handleSaveForm} data-bs-dismiss="modal" aria-label="Close">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {sideToster.show && <SideMessage close={setSideToster} show={sideToster.show} type={sideToster.type} message={sideToster.message} />}
            <Loader show={loader} />
        </>
    )
}
