import React, { useEffect, useState } from 'react'
import PageHeading from '../../../components/PageHeading'
import FilterTable from '../../../components/FilterTable'
import GetDealWiseInvestor from '../../../APIs/InvestorPortfolio/GetDealWiseInvestor'
import { Link } from 'react-router-dom'
import Loader from '../../../components/Loader'
import GetOwnerPortfolio from '../../../APIs/OwnerPortfolio/GetOwnerPortfolio'

const OwnerPortfolio = () => {
    const [ownerTempList, setOwnerTempList] = useState([])
    const [ownerList, setOwnerList] = useState([])
    const [dealwiseownerTempList, setDealWiseownerTempList] = useState([])
    const [dealwiseownerList, setDealWiseownerList] = useState([])
    const [isProject, setIsProject] = useState(0)
    let [loader, setLoader] = useState(1)

    const getOwnerPortfolio = async () => {
        setLoader(1)
        const response = await GetOwnerPortfolio()
        if (response.status === 1) {
            setLoader(0)
            setOwnerTempList(response.responseValue)
            setOwnerList(response.responseValue)
        }
        else {
            setOwnerTempList([])
            setOwnerList([])
            setLoader(0)
        }
    }
    const getDealWiseInvestor = async () => {
        const response = await GetDealWiseInvestor()
        if (response.status === 1) {
            setDealWiseownerTempList(response.responseValue)
            setDealWiseownerList(response.responseValue)
        }
        else {
            setDealWiseownerTempList([])
            setDealWiseownerList([])
        }
    }

    useEffect(() => {
        getOwnerPortfolio();
    }, [])

    return (
        <>
            <div className='container-fluid landformconatiner p-4'>
                <div className='d-flex flex-column flex-md-row justify-content-between'>
                    <PageHeading pagename="Owner Portfolio Overview " />
                    <FilterTable tempList={isProject === 0 ? ownerTempList : dealwiseownerTempList} tempListMethod={isProject === 0 ? setOwnerTempList : setDealWiseownerTempList} originalList={isProject === 0 ? ownerList : dealwiseownerList} isSearch={true} />
                </div>

                <div className='pt-4'>
                    <table className="table">
                        <thead>
                            <tr className='table-head'>
                                <th scope="col">Name</th>
                                <th scope="col">In Project</th>
                                <th scope="col">Land Area</th>
                                <th scope="col">Given Amount</th>
                                <th scope="col">Total Amount</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody className='table-body'>
                            {
                                ownerTempList && ownerTempList.map((val, ind) => {
                                    return (
                                        <tr key={ind}>
                                            <td>
                                                <div className='d-flex flex-row gap-2'>
                                                    <div style={{ width: "30px", height: "30px", backgroundColor: "#E4E4E4", borderRadius: "15px" }}></div>
                                                    <span className='d-flex flex-column gap-1'>
                                                        <label className='heading-project fw-semibold'>{val.ownerName}</label>
                                                    </span>
                                                </div>
                                            </td>
                                            <td>{val.projectName}</td>
                                            <td>{val.landArea} {val.unitName}</td>
                                            <td>{val.givenAmount}</td>
                                            <td>{val.investedAmount}</td>
                                            <td >
                                                <div class="dropdown dropend">
                                                    <i class="bi bi-three-dots-vertical " data-bs-toggle="dropdown" aria-expanded="false"></i>
                                                    <ul className="dropdown-menu mr-3">
                                                        <li><Link className="dropdown-item" to={`/investorportfoliooverview/Project/${val.payeeType}/${val.referenceId}/${val.ownerName}/${val.paidToId}/1/0/${val.projectName}`}>View Transaction</Link></li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div> :

            </div>
            <Loader show={loader} />

        </>
    )
}

export default OwnerPortfolio
