import React, { useEffect, useState } from 'react'
import CompareBarGraph from '../../../components/CompareBarGraph'
import ProjectPlotSummeryOverview from './ProjectPlotSummeryOverview'
import LandOwnerReimbursementTable from './LandOwnerReimbursementTable'
import ProjectByName from './ProjectByName'

export default function ProjectPlotSummeryIndex() {
    let [salePurchase, setSalePurchase] = useState(["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July"])
    let [salePurchaseData, setSalePurchaseData] = useState([])

    useEffect(() => {
        setSalePurchaseData([
            {
                label: 'One',
                barPercentage: 0.3,
                backgroundColor: '#40BE54',
                data: [],
                barThickness: 10

            }, {
                label: 'Two',
                barPercentage: 0.3,
                backgroundColor: '#52A1FD',
                data: [],
                barThickness: 10

            },
            {
                label: 'Three',
                barPercentage: 0.3,
                backgroundColor: '#E84C3D',
                data: [],
                barThickness: 10

            }
        ])

    }, [])
    return (
        <>
            <div className='row pb-3 ps-3 pe-3'>
                <ProjectPlotSummeryOverview />
            </div>
            <div className='row pb-3'>
                <div className='col col-md-8'>
                    <div className='d-flex flex-column gap-3'>
                        <CompareBarGraph title="Project Overview" datalabel={salePurchase} dataArray={salePurchaseData} yLabel="Price" xLabel="Months" />
                    </div>
                    <div className='d-flex flex-column mt-3'>
                        <LandOwnerReimbursementTable />
                    </div>
                </div>
                <div className='col col-md-4'>
                    <div className='d-flex flex-column gap-3' >
                        <ProjectByName />
                    </div>
                </div>
            </div>

        </>
    )
}
