import React, { useState } from 'react'
import PageHeading from '../../../../components/PageHeading'

export default function RecentTransaction() {
    let [activeTab, setActiveTab] = useState(1)
    let handleTab = (val) => {
        setActiveTab(val)
    }
    return (
        <>
            <div className='container-fluid landformconatiner p-4'>
                <div className='d-flex flex-row justify-content-between align-items-center'>
                    <PageHeading pagename="Recent Transaction" />
                    <span className='text-blue-color fw-semibold'>
                        View All
                    </span>

                </div>
                <nav class="nav nav-pills flex-column flex-sm-row p-1 gap-2 pt-2">
                    <a class={`flex-sm-fill_ text-sm-center nav-link ${activeTab === 1?"transaction-tab-selected":"transaction-tab"}`}  onClick={() => handleTab(1)} href="#">All</a>
                    <a class={`flex-sm-fill_ text-sm-center nav-link ${activeTab === 2?"transaction-tab-selected":"transaction-tab"}`}  onClick={() => handleTab(2)} href="#">Project</a>
                    <a class={`flex-sm-fill_ text-sm-center nav-link ${activeTab === 3?"transaction-tab-selected":"transaction-tab"}`}  onClick={() => handleTab(3)} href="#">Expenses</a>
                    <a class={`flex-sm-fill_ text-sm-center nav-link ${activeTab === 4?"transaction-tab-selected":"transaction-tab"}`}  onClick={() => handleTab(4)} href="#">Investor</a>
                </nav>
                <div className='d-flex flex-column gap-3 pt-4'>
                    <div className='d-flex flex-row justify-content-between pb-2 rowBox' >
                        <div className='d-flex flex-row gap-2'>
                            <div style={{ width: "30px", height: "30px", backgroundColor: "#E4E4E4", borderRadius: "15px" }}></div>
                            <span className='d-flex flex-column gap-2'>
                                <label className='heading-project fw-semibold'></label>
                                <label className='sub-heading-project'> </label>
                                <label className='sub-heading-project2' >Remaining ₹0</label>
                            </span>
                        </div>
                        <div className='d-flex flex-row'>
                            <PageHeading pagename="₹ 50Cr." />
                        </div>
                    </div>
                    <div className='d-flex flex-row justify-content-between  pb-2 rowBox' >
                        <div className='d-flex flex-row gap-2'>
                            <div style={{ width: "30px", height: "30px", backgroundColor: "#03D4C6", borderRadius: "15px" }}></div>
                            <span className='d-flex flex-column gap-1'>
                                <label className='heading-project fw-semibold'></label>
                                <label className='sub-heading-project'> </label>
                                <label className='sub-heading-project2' >Remaining ₹0</label>

                            </span>
                        </div>
                        <div className='d-flex flex-row'>
                            <PageHeading pagename="₹ 25Cr." />
                        </div>
                    </div>
                    <div className='d-flex flex-row justify-content-between  pb-2 rowBox' >
                        <div className='d-flex flex-row gap-2'>
                            <div style={{ width: "30px", height: "30px", backgroundColor: "#006CFF", borderRadius: "15px" }}></div>
                            <span className='d-flex flex-column gap-1'>
                                <label className='heading-project fw-semibold'></label>
                                <label className='sub-heading-project'> </label>
                                <label className='sub-heading-project2' >Remaining ₹0</label>

                            </span>
                            <span>

                            </span>
                        </div>
                        <div className='d-flex flex-row'>
                            <PageHeading pagename="₹ 30Cr." />
                        </div>
                    </div>
                    <div className='d-flex flex-row justify-content-between pb-2 rowBox' >
                        <div className='d-flex flex-row gap-2'>
                            <div style={{ width: "30px", height: "30px", backgroundColor: "#FE996C", borderRadius: "15px" }}></div>
                            <span className='d-flex flex-column gap-1'>
                                <label className='heading-project fw-semibold'></label>
                                <label className='sub-heading-project'> </label>
                                <label className='sub-heading-project2' >Remaining ₹0</label>

                            </span>
                        </div>
                        <div className='d-flex flex-row'>
                            <PageHeading pagename="₹ 10Cr." />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
