import React, { useState } from 'react'
import LoginImag from "../../assets/images/LoginImage.png"
import UserLogin from '../../APIs/Login/UserLogin';
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader';
import SideMessage from '../../components/SideMessage';
export default function Login() {

    let navigate = useNavigate()
    let [sendForm, setSendForm] = useState({
        "userName": "",
        "password": ""
    })
    let [sideToster, setSideToster] = useState({
        show: 0,
        message: '',
        type: ''
      })
    let [loader, setLoader] = useState(0)

    let handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setSendForm({ ...sendForm, [name]: value })
    }

    let handleSave = async () => {
        if (sendForm.userName.trim() !== "" && sendForm.password.trim() !== "") {
            setLoader(1)
            let response = await UserLogin(sendForm)
            if (response.status === 1) {
                setLoader(0)
                window.sessionStorage.setItem("userLoginData", JSON.stringify({ token: response.token, userData: response.responseValue }))
                window.userId = response.responseValue.id
                window.token = "Bearer " + response.token
                window.userName = response.responseValue.name
                navigate("/allproject/")
            }
            else {
                setSideToster({
                    show: 1,
                    message: response.responseValue,
                    type: 'Alert'
                })
                setLoader(0)
            }
        }
        else{
            setSideToster({
                show: 1,
                message: "Please Fill Both Fields",
                type: 'Alert'
            })
        }
    }
    let handleEnterSave = (e) => {
        if (e.keyCode === 13) {
            handleSave()
        }
    }
    return (
        <div className='container-fluid'>
            <div className='row' style={{ minHeight: "100vh" }}>
                <div className='d-none d-md-flex col-md-6 loginimage' >
                </div>
                <div className='d-flex d-flex flex-column justify-content-center align-items-center col-12 col-md-6'>
                    <div className='d-flex flex-column gap-4'>
                        <div className='d-flex flex-column justify-content-center align-items-center gap-1 pb-3'>
                            <span className='login-heading-main'>Log in to your account</span>
                            <span className='login-sub-heading'>Welcome back! Select method to log in.</span>
                        </div>
                        <div className='d-flex flex-column gap-4 '>
                            <div className='row'>
                                <div className='col'>
                                    <div className='d-flex flex-column gap-1'>
                                        <label className='form-label'>Email</label>
                                        <div className='d-flex flex-column textboxcontainer'>
                                            <input type='email' placeholder='Enter user id here' className='form-input-box' name='userName' style={{ maxWidth: "650px", paddingLeft: "40px" }} onChange={handleChange} onKeyDown={handleEnterSave} />
                                            <i className="bi bi-envelope emailicon"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col'>
                                    <div className='d-flex flex-column gap-1'>
                                        <label className='form-label'>Password</label>
                                        <div className='d-flex flex-column textboxcontainer'>
                                            <input type='password' placeholder='Enter password here' className='form-input-box' name='password' style={{ maxWidth: "650px", paddingLeft: "40px" }} onChange={handleChange} onKeyDown={handleEnterSave} />
                                            <i className="bi bi-lock emailicon"></i>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* <div className='row'>
                                <div className='d-flex flex-column flex-md-row justify-content-between'>
                                    <div className="mb-3 form-check">
                                        <input type="checkbox" className="form-check-input checkboxlogin" id="exampleCheck1" />
                                        <label className="form-check-label passwordlabel" for="exampleCheck1">Remember me</label>
                                    </div>
                                    <Link to="/forgotpassword/"> <span className='passwordlabel' style={{ color: "#151D48" }}>Forgot Password?</span></Link>
                                </div>
                            </div> */}
                        </div>
                        <div className='row'>
                            <button className='btn login-btn' onClick={handleSave}>Login</button>
                        </div>
                        {/* <div className='row'>
                            <span className='passwordlabel'>or continue with email</span>
                        </div> */}
                        {/* <div className='row ps-3 pe-3'>
                            <div className='d-flex flex-row justify-content-between '>
                                <div className='col-5 row'>
                                    <button className='btn login-other'>
                                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 48 48">
                                            <path fill="#fbc02d" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12	s5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24s8.955,20,20,20	s20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"></path><path fill="#e53935" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039	l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"></path><path fill="#4caf50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36	c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"></path><path fill="#1565c0" d="M43.611,20.083L43.595,20L42,20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571	c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"></path>
                                        </svg>&nbsp;
                                        <span>Google</span></button>
                                </div>

                                <div className='col-5 row'>
                                    <button className='btn login-other'>
                                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25" height="25" viewBox="0,0,256,256">
                                            <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style={{ "mix-blend-mode": "normal" }}><g transform="scale(5.33333,5.33333)"><path d="M24,5c-10.49341,0 -19,8.50659 -19,19c0,10.49341 8.50659,19 19,19c10.49341,0 19,-8.50659 19,-19c0,-10.49341 -8.50659,-19 -19,-19z" fill="#039be5"></path><path d="M26.572,29.036h4.917l0.772,-4.995h-5.69v-2.73c0,-2.075 0.678,-3.915 2.619,-3.915h3.119v-4.359c-0.548,-0.074 -1.707,-0.236 -3.897,-0.236c-4.573,0 -7.254,2.415 -7.254,7.917v3.323h-4.701v4.995h4.701v13.729c0.931,0.14 1.874,0.235 2.842,0.235c0.875,0 1.729,-0.08 2.572,-0.194z" fill="#ffffff"></path></g></g>
                                        </svg>&nbsp;
                                        <span>Facebook
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className='d-flex flex-column gap-2 flex-md-row passwordlabel'>
                            <span>Don’t have an account?</span> <span className='text-blue-color'> <Link to="/signup/"> Create an account</Link></span>
                        </div> */}
                    </div>
                </div>
            </div>
            <Loader show={loader} />
            {
        sideToster.show === 1 ?
          <SideMessage close={setSideToster} show={sideToster.show} type={sideToster.type} message={sideToster.message} />
          : ""
      }
        </div>
    )
}
