export default async function GetTransactionDashboardData(investmentType, payeeType, referenceId,paidId) {
    try {
        let url = window.RealestateService + `/api/PaymentDetails/GetTransactionDashboardData?investmentType=${investmentType}&payeeType=${payeeType}&referenceId=${referenceId}&paidToId=${paidId}`
        let head = {
            "content-type": "application/json",
            "accept":"*/*"
        }
        let response = await fetch(url, {
            method: "GET",
            headers: head,
        }).then(res => res.json()).catch(res => res)

        return response
    }
    catch (ex){
        return {
            status:0,
            message:"",
            responseValue:[]
        }
    }
}