import React, { useEffect, useState } from 'react'
import GetExpense from '../../APIs/Master/Expense/GetExpense'
import GetPaymentMaster from '../../APIs/Master/Payment/GetPaymentMaster'
import { useParams } from 'react-router-dom'
import InsertProjectExpense from '../../APIs/AddExpense/InsertProjectExpense'
import Loader from '../../components/Loader'
import SideMessage from '../../components/SideMessage'
import UpdateProjectExpense from '../../APIs/AddExpense/UpdateProjectExpense'
import InputBoxNumber from '../../components/InputBoxNumber'
import GetVendorDetails from '../../APIs/AddVendor/GetVendorDetails'
import GetVendorTerm from '../../APIs/AddExpense/GetVendorTerm'
import AddTermModal from './AddTermModal'
import GetInvestorDetails from '../../APIs/AddInvestor/GetInvestorDetails'

export default function AddExpensesModal(props) {

    let [expenseList, setExpenseList] = useState([])
    let [paymentList, setPaymentList] = useState([])
    let [vendorList, setVendorList] = useState([])
    let [vendorTermList, setVendorTermList] = useState([])
    let [investorList, setInvestorList] = useState([])
    let [loader, setLoader] = useState(0)
    let [showTermModal, setShowTermModal] = useState(0)
    let [sideToster, setSideToster] = useState({
        show: 0,
        message: '',
        type: ''
    })

    let [sendForm, setSendForm] = useState(
        {
            "userId": window.userId,
            "ExpenseId": "-1",
            "VendorId": "-1",
            "Amount": "",
            "PaymentDate": "",
            "TermId": "-1",
            "Quantity": "",
            "IsVifa": false,
            "InvestorId": 0


        }
    )
    let projectid = useParams()
    let handleFileUpload = (e) => {
        if (e.target.files[0] !== "") {
            setSendForm({ ...sendForm, projectExpenseFiles: e.target.files[0] })
        }
    }

    let getData = async () => {
        let expense = await GetExpense()
        let payment = await GetPaymentMaster()
        let vendor = await GetVendorDetails()
        let responseInvestor = await GetInvestorDetails()

        if (expense.status === 1) {
            setExpenseList(expense.responseValue)
        }
        if (payment.status === 1) {
            setPaymentList(payment.responseValue)
        }
        if (vendor.status === 1) {
            setVendorList(vendor.responseValue)
        }
        if (responseInvestor.status === 1) {
            setInvestorList(responseInvestor.responseValue)
        }
    }
    let getTerms = async (value) => {
        let response = await GetVendorTerm(parseInt(value))
        if (response.status === 1) {
            setVendorTermList(response.responseValue)
        }
    }
    let handleChange = async (e) => {
        let name = e.target.name;
        let value = e.target.value
        if (name === "VendorId") {
            getTerms(value)
            setSendForm({ ...sendForm, [name]: value })

        }
        else if (name === "TermId" && value === "0") {
            setShowTermModal(1)
            setSendForm({ ...sendForm, [name]: "-1" })
            // setSendForm({ ...sendForm, [name]: value })

        }

        else if (name === "IsVifa") {
            setSendForm({ ...sendForm, [name]: !sendForm.IsVifa })
            if (!sendForm.IsVifa === false) {
                let response = await GetInvestorDetails()
                if (response.status === 1) {
                    setInvestorList(response.responseValue)
                }
            }
        }

        else {
            setSendForm({ ...sendForm, [name]: value })

        }
    }

    let handleSaveForm = async () => {
        // if (sendForm.ExpenseId !== "-1" && sendForm.PaymentId !== "-1" && sendForm.Amount !== "" && sendForm.PaymentDate !== "" && sendForm.VendorId !== "-1") {


        if (sendForm.ExpenseId !== "-1" && sendForm.Amount !== "") {
            setLoader(1)
            let formData = new FormData()
            Object.keys(sendForm).map((name) => {
                formData.append(name, sendForm[name]);
            })
            formData.append("ReferenceId", projectid.id)
            let resp = await InsertProjectExpense(formData)
            if (resp.status === 1) {
                setLoader(0)
                setSideToster({
                    show: 1,
                    message: 'Data Saved',
                    type: 'Success'
                })
                clear()
                // window.location.reload()
                props.closeModal(0)
            }
            else {
                setSideToster({
                    show: 1,
                    message: resp.responseValue,
                    type: 'Alert'
                })
                setLoader(0)
            }
        }
        else {

            if (sendForm.Amount === "") {
                setSideToster({
                    show: 1,
                    message: 'Please Enter Amount',
                    type: 'Alert'
                })

            }
            else if (sendForm.ExpenseId === "-1") {

                setSideToster({
                    show: 1,
                    message: 'Please Select Expenses',
                    type: 'Alert'
                })
            }
            else if (sendForm.PaymentId === "-1") {
                setSideToster({
                    show: 1,
                    message: 'Please Select Payment',
                    type: 'Alert'
                })

            }
            else if (sendForm.VendorId === "-1") {
                setSideToster({
                    show: 1,
                    message: 'Please Select Vendor',
                    type: 'Alert'
                })

            }

            else if (sendForm.Quantity === "" || sendForm.Quantity.length === 0) {
                setSideToster({
                    show: 1,
                    message: 'Please Enter PaymentDate',
                    type: 'Alert'
                })

            }
        }

    }

    let handleUpdate = async () => {

        let resp = await UpdateProjectExpense(sendForm)
        if (resp.status === 1) {
            window.location.reload()
            props.closeModal(0)
        }
    }

    let clear = () => {
        setSendForm(
            {
                "userId": window.userId,
                "ExpenseId": "-1",
                "PaymentId": "-1",
                "Amount": "",
                "PaymentDate": "",
                "TermId": "-1",
                "Quantity": 0,
            }
        )

    }

    useEffect(() => {
        if (props.isEdit === 1) {
            console.log("dataaaa", props.editData)
            let t = {
                "userId": window.userId,
                "ExpenseId": props.editData.expenseId,
                "PaymentId": props.editData.paymentId,
                "Amount": props.editData.amount,
                "PaymentDate": props.editData.date.split("T")[0],
                "id": props.editData.id
            }
            setTimeout(() => {
                setSendForm(t)
            }, 600)
        }
        getData()
    }, [])
    return (
        <>
            <div className={`modal  d-${props.showModal === 0 ? 'none' : 'block'} `} id="addexpenses" tabindex="-1" aria-labelledby="addexpensesLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content pt-3 ps-3 pe-3 pb-1" style={{ maxWidth: "350px" }}>
                        <div className="modal-header">
                            <h1 className="modal-title fs-5 text-primary" id="addexpensesLabel">Add Expenses</h1>
                            <button type="button" className="btn-close" onClick={() => { props.closeModal(0) }} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className='row pb-4'>
                                <div className='col pb-2 pb-md-0'>
                                    <div className='d-flex flex-column gap-1'>
                                        <label className='form-label'>Expenses Category Type</label>
                                        <select className='form-input-box form-select pe-5' name='ExpenseId' value={sendForm.ExpenseId} onChange={handleChange} >
                                            <option value="-1">--Select Expenses Category Type--</option>
                                            {
                                                expenseList && expenseList.map((val, ind) => {
                                                    return (
                                                        <option value={val.id}>
                                                            {val.name}
                                                        </option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            {
                                sendForm.ExpenseId === "2" ?
                                    <div className='row pb-4'>
                                        <div className='col pb-2 pb-md-0'>
                                            <div className='d-flex flex-column gap-1'>
                                                <label className='form-label'>Given By</label>
                                                <div className='d-flex flex-row gap-3'>
                                                    <div> <input type='radio' name='IsVifa' defaultChecked={sendForm.vifa} onChange={handleChange} /> Vifa</div>
                                                    <div> <input type='radio' name='IsVifa' defaultChecked={!sendForm.IsVifa} onChange={handleChange} /> Investor </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : ""
                            }
                            {/*  investor list */}
                            {
                                sendForm.IsVifa === false && sendForm.ExpenseId === "2" ?
                                    <div className='row pb-4'>
                                        <div className='col pb-2 pb-md-0'>
                                            <div className='d-flex flex-column gap-1'>
                                                <label className='form-label'>Investor</label>
                                                <select className='form-input-box form-select pe-5' name='InvestorId' value={sendForm.InvestorId} onChange={handleChange} >
                                                    <option value="-1">--Select Investor --</option>
                                                    {
                                                        investorList && investorList.map((val, ind) => {
                                                            return (
                                                                <option value={val.id}>
                                                                    {val.name}
                                                                </option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    : ""
                            }

                            {
                                sendForm.ExpenseId === "1" ?
                                    <div className='row pb-4'>
                                        <div className='col pb-2 pb-md-0'>
                                            <div className='d-flex flex-column gap-1'>
                                                <label className='form-label'>Vendor</label>
                                                <select className='form-input-box form-select pe-5' name='VendorId' value={sendForm.VendorId} onChange={handleChange} >
                                                    <option value="-1">--Select Vendor --</option>
                                                    <option value="0">No Vendor</option>
                                                    {
                                                        vendorList && vendorList.map((val, ind) => {
                                                            return (
                                                                <option value={val.id}>
                                                                    {val.name}
                                                                </option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div> : ""
                            }

                            {/* add term */}

                            {
                                sendForm.VendorId !== "-1" && sendForm.VendorId !== "0" ?
                                    <div className='row pb-4'>
                                        <div className='col pb-2 pb-md-0'>
                                            <div className='d-flex flex-column gap-1'>
                                                <label className='form-label'>Vendor Term</label>
                                                <select className='form-input-box form-select pe-5' name='TermId' value={sendForm.TermId} onChange={handleChange} >
                                                    <option value="-1">--Select Vendor Term--</option>
                                                    <option value="0" >Add Term</option>
                                                    {
                                                        vendorTermList && vendorTermList.map((val, ind) => {
                                                            return (
                                                                <option value={val.id}>
                                                                    {val.name}
                                                                </option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    : ""
                            }

                            <div className='row pb-4'>
                                <div className='col pb-2 pb-md-0'>
                                    <div className='d-flex flex-column gap-1'>
                                        <label className='form-label'>Amount (&#8377;)</label>
                                        {/* <input type='number' className='form-input-box' value={sendForm.Amount} placeholder='Enter Amount' name="Amount" onChange={handleChange} /> */}
                                        <InputBoxNumber value={sendForm.Amount} placeholder='Enter Amount' name="Amount" setSendForm={setSendForm} range={10} min={1} max={10000000} />
                                    </div>
                                </div>
                            </div>
                            {
                                sendForm.ExpenseId === "1" || sendForm.ExpenseId === "2" ?
                                    <div className='row pb-4'>
                                        <div className='col pb-2 pb-md-0'>
                                            <div className='d-flex flex-column gap-1'>
                                                <label className='form-label'>Quantity</label>
                                                {/* <input type='number' className='form-input-box' value={sendForm.Amount} placeholder='Enter Amount' name="Amount" onChange={handleChange} /> */}
                                                <InputBoxNumber value={sendForm.Quantity} placeholder='Enter Quantity' name="Quantity" setSendForm={setSendForm} range={10} min={1} max={10000000} />
                                            </div>
                                        </div>
                                    </div> : ""
                            }


                            {/* {
                                sendForm.VendorId !== '-1' && sendForm.VendorId !== "0" ?
                                    <div className='row pb-4'>
                                        <div className='col pb-2 pb-md-0'>
                                            <div className='d-flex flex-column gap-1'>
                                                <label className='form-label'>Add Terms</label>
                                                <select className='form-input-box form-select pe-5' name='VendorId' value={sendForm.VendorId} onChange={handleChange} >
                                                    <option value="-1">--Select Terms --</option>
                                                    <option value="0"> + Add Term</option>
                                                    {
                                                        vendorList && vendorList.map((val, ind) => {
                                                            return (
                                                                <option value={val.id}>
                                                                    {val.name}
                                                                </option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div> :
                                    <></>
                            } */}

                            {/* <div className='row pb-4'>
                                <div className='col pb-2 pb-md-0'>
                                    <div className='d-flex flex-column gap-1'>
                                        <label className='form-label'>Payment Type</label>
                                        <select className='form-input-box form-select pe-5' name='PaymentId' onChange={handleChange} value={sendForm.PaymentId}>
                                            <option value="-1">--Select Payment Type--</option>
                                            {
                                                paymentList && paymentList.map((val, ind) => {
                                                    return (
                                                        <option value={val.id}>
                                                            {val.name}
                                                        </option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className='row pb-4'>
                                <div className='col pb-2 pb-md-0'>
                                    <div className='d-flex flex-column gap-1'>
                                        <label className='form-label'>PaymentDate</label>
                                        <input type='date' name="PaymentDate" className='form-input-box' id="PaymentDate" onChange={handleChange} value={sendForm.PaymentDate} />
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className='row ' >
                                <div className='col pb-2 pb-md-0' >
                                    <div className='d-flex flex-column gap-1' >
                                        <label className='form-label'>Attachment</label>
                                        <div className='d-flex flex-column  align-items-center fileuploadbox pt-2' style={{ maxHeight: "100px", minHeight: "100px" }}>
                                            <i class="bi bi-cloud-upload-fill"></i>
                                            <span className='gardiantext'>Drag & drop your file here</span>
                                            <input type='file' className='fileinput' onChange={handleFileUpload} />
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <div className="modal-footer" >

                            {
                                props.isEdit !== 1 ?

                                    <button type="button" style={{ width: "100%" }} className="btn btn-primary" onClick={handleSaveForm} data-bs-dismiss="modal" aria-label="Close">Add Expenses</button>
                                    :
                                    <button type="button" style={{ width: "100%" }} className="btn btn-primary" onClick={handleUpdate} data-bs-dismiss="modal" aria-label="Close">Update Expenses</button>

                            }
                        </div>
                    </div>
                </div>
            </div>
            <Loader show={loader} />

            {
                showTermModal &&
                <AddTermModal setShowTermModal={setShowTermModal} VendorId={sendForm.VendorId} getTerms={getTerms}  />
            }

            {
                sideToster.show === 1 ?
                    <SideMessage close={setSideToster} show={sideToster.show} type={sideToster.type} message={sideToster.message} />
                    : ""
            }
        </>
    )
}
