export default async function GetExpenseCategory(id) {
    let url = window.RealestateService + `/api/Expense/GetExpenseCategory`
    let head = {
        "content-type": "application/json",
        "accept": "*/*"
    }
    let response = await fetch(url, {
        method: "GET",
        headers: head,
    }).then(res => res.json()).catch(res => res)

    return response
}