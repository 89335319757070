export default async function UpdateExpense(sendForm) {
    let url = window.RealestateService + "/api/Expense/UpdateExpense";
    let head = {
        "content-type": "application/json",
        "accept": "*/*"
    };
    let data = {};
    
    let response = await fetch(url, {
        method:"POST",
        headers: head,
        body: JSON.stringify(sendForm) // Ensure sendForm is a JSON object
    })
    .then(res => res.json())
    .then(data)
    .catch(res => res);

    return response;
}
