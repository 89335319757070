import React, { useEffect, useState } from 'react'
import SalesOverview from './SalesOverview'
import CompareBarGraph from '../../../components/CompareBarGraph'
import SalesAnalytics from './SalesAnalytics'
import RecentLeads from './RecentLeads'

export default function SalesOverviewIndex() {

  let [salePurchase, setSalePurchase] = useState(["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July"])
  let [salePurchaseData, setSalePurchaseData] = useState([])

  useEffect(() => {
    setSalePurchaseData([
      {
        label: 'One',
        barPercentage: 0.3,
        backgroundColor: '#40BE54',
        data: [],
        barThickness: 10

      }, {
        label: 'Two',
        barPercentage: 0.3,
        backgroundColor: '#52A1FD',
        data: [],
        barThickness: 10

      },
      {
        label: 'Three',
        barPercentage: 0.3,
        backgroundColor: '#E84C3D',
        data: [],
        barThickness: 10

      }
    ])

  }, [])
  return (
    <>
      <div className='row pb-3'>
        <SalesOverview />
      </div>
      <div className='row pb-3'>
        <div className='col col-md-8'>
          <div className='d-flex flex-column gap-3'>
            <CompareBarGraph title="Project Overview" datalabel={salePurchase} dataArray={salePurchaseData} yLabel="Price" xLabel="Months" />
          </div>
        </div>
        <div className='col col-md-4'>
          <div className='d-flex flex-column gap-3' >
            <SalesAnalytics />
          </div>
        </div>
      </div>
      <div className='row pb-3'>
        <RecentLeads />
      </div>
    </>
  )
}

