export default async function GetLandDetailsByProjectId(id) {
    let url = window.RealestateService+  `/api/Land/GetLandDetailsByProjectId?projectId=${id}`
    let head = {
        "content-type":"application/json",
        "accept":"*/*"
    }
    let response = await fetch(url, {
        method:"GET",
        headers:head,
    }).then(res=>res.json()).catch(res=>res)

    return response
}