import './App.css';
import Routing from './Service/Routing';

function App() {

  window.RealestateService = "https://adminapi.vifadevelopers.com"
  window.UserService = "https://userapi.vifadevelopers.com"
  // window.UserService = "https://localhost:7215"
  // window.RealestateService = "https://localhost:7088"

  window.userId = window.sessionStorage.getItem("userLoginData") ? JSON.parse(window.sessionStorage.getItem("userLoginData")).userData.id : ""
  window.token = window.sessionStorage.getItem("userLoginData") ? "Bearer " + JSON.parse(window.sessionStorage.getItem("userLoginData")).token : ""
  return (
    <Routing />
  );
}

export default App;
