import React, { useEffect, useState } from 'react'
import PageHeading from '../../../components/PageHeading'
import FilterTable from '../../../components/FilterTable'
import GetInvestorPortfolio from '../../../APIs/InvestorPortfolio/GetInvestorPortfolio'
import GetDealWiseInvestor from '../../../APIs/InvestorPortfolio/GetDealWiseInvestor'
import { Link } from 'react-router-dom'
import Loader from '../../../components/Loader'

const InvestorPortfolio = () => {
    const [investorTempList, setInvestorTempList] = useState([])
    const [investorList, setInvestorList] = useState([])
    const [dealwiseinvestorTempList, setDealWiseInvestorTempList] = useState([])
    const [dealwiseinvestorList, setDealWiseInvestorList] = useState([])
    const [isProject, setIsProject] = useState(0)
    let [loader, setLoader] = useState(1)

    const getInvestorPortfolio = async () => {
        setLoader(1)
        const response = await GetInvestorPortfolio()
        if (response.status === 1) {
            setLoader(0)
            setInvestorTempList(response.responseValue)
            setInvestorList(response.responseValue)
        }
        else {
            setInvestorTempList([])
            setInvestorList([])
            setLoader(0)
        }
    }
    const getDealWiseInvestor = async () => {
        const response = await GetDealWiseInvestor()
        if (response.status === 1) {
            setDealWiseInvestorTempList(response.responseValue)
            setDealWiseInvestorList(response.responseValue)
        }
        else {
            setDealWiseInvestorTempList([])
            setDealWiseInvestorList([])
        }
    }

    useEffect(() => {
        getInvestorPortfolio();
        getDealWiseInvestor();
    }, [])

    return (
        <>
            <div className='container-fluid landformconatiner p-4'>
                <div className='d-flex flex-column flex-md-row justify-content-between'>
                    <PageHeading pagename="Investors Portfolio Overview " />
                    <div className="btngroup">
                        <div className="row">
                            <div className="col-md">
                                <input type="radio" className="btn-check" name="btnradio" id="btnradio1" autoComplete="off" checked={isProject === 0} onChange={() => { setIsProject(0); getInvestorPortfolio(); }} />
                                <label className="btn btn-project" htmlFor="btnradio1">Project</label>
                            </div>
                            <div className="col-md">
                                <input type="radio" className="btn-check" name="btnradio" id="btnradio2" autoComplete="off" checked={isProject === 1} onChange={() => { setIsProject(1); getDealWiseInvestor(); }} />
                                <label className="btn btn-Individual" htmlFor="btnradio2">Individual</label>
                            </div>
                        </div>
                    </div>

                    <FilterTable tempList={isProject === 0 ? investorTempList : dealwiseinvestorTempList} tempListMethod={isProject === 0 ? setInvestorTempList : setDealWiseInvestorTempList} originalList={isProject === 0 ? investorList : dealwiseinvestorList} isSearch={true} />
                </div>
                {isProject === 0 ?
                    <div className='pt-4'>
                        <table className="table">
                            <thead>
                                <tr className='table-head'>
                                    <th scope="col">Name</th>
                                    <th scope="col">In Project</th>
                                    <th scope="col">Khasra  No.</th>
                                    <th scope="col">Land Area</th>
                                    <th scope="col">Investment Stats</th>
                                    <th scope="col">Pending Amount (₹)</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody className='table-body'>
                                {
                                    investorTempList && investorTempList.map((val, ind) => {
                                        return (
                                            <tr key={ind}>
                                                <td>
                                                    <div className='d-flex flex-row gap-2'>
                                                        <div style={{ width: "30px", height: "30px", backgroundColor: "#E4E4E4", borderRadius: "15px" }}></div>
                                                        <span className='d-flex flex-column gap-1'>
                                                            <label className='heading-project fw-semibold'>{val.investorName}</label>
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>{val.projectName}</td>
                                                <td>{val.khasraNo}</td>
                                                <td>{val.landArea} {val.unitName}</td>
                                                <td>
                                                    <span style={{ color: 'red', marginRight: '10px' }}>{val.amountTobePaid}</span>
                                                    <i className="fa-solid fa-arrow-down" style={{ color: 'red', marginRight: '20px' }}></i>
                                                    <span style={{ color: 'green', marginLeft: '10px' }}>{val.givenAmount}</span>
                                                    <i className="fa-solid fa-arrow-up" style={{ color: 'green', marginLeft: '5px' }}></i>
                                                </td>
                                                <td>{val.pendingAmount}</td>
                                                <td >
                                                    <div class="dropdown dropend">
                                                        <i class="bi bi-three-dots-vertical " data-bs-toggle="dropdown" aria-expanded="false"></i>
                                                        <ul className="dropdown-menu mr-3">

                                                            <li><Link className="dropdown-item" to={`/investorportfoliooverview/Project/${val.payeeType}/${val.referenceId}/${val.investorName}/${val.paidToId}/1/0/${val.projectName}`}>View Transaction Details</Link></li>

                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div> :
                    <div className='pt-4'>
                        <table className="table">
                            <thead>
                                <tr className='table-head'>
                                    <th scope="col">Name</th>
                                    <th scope="col">Investment Stats</th>
                                    <th scope="col">Pending Amount (₹)</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody className='table-body'>
                                {
                                    dealwiseinvestorTempList && dealwiseinvestorTempList.map((val, ind) => {
                                        return (
                                            <tr key={ind}>
                                                <td>
                                                    <div className='d-flex flex-row gap-2'>
                                                        <div style={{ width: "30px", height: "30px", backgroundColor: "#E4E4E4", borderRadius: "15px" }}></div>
                                                        <span className='d-flex flex-column gap-1'>
                                                            <label className='heading-project fw-semibold'>{val.investorName}</label>
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <span style={{ color: 'red', marginRight: '10px' }}>{val.amountTobePaid}</span>
                                                    <i className="fa-solid fa-arrow-down" style={{ color: 'red', marginRight: '20px' }}></i>
                                                    <span style={{ color: 'green', marginLeft: '10px' }}>{val.givenAmount}</span>
                                                    <i className="fa-solid fa-arrow-up" style={{ color: 'green', marginLeft: '5px' }}></i>
                                                </td>
                                                <td>{val.pendingAmount}</td>
                                                <td >
                                                    <div class="dropdown dropend">
                                                        <i class="bi bi-three-dots-vertical " data-bs-toggle="dropdown" aria-expanded="false"></i>
                                                        <ul className="dropdown-menu mr-3">

                                                            <li><Link className="dropdown-item" to={`/investorportfoliooverview/Deal/${val.payeeType}/${val.referenceId}/${val.investorName}/${val.paidToId}/1/0/${val.projectName}`}>View Transaction</Link></li>

                                                            {/* <li><span className="dropdown-item" onClick={() => { handleDelete(val.id) }}>Delete</span></li> */}
                                                            {/* <li onClick={handleLogout}><Link className="dropdown-item" href="#">Logout <i className="bi bi-box-arrow-right"></i></Link></li> */}
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>}
            </div>
            <Loader show={loader} />

        </>
    )
}

export default InvestorPortfolio
