export default async function GetOwnerPortfolio() {
    let url = window.RealestateService + `/api/PaymentDetails/GetProjectWiseOwnerPaymentDetails`
    let head = {
        "content-type": "application/json",
        "accept": "*/*"
    }
    let response = await fetch(url, {
        method: "GET",
        headers: head,
    }).then(res => res.json()).catch(res => res)

    return response
}