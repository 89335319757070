import React, { useEffect, useState } from 'react'
import ProjectSummery from './LeftSide/ProjectSummery'
import ProjectDetails from './LeftSide/ProjectDetails'
import ProjectOver from './RightSide/ProjectOver'
import ProjectExpenses from './RightSide/ProjectExpenses'
import RecentTransaction from './RightSide/RecentTransaction'
import LandOwners from './RightSide/LandOwners'
import GetProject from '../../../APIs/Project/GetProjectById'
import InvestorDetails from './RightSide/InvestorDetails'
import GetLandDetailsByProjectId from '../../../APIs/Land/GetLandDetailsByProjectId'

export default function ProjectDashboard() {
  let [landId, setLandId] = useState("")
  let [projectName, setProjectName] = useState("")
  let [projectData, setProjectData] = useState([])
  let getData = async () => {
    let projectId = JSON.parse(window.sessionStorage.getItem("ProjectData")).projectId
    let response = await GetProject(projectId)
    let responseProjectAllData = await GetLandDetailsByProjectId(projectId)
    if (response.status === 1 && responseProjectAllData.status === 1) {
      setProjectName(response.responseValue[0].name)
      let oldData = JSON.parse(window.sessionStorage.getItem("ProjectData"))

      window.sessionStorage.setItem("ProjectData", JSON.stringify({ ...oldData, projectName: response.responseValue[0].name, landmark: response.responseValue[0].landmark }))
      setProjectData({...response.responseValue[0], LandData: responseProjectAllData.responseValue})
    }
    else {
      setProjectData([])
    }
  }
  useEffect(() => {
    getData()
  }, [])
  return (
    <div className='row pb-3'>
      <div className='col col-md-8'>
        <div className='d-flex flex-column gap-3'>
          <ProjectSummery projectData={projectData} />
          <ProjectDetails setLandId={setLandId} projectDataOut={projectData} setProjectNameGlobal={setProjectName}/>
        </div>
      </div>
      <div className='col col-md-4'>
        <div className='d-flex flex-column gap-3' >
          <ProjectOver projectData={projectData} />
          <ProjectExpenses projectData={projectData} />
          {/* <RecentTransaction projectData={projectData}/> */}
          <InvestorDetails projectData={projectData} landId={landId} projectName={projectName}/>
          <LandOwners  projectData={projectData} landId={landId} projectName={projectName}/>
        </div>
      </div>
    </div>
  )
}
