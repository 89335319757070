export default async function InsertVendorTerm(sendForm) {
    let url = window.RealestateService + `/api/Vendor/InsertVendorTerm`
    let head = {
        'Content-Type': 'application/json',
        "accept": "*/*"
    }
    let data = {}
    let response = await fetch(url, {
        method: "POST",
        headers: head,
        body: JSON.stringify(sendForm)
    }).then(res => res.json()).then(data)

    return response
}