import React, { useEffect, useState } from 'react'
import FileUpload from '../../components/FileUpload'
import OwnerShow from './ShowLand/OwnerShow'
import GetUnitMaster from '../../APIs/Master/UnitMaster/GetUnitMaster'
import GetStates from '../../APIs/Master/StateMaster/GetStates'
import GetCitiesByStateId from '../../APIs/Master/CityMaster/GetCitiesByStateId'
import GetAllGender from '../../APIs/Master/Gender/GetAllGender'
import GetIdentityDocumentTypeList from '../../APIs/Master/IdentityDocumentTypeMaster/GetIdentityDocumentTypeList'
import InsertLandOwner from '../../APIs/Owner/InsertLandOwner'
import Loader from '../../components/Loader'
import SideMessage from '../../components/SideMessage'
import { Link, useNavigate } from 'react-router-dom'
import InsertLandOwnerWithIdentityFiles from '../../APIs/Owner/InsertLandOwnerWithIdentityFiles'
import InputBoxNumber from '../../components/InputBoxNumber'
import InputBoxAlphaNumarics from '../../components/InputBoxAlphaNumarics'
import UpdateLandOwner from '../../APIs/Owner/UpdateLandOwner'
import GetProject from '../../APIs/Project/GetProjectById'
import GetLandDetailsByProjectId from '../../APIs/Land/GetLandDetailsByProjectId'

export default function AddOwner(props) {
  let [allImage, setAllImage] = useState([])
  let [loader, setLoader] = useState(0)
  let [getOwner, setGetOwner] = useState(0)
  let [noOfOwnerSaved, setNoOfOwnerSaved] = useState(0)
  let [showPopup, setSHowPopup] = useState(0)
  let [rawAddress, setRawAddress] = useState([])
  let [totalPercentage, setTotalPercentage] = useState(0)
  let [subtractPercentage, setSubtractPercentage] = useState(0)
  let [isUpdate, setIsUpdate] = useState(0)
  let navigate = useNavigate()

  let [sendForm, setSendForm] = useState(
    {
      "landId": JSON.parse(window.sessionStorage.getItem("ProjectData")).landData ? JSON.parse(window.sessionStorage.getItem("ProjectData")).landData.id : "",
      "name": "",
      "age": 0,
      "genderId": "-1",
      "mobileNo": "",
      "cityId": "-1",
      "stateId": "-1",
      "countryId": 0,
      "pincode": "",
      "address": rawAddress,
      "landmark": "",
      "identityTypeId": "-1",
      "identityNumber": "",
      "percentage": "",

    }
  )
  let clearForm = () => {
    setRawAddress([])
    setSendForm({
      "landId": JSON.parse(window.sessionStorage.getItem("ProjectData")).landData ? JSON.parse(window.sessionStorage.getItem("ProjectData")).landData.id : "",
      "name": "",
      "age": 0,
      "genderId": "-1",
      "mobileNo": "",
      "cityId": "-1",
      "stateId": "-1",
      "countryId": 0,
      "pincode": "",
      "address": "",
      "landmark": "",
      "identityTypeId": "-1",
      "identityNumber": "",
      "percentage": "",

    })
    document.getElementsByName("address").value = ""
    setAllImage([])
  }
  let [sideToster, setSideToster] = useState({
    show: 0,
    message: '',
    type: ''
  })
  let [genderList, setGenderList] = useState([])
  let [stateList, setStateList] = useState([])
  let [cityList, setCityList] = useState([])
  let [identityTypeList, setIdentityTypeList] = useState([])
  let [deletedImages, setDeletedImages] = useState([])

  let getData = async () => {
    let respGender = await GetAllGender()
    let respState = await GetStates()
    let respIdentityType = await GetIdentityDocumentTypeList()
    if (respGender.status === 1) {
      setGenderList(respGender.responseValue)
    }
    if (respState.status === 1) {
      setStateList(respState.responseValue)
    }
    if (respIdentityType.status === 1) {
      setIdentityTypeList(respIdentityType.responseValue)
    }

  }
  let getCity = async (id) => {
    let respCity = await GetCitiesByStateId(id);
    if (respCity.status === 1) {

      setCityList(respCity.responseValue)
    }
  }

  let handleChnage = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "stateId") {
      var dropdown = document.getElementsByName(name)[0];
      getCity(value)
      setRawAddress([rawAddress[0], dropdown.options[dropdown.selectedIndex].text, rawAddress[2]])
      setSendForm({ ...sendForm, [name]: value, ["address"]: [rawAddress[0], dropdown.options[dropdown.selectedIndex].text, rawAddress[2]].join(",") })

    }
    else if (name === "cityId") {
      var dropdown = document.getElementsByName(name)[0];
      setRawAddress([rawAddress[0], rawAddress[1], dropdown.options[dropdown.selectedIndex].text, rawAddress[3], rawAddress[4]])
      setSendForm({ ...sendForm, [name]: value, ["address"]: [dropdown.options[dropdown.selectedIndex].text, rawAddress[1], rawAddress[2]].join(",") })

    }
    else if (name === "pincode") {
      setRawAddress([rawAddress[0], rawAddress[1], rawAddress[2], rawAddress[3], value])
      setSendForm({ ...sendForm, [name]: value, ["address"]: [rawAddress[0], rawAddress[1], value].join(",") })

    }
    else if (name === "mobileNo") {

      if (value.toString().length <= 10) {
        setSendForm({ ...sendForm, [name]: value })
      }
      else {
        setSideToster({
          show: 1,
          message: 'Mobile Number Have Only 10-digits',
          type: 'Alert'
        })
      }
    }
    else {
      setSendForm({ ...sendForm, [name]: value })
    }
  }

  let handleVerification = () => {
    // console.log("sendForm", sendForm.address.length)
    // console.log("test",sendForm.percentage.toString().trim().length !== 0 , sendForm.name.trim() !== '' , sendForm.age !== 0 , sendForm.genderId.toString() !== "-1" , sendForm.cityId.toString() !== "-1" , sendForm.stateId.toString() !== "-1" , (sendForm.pincode.toString().trim().length === 6) , sendForm.address.length !== 0 , sendForm.landmark.trim() !== '' , sendForm.identityTypeId.toString() !== "-1" , sendForm.identityNumber.trim() !== '' , sendForm.percentage !== 0 , ((totalPercentage + parseInt(sendForm.percentage) - parseInt(subtractPercentage)) <= 100) , sendForm.mobileNo.trim() !== '')
    if (sendForm.percentage.toString().trim().length !== 0 && sendForm.name.trim() !== '' && sendForm.age !== 0 && sendForm.genderId.toString() !== "-1" && sendForm.cityId.toString() !== "-1" && sendForm.stateId.toString() !== "-1" && (sendForm.pincode.toString().trim().length === 6) && sendForm.address.length !== 0 && sendForm.landmark.trim() !== '' && sendForm.identityTypeId.toString() !== "-1" && sendForm.identityNumber.trim() !== '' && sendForm.percentage !== 0 && ((totalPercentage + parseInt(sendForm.percentage) - parseInt(subtractPercentage)) <= 100) && sendForm.mobileNo.trim() !== '') {
      return [true, '']
    }
    else if (sendForm.percentage.toString().trim().length === 0) {
      return [false, 'Please Enter Owner Percentage']

    }
    else if (sendForm.name.trim() === '') {
      return [false, 'Please Enter Owner Name']

    }
    else if (sendForm.age === 0) {
      return [false, 'Please Enter Age']

    }
    else if (sendForm.genderId.toString() === "-1") {
      return [false, 'Please Select Gender']
    }
    else if (sendForm.mobileNo.trim() === '') {
      return [false, 'Please Enter Mobile No.']
    }
    else if (sendForm.cityId.toString() === "-1") {
      return [false, 'Please Select City']

    }
    else if (sendForm.stateId.toString() === "-1") {
      return [false, 'Please Select State']

    }
    else if (sendForm.pincode.toString().trim().length < 6) {
      if(sendForm.pincode.trim() === '')
        {
          return [false, 'Please Enter Pin Code']
        }
        else{
          return [false, 'Please Enter 6 Digits Pin Code']

        }

    }
    else if (sendForm.landmark.trim() === '') {
      return [false, 'Please Enter landmark']

    }
    else if (sendForm.identityTypeId.toString() === "-1") {
      return [false, 'Please Enter Identity Type']

    }
    else if (sendForm.address.trim() === '') {
      return [false, 'Please Enter Address']

    }
    else if (sendForm.landmark.trim() === '') {
      return [false, 'Please Enter Landmark']

    }
    else if (sendForm.percentage.trim() === '') {
      return [false, 'Please Enter Percentage']

    }
    else if ((totalPercentage + parseInt(sendForm.percentage)) > 100) {
      return [false, 'Total Percentage Not Above The 100']

    }
    else if (sendForm.identityNumber.trim() === '') {
      return [false, 'Please Enter Identity Number']

    }
    // else{
    //   console.log("csd", sendForm.percentage.toString().trim().length !== 0 , sendForm.name.trim() !== '' , sendForm.age !== 0 , sendForm.genderId !== 0 , sendForm.cityId !== 0 , sendForm.stateId !== 0 , sendForm.pincode.trim() !== '',sendForm.address.trim() !== '' , sendForm.landmark.trim() !== '' , sendForm.identityTypeId !== 0 && sendForm.identityNumber.trim() !== '' , sendForm.percentage !== 0 , ((totalPercentage + sendForm.percentage) <= 100)  , sendForm.mobileNo.trim() !== '')
    //   return [false,"nksndcsdcmad"]
    // }
  }

  let handleSave = async () => {
    console.log("sendform", noOfOwnerSaved, JSON.parse(window.sessionStorage.getItem("ProjectData")).landData.noOfOwners)
    // props.handleActiveTab(4)
    console.log("dnknjsckjbds", Number(noOfOwnerSaved) !== Number(JSON.parse(window.sessionStorage.getItem("ProjectData")).landData.noOfOwners))
    if (Number(noOfOwnerSaved) !== Number(JSON.parse(window.sessionStorage.getItem("ProjectData")).landData.noOfOwners)) {

      let validate = handleVerification()

      if (validate[0]) {
        setLoader(1)

        let formData = new FormData()
        Object.keys(sendForm).map((name) => {
          formData.append(name, sendForm[name]);
        })
        allImage.map((val, ind) => {
          formData.append("landFiles", val);
        })

        // let resp = await InsertLandOwner(sendForm)
        let resp = await InsertLandOwnerWithIdentityFiles(formData)


        if (resp.status === 1) {
          setLoader(0)
          clearForm()
          setNoOfOwnerSaved(noOfOwnerSaved + 1)
          setGetOwner(noOfOwnerSaved + 1)
          // setTotalPercentage(totalPercentage + sendForm.percentage)
          if ((noOfOwnerSaved + 1) === Number(JSON.parse(window.sessionStorage.getItem("ProjectData")).landData.noOfOwners)) {
            setSHowPopup(1)
          }
          // props.handleActiveTab(3)
        }
        else {
          setSideToster({
            show: 1,
            message: resp.responseValue,
            type: 'Alert'
          })
          setLoader(0)
        }
      }
      else {
        setSideToster({
          show: 1,
          message: validate[1],
          type: 'Alert'
        })
        setLoader(0)

      }
    }
    else {

      setSHowPopup(1)

    }


  }
  let handleUpdate = async () => {
    // if (Number(noOfOwnerSaved) !== Number(JSON.parse(window.sessionStorage.getItem("ProjectData")).landData.noOfOwners)) {
    let validate = handleVerification()
    if (validate[0]) {
      setLoader(1)

      let formData = new FormData()
      Object.keys(sendForm).map((name) => {
        formData.append(name, sendForm[name]);
      })
      allImage.map((val, ind) => {
        formData.append("landFiles", val);
      })
      if (deletedImages.length !== 0) {
        deletedImages.map((val, ind) => {
          formData.append("landOwnerIds", val);
        })
      }

      // console.log("delete Id", deletedImages)
      else {
        formData.append("landOwnerIds", [0]);

      }

      // let resp = await InsertLandOwner(sendForm)
      let resp = await UpdateLandOwner(formData)


      if (resp.status === 1) {
        setLoader(0)
        clearForm()
        setGetOwner(Math.random())

        // setNoOfOwnerSaved(noOfOwnerSaved + 1)
        // setGetOwner(noOfOwnerSaved + 1)
        // setTotalPercentage(totalPercentage + sendForm.percentage)
        // if ((noOfOwnerSaved + 1) === Number(JSON.parse(window.sessionStorage.getItem("ProjectData")).landData.noOfOwners)) {
        //   setSHowPopup(1)
        // }
        setIsUpdate(0)
        setSideToster({
          show: 1,
          message: "Data Updated!!",
          type: 'Success'
        })
        navigate("/projectdashboard/")
        // props.handleActiveTab(3)

      }
      else {
        setSideToster({
          show: 1,
          message: resp.responseValue,
          type: 'Alert'
        })
        setLoader(0)
      }
    }
    else {
      setSideToster({
        show: 1,
        message: validate[1],
        type: 'Alert'
      })
      setLoader(0)

    }
    // }
    // else {
    //   setSHowPopup(1)
    // }

  }

  let handleDashboard = async () => {

    window.sessionStorage.setItem("ProjectData", JSON.stringify({ "projectData": [props.projectData.name, props.projectData.projectNo, props.projectData.id, props.projectData.projectPrice, props.projectData.projectArea, props.projectData.landmark], }))
    navigate("/projectdashboard/")

  }
  let handleEdit = (data) => {
    getCity(data.stateId)
    setSendForm(data)
    setSubtractPercentage(data.percentage)
    setIsUpdate(1)
  }

  let handleGotDashboard = async() => {
    let projectId = JSON.parse(window.sessionStorage.getItem("ProjectData")).projectId
    let response = await GetProject(projectId)
    let resp = await GetLandDetailsByProjectId(projectId);
    if (response.status === 1) {
      let data = JSON.parse(window.sessionStorage.getItem("ProjectData"))
      window.sessionStorage.setItem("ProjectData", JSON.stringify({allLandData:resp.responseValue, projectData:response.responseValue[0], projectId:projectId}))
      // setProjectData(response.responseValue[0])
      navigate("/projectdashboard/")
    }
   
  }
  useEffect(() => {
    let data = JSON.parse(window.sessionStorage.getItem("ProjectData")).landData
    // setNoOfOwnerSaved(0)
    getData()
  }, [])
  return (
    <>
      <div className='display-container'>
        <OwnerShow handleEdit={handleEdit} change={getOwner} setNoOfOwnerSaved={setNoOfOwnerSaved} setTotalPercentage={setTotalPercentage} />
      </div>
      <div className='container-fluid landformconatiner p-4' id="conatinerId">
        <div className='wrapform'>
          <div className='d-flex flex-column gap-1'>
            <label className='form-label'>Land Percentage (%)</label>
            {/* <input type='number' className='form-input-box' value={sendForm.percentage} placeholder='Enter Land Percentage' name='percentage' onChange={handleChnage} /> */}
            <InputBoxNumber name="percentage" value={sendForm.percentage} placeholder='Enter  Land Percentage' setSendForm={setSendForm} range={3} min={1} max={100} />

          </div>
          <div className='d-flex flex-column gap-1'>
            <label className='form-label'>Owner Name</label>
            <input type='text' className='form-input-box' value={sendForm.name} placeholder='Enter Owner Name' name='name' onChange={handleChnage} />
          </div>
          <div className='d-flex flex-column gap-1'>
            <label className='form-label'>Owner Age</label>
            {/* <input type='number' className='form-input-box' value={sendForm.age} placeholder='Enter Owner Age' name='age' onChange={handleChnage} /> */}
            <InputBoxNumber name="age" value={sendForm.age} placeholder='Enter  Owner Age' setSendForm={setSendForm} range={3} min={1} max={100} />

          </div>
          {/* <div className='row pb-4'>
            <div className='col col-md-4 pb-2 pb-md-0'>
              
            </div>
            <div className='col col-md-4 pb-2 pb-md-0'>
              
            </div>
            <div className='col col-md-4 pb-2 pb-md-0'>
             
            </div>
          </div> */}

          <div className='d-flex flex-column gap-1'>
            <label className='form-label'>Owner Gender</label>
            <select className='form-input-box form-select pe-5' value={sendForm.genderId} name='genderId' onChange={handleChnage}>
              <option value="-1">--Select Gender--</option>
              {
                genderList && genderList.map((val, ind) => {
                  return (
                    <option value={val.id}>
                      {val.name}
                    </option>
                  )
                })
              }
            </select>
          </div>

          <div className='d-flex flex-column gap-1'>
            <label className='form-label'>Owner Mobile No.</label>
            {/* <input type='number' className='form-input-box' pattern="[0-9]{10}" value={sendForm.mobileNo} placeholder='Enter Mobile No.' name='mobileNo' onChange={handleChnage} /> */}
            <InputBoxNumber name="mobileNo" value={sendForm.mobileNo} placeholder='Enter Mobile No.' setSendForm={setSendForm} range={10} />

          </div>
          <div className='d-flex flex-column gap-1'>
            <label className='form-label'>Select State</label>
            <select className='form-input-box form-select pe-5' name='stateId' value={sendForm.stateId} onChange={handleChnage}>
              <option value="-1">--Select State--</option>
              {
                stateList && stateList.map((val, ind) => {
                  return (
                    <option value={val.id}>
                      {val.stateName}
                    </option>
                  )
                })
              }
            </select>
          </div>

          {/* <div className='row pb-4'>
            <div className='col col-md-4 pb-2 pb-md-0'>
             
            </div>
            <div className='col col-md-4 pb-2 pb-md-0'>
              
            </div>

            <div className='col col-md-4 pb-2 pb-md-0'>
              
            </div>
          </div> */}

          <div className='d-flex flex-column gap-1'>
            <label className='form-label'>Select City</label>
            <select className='form-input-box form-select pe-5' name='cityId' value={sendForm.cityId} onChange={handleChnage}>
              <option value="-1">--Select City--</option>
              {
                cityList && cityList.map((val, ind) => {
                  return (
                    <option value={val.id}>
                      {val.cityName}
                    </option>
                  )
                })
              }
            </select>
          </div>

          <div className='d-flex flex-column gap-1'>
            <label className='form-label'>Pin Code</label>
            {/* <input type='number' className='form-input-box' value={sendForm.pincode} placeholder='Enter Pin Code' name='pincode' onChange={handleChnage} /> */}
            <InputBoxNumber name="pincode" value={sendForm.pincode} placeholder='Enter Pin Code' setSendForm={setSendForm} range={6} />

          </div>
          <div className='d-flex flex-column gap-1'>
            <label className='form-label'>Land Mark</label>
            <input type='text' className='form-input-box' value={sendForm.landmark} placeholder='Enter Land Mark' name='landmark' onChange={handleChnage} />
          </div>
          {/* <div className='row pb-4'>
            <div className='col col-md-4 pb-2 pb-md-0'>
              
            </div>
            <div className='col col-md-4 pb-2 pb-md-0'>
             
            </div>
            <div className='col col-md-4 pb-2 pb-md-0'>
              
            </div>
          </div> */}



          <div className='d-flex flex-column gap-1'>
            <label className='form-label'>Select Identity Type</label>
            <select className='form-input-box form-select pe-5' name='identityTypeId' value={sendForm.identityTypeId} onChange={handleChnage}>
              <option value="-1">--Select Identity Type--</option>
              {
                identityTypeList && identityTypeList.map((val, ind) => {
                  return (
                    <option value={val.id}>
                      {val.documentType}
                    </option>
                  )
                })
              }
            </select>
          </div>
          <div className='d-flex flex-column gap-1'>
            <label className='form-label'>Owner Identity No.</label>
            {/* <input type='number' className='form-input-box' placeholder='Enter Identity No.' value={sendForm.identityNumber} name='identityNumber' onChange={handleChnage} /> */}
            <InputBoxAlphaNumarics name="identityNumber" value={sendForm.identityNumber} placeholder='Enter Identity No.' setSendForm={setSendForm} range={15} />
          </div>
          {/* <div className='row pb-4'>
            <div className='col col-md-4 pb-2 pb-md-0'>
              
            </div>
            <div className='col col-md-4 pb-2 pb-md-0'>
              
            </div>
            <div className='col col-md-4 pb-2 pb-md-0'>
              
            </div>

          </div> */}
        </div>

        <div className='row mt-3'>
          <div className='d-flex flex-column gap-1'>
            <label className='form-label'>Address</label>
            <textarea className='form-input-box' placeholder='Enter Address' value={sendForm.address} name='address' onChange={handleChnage}></textarea>
            {/* <input type='text' className='form-input-box' /> */}
          </div>
        </div>
        <FileUpload setDeletedImages={setDeletedImages} deletedImages={deletedImages} setAllImage={setAllImage} allImage={allImage} name="Identity Documents" />
      </div>
      <div className='d-flex flex-row justify-content-md-end'>

        {
          isUpdate === 0 ?
            <button className='btn export-save-btn' onClick={handleSave}>Save Owner</button> :

            <button className='btn export-save-btn' onClick={handleUpdate}>Upadte Owner</button>
        }
      </div>

      <Loader show={loader} />
      {
        sideToster.show === 1 ?
          <SideMessage close={setSideToster} show={sideToster.show} type={sideToster.type} message={sideToster.message} />
          : ""
      }

      {/* <!-- Modal --> */}
      <div class={`modal  d-${showPopup === 1 ? "block" : "none"} `} id="exampleModal" tabindex="-1" >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">Owner Alert</h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setSHowPopup(0) }}></button>
            </div>
            <div class="modal-body">
              If you want to add more onwers please increase number of owners in land information.
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" onClick={() => { props.handleActiveTab(0) }}>Go To Land Details</button>

              <button onClick={handleGotDashboard}  type="button" class="btn btn-secondary" >Go to Project Summary</button>
            </div>
          </div>
        </div>
      </div>
    </>

  )
}
