export default async function GetInvestorAllTransactionData(id) {
    // let url = window.RealestateService+  `/api/Investor/GetInvestorDetailsById?id=${id}`
    let url = window.RealestateService+  `/api/PaymentDetails/GetInvestorAllTransactionData?investorId=${id}`
    let head = {
         "content-type":"application/json",
        "accept":"*/*"
    }
    let response = await fetch(url, {
        method:"GET",
        headers:head,
    }).then(res=>res.json()).catch(res=>res)

    return response
}