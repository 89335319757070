import React, { useEffect, useState } from 'react'
import GetIdentityDocumentTypeList from '../../../APIs/Master/IdentityDocumentTypeMaster/GetIdentityDocumentTypeList'
import GetStates from '../../../APIs/Master/StateMaster/GetStates'
import GetLandDocumentTypeList from '../../../APIs/Master/LandDocumentTypeMaster/GetLandDocumentTypeList'
import GetCitiesByStateId from '../../../APIs/Master/CityMaster/GetCitiesByStateId'
import PageHeading from '../../../components/PageHeading'
import FileUpload from '../../../components/FileUpload'
import InsertInvestorDetails from '../../../APIs/AddInvestor/InsertInvestorDetails'
import Validation from '../../../Code/Validation'
import GetBankMaster from '../../../APIs/Bank/GetBankMaster'
import Loader from '../../../components/Loader'
import SideMessage from '../../../components/SideMessage'
import { useNavigate, useParams } from 'react-router-dom'
import GetInvestorDetailsById from '../../../APIs/AddInvestor/GetInvestorDetailsById'
import UpdateInvestorDetails from '../../../APIs/AddInvestor/UpdateInvestorDetails'
import InsertInvestorBankDocuments from '../../../APIs/AddBankImages/InsertInvestorBankDocuments'
import InputBoxNumber from '../../../components/InputBoxNumber'
import InputBoxAlphaNumarics from '../../../components/InputBoxAlphaNumarics'

export default function AddCompanyDetails() {


    let [sendForm, setSendForm] = useState({
        "investorType": -1,
        "name": "",
        "emailId": "",
        "mobileNo": "",
        "pincode": "",
        "address": "",
        "stateId": 0,
        "cityId": 0,
        "investorBankDetails": {
            "bankId": 0,
            "investorId": -1,
            "accountHolderName": "",
            "branchCode": "",
            "accountNo": "",
            "ifscCode": "",
            "userId": window.userId,

        },
        "userId": window.userId,
    })
    let [sideToster, setSideToster] = useState({
        show: 0,
        message: '',
        type: ''
    })
    let [stateList, setStateList] = useState([])
    let [cityList, setCityList] = useState([])
    let [bankList, setBankList] = useState([])
    let [identityTypeList, setIdentityTypeList] = useState([])
    let [allImage, setAllImage] = useState([])
    let [landDocumentTypeList, setLandDocumentTypeList] = useState([])
    let [documentTypeId, setDocumentTypeId] = useState(0)
    let [loader, setLoader] = useState(1)
    let [isUpdate, setIsUpdate] = useState(0)
    let navigate = useNavigate()


    let clear = () => {
        setSendForm({
            "investorType": -1,
            "name": "",
            "emailId": "",
            "mobileNo": "",
            "pincode": "",
            "address": "",
            "stateId": 0,
            "cityId": 0,
            "investorBankDetails": {
                "bankId": 0,
                "investorId": -1,
                "accountHolderName": "",
                "branchCode": "",
                "accountNo": "",
                "ifscCode": "",
                "userId": window.userId,

            },
            "userId": window.userId,
        })
    }

    let getData = async () => {
        let respState = await GetStates()
        let respIdentityType = await GetIdentityDocumentTypeList()
        let respLandProof = await GetLandDocumentTypeList()
        let respBank = await GetBankMaster()

        if (respState.status === 1) {
            setStateList(respState.responseValue)
        }
        if (respIdentityType.status === 1) {
            setIdentityTypeList(respIdentityType.responseValue)
        }
        if (respLandProof.status === 1) {
            setLandDocumentTypeList(respLandProof.responseValue)
        }
        if (respBank.status === 1) {
            setBankList(respBank.responseValue)
            setLoader(0)
        }

    }

    let getCity = async (id) => {
        let respCity = await GetCitiesByStateId(id);
        if (respCity.status === 1) {

            setCityList(respCity.responseValue)
        }
    }

    let handleChnage = (e) => {
        let name = e.target.name
        let value = e.target.value
        if (name === "stateId") {
            getCity(value)
            setSendForm({ ...sendForm, [name]: value })

        }
        else if (name === "bankId") {

            let temp = { ...sendForm }
            temp["investorBankDetails"][name] = value
            setSendForm({ ...sendForm })


        }
        else if (name === "investorId") {

            let temp = { ...sendForm }
            temp["investorBankDetails"][name] = value
            setSendForm({ ...sendForm })


        }

        else if (name === "accountHolderName") {

            let temp = { ...sendForm }
            temp["investorBankDetails"][name] = value
            setSendForm({ ...sendForm })

        }
        else if (name === "branchCode") {

            let temp = { ...sendForm }
            temp["investorBankDetails"][name] = value
            setSendForm({ ...sendForm })

        }
        else if (name === "accountNo") {

            let temp = { ...sendForm }
            temp["investorBankDetails"][name] = value
            setSendForm({ ...sendForm })

        }
        else if (name === "ifscCode") {

            let temp = { ...sendForm }
            temp["investorBankDetails"][name] = value
            setSendForm({ ...sendForm })

        }
        else {

            setSendForm({ ...sendForm, [name]: value })
        }
    }

    let handleSave = async () => {
        let resp = validation()
        if (resp[0]) {
            setLoader(1)
            sendForm["investorType"] = 1
            let resp = await InsertInvestorDetails(sendForm)
            if (resp.status === 1) {
                let id = resp.responseValue.investorBankDetails.id
                let formData = new FormData()
                formData.append("InvestorBankId", id)
                formData.append("DocumentType", documentTypeId)
                formData.append("UserId", window.userId)
                allImage.map((val, ind) => {
                    formData.append("formFiles", val);
                })

                let response = await InsertInvestorBankDocuments(formData)
                if (response.status === 1) {
                    setLoader(0)
                    setSideToster({
                        show: 1,
                        message: 'Data Save',
                        type: 'Success'
                    })
                    clear()
                    navigate("/individualinvestor/")

                }
                else {
                    setLoader(0)
                    setSideToster({
                        show: 1,
                        message: resp.responseValue,
                        type: 'Alert'
                    })
                }

            }
            else {
                setLoader(0)
                setSideToster({
                    show: 1,
                    message: resp.responseValue,
                    type: 'Alert'
                })
            }

        }
        else {

            setSideToster({
                show: 1,
                message: resp[1],
                type: "Alert"
            })

        }
        // console.log("check ", resp)
    }

    let validation = () => {
        if (sendForm.name.trim() !== "" && sendForm.emailId.trim() !== "" && sendForm.mobileNo.trim().length === 10 && sendForm.pincode.trim().length === 6 && sendForm.address.trim() !== "" && sendForm.stateId.toString().trim() !== "-1" && sendForm.cityId.toString().trim() !== "-1" && sendForm.investorBankDetails.bankId.toString().trim() !== "-1" && sendForm.investorBankDetails.accountHolderName.trim() !== "" && sendForm.investorBankDetails.branchCode.trim() !== "" && sendForm.investorBankDetails.accountNo.trim() !== "" && sendForm.investorBankDetails.ifscCode.trim() !== "") {
            return [true, ""]
        }
        else if (sendForm.name.trim() === "") {
            return [false, "Please Enter Investor Name"]
        }
        else if (sendForm.emailId.trim() === "") {
            return [false, "Please Enter Investor Email Id"]
        }
        else if (sendForm.mobileNo.trim() !== 10) {
            return [false, "Please Enter Investor Mobile No."]
        }
        else if (sendForm.pincode.trim() !== 6) {
            return [false, "Please Enter Pincode"]
        }
        else if (sendForm.address.trim() === "") {
            return [false, "Please Enter Investor Address"]
        }
        else if (sendForm.stateId.toString().trim() === "-1") {
            return [false, "Please Select State"]
        }
        else if (sendForm.cityId.toString().trim() === "-1") {
            return [false, "Please Select City"]
        }
        else if (sendForm.investorBankDetails.bankId.toString().trim() === "-1") {
            return [false, "Please Select Bank"]
        }
        else if (sendForm.investorBankDetails.accountHolderName.trim() === "") {
            return [false, "Please Enter Account Holder Name"]
        }
        else if (sendForm.investorBankDetails.branchCode.trim() === "") {
            return [false, "Please Enter Bank Branch Code"]
        }
        else if (sendForm.investorBankDetails.accountNo.trim() === "") {
            return [false, "Please Enter Investor Account No"]
        }
        else if (sendForm.investorBankDetails.ifscCode.trim() === "") {
            return [false, "Please Enter Investor IFSC Code"]
        }
        else {
            return [false, "Somthing Wrong!!"]
        }
    }

    let handleUpdate = async () => {
        let resp = validation()
        if (resp[0]) {
            setLoader(1)
            sendForm["investorType"] = 1
            let resp = await UpdateInvestorDetails(sendForm)
            if (resp.status === 1) {
                setLoader(0)
                setSideToster({
                    show: 1,
                    message: 'Data Save',
                    type: 'Success'
                })
                clear()
                setIsUpdate(0)
                navigate("/individualinvestor/")
            }
            else {
                setLoader(0)
                setSideToster({
                    show: 1,
                    message: resp.responseValue,
                    type: 'Alert'
                })
            }
        }
        else {
            setSideToster({
                show: 1,
                message: resp[1],
                type: "Alert"
            })
        }
        // console.log("check ", resp)
    }

    let getInvestordata = async (id) => {

        let resp = await GetInvestorDetailsById(id)
        if (resp.status === 1) {
            let temp = resp.responseValue[0]
            let banck = { ...temp.bankDetails }
            delete temp.bankDetails
            let images = temp.documents
            delete temp.documents

            let investor = {
                ...temp
            }
            setSendForm({ ...investor, ["investorBankDetails"]: banck })
            setAllImage(images)
            console.log("data", images)
        }
    }

    let id = useParams()

    useEffect(() => {
        if (id.id !== "null") {
            getInvestordata(id.id);
            setIsUpdate(1)
        }
        getData();
    }, [])

    return (
        <>
            <div className='d-flex flex-column flex-md-row justify-content-between pe-3 ps-3'>
                <PageHeading pagename={"Add Company Investor"} />
            </div>


            <div className='container-fluid landformconatiner p-4' id="conatinerId">
                <PageHeading pagename={"Company Details"} />
                <div className='wrapform pt-3'>
                    <div className='d-flex flex-column gap-1'>
                        <label className='form-label'>Company Name</label>
                        <input type='text' className='form-input-box' value={sendForm.name} placeholder='Enter Company Name' name='name' onChange={handleChnage} />
                    </div>
                    <div className='d-flex flex-column gap-1'>
                        <label className='form-label'>Email Address</label>
                        <input type='email' className='form-input-box' value={sendForm.emailId} placeholder='Enter Email Address' name='emailId' onChange={handleChnage} />
                    </div>
                    {/* <div className='d-flex flex-column gap-1'>
                        <label className='form-label'>Invested in Any Property</label>
                        <select className='form-input-box form-select pe-5' name='propteryId' value={sendForm.propteryId} onChange={handleChnage}>
                            <option value="-1">--select Property--</option>
                            {
                                stateList && stateList.map((val, ind) => {
                                    return (
                                        <option value={val.id}>
                                            {val.stateName}
                                        </option>
                                    )
                                })
                            }
                        </select>
                    </div> */}
                    <div className='d-flex flex-column gap-1'>
                        <label className='form-label'>Mobile No.</label>
                        {/* <input type='number' className='form-input-box' value={sendForm.mobileNo} placeholder='Enter Mobile No.' name='mobileNo' onChange={handleChnage} /> */}
                        <InputBoxNumber value={sendForm.mobileNo} placeholder='Enter Mobile No.' name='mobileNo' setSendForm={setSendForm} range={10} />

                    </div>



                    <div className='d-flex flex-column gap-1'>
                        <label className='form-label'>Select State</label>
                        <select className='form-input-box form-select pe-5' name='stateId' value={sendForm.stateId} onChange={handleChnage}>
                            <option value="-1">--Select State--</option>
                            {
                                stateList && stateList.map((val, ind) => {
                                    return (
                                        <option value={val.id}>
                                            {val.stateName}
                                        </option>
                                    )
                                })
                            }
                        </select>
                    </div>



                    <div className='d-flex flex-column gap-1'>
                        <label className='form-label'>Select City</label>
                        <select className='form-input-box form-select pe-5' name='cityId' value={sendForm.cityId} onChange={handleChnage}>
                            <option value="-1">--Select City--</option>
                            {
                                cityList && cityList.map((val, ind) => {
                                    return (
                                        <option value={val.id}>
                                            {val.cityName}
                                        </option>
                                    )
                                })
                            }
                        </select>
                    </div>

                    <div className='d-flex flex-column gap-1'>
                        <label className='form-label'>Pin Code</label>
                        {/* <input type='number' className='form-input-box' value={sendForm.pincode} placeholder='Enter Pin Code' name='pincode' onChange={handleChnage} /> */}
                        <InputBoxNumber value={sendForm.pincode} placeholder='Enter Pin Code' name='pincode' setSendForm={setSendForm} range={10} />

                    </div>
                    <div className='d-flex flex-column gap-1'>
                        <label className='form-label'>Address</label>
                        <input type='text' className='form-input-box' value={sendForm.address} placeholder='Enter Address' name='address' onChange={handleChnage} />
                    </div>


                    {/* <div className='d-flex flex-column gap-1'>
                        <label className='form-label'>Unique Id if you have</label>
                        <input type='number' className='form-input-box' value={sendForm.mobileNo} placeholder='Enter Unique Id' name='mobileNo' onChange={handleChnage} />
                    </div> */}



                </div>
            </div>

            <div className='container-fluid landformconatiner p-4 mt-3' id="conatinerId">
                <PageHeading pagename={"Bank Details"} />
                <div className='wrapform pt-3'>
                    <div className='d-flex flex-column gap-1'>
                        <label className='form-label'>Select Bank</label>
                        <select className='form-input-box form-select pe-5' name='bankId' value={sendForm.investorBankDetails.bankId} onChange={handleChnage}>
                            <option value="-1">--Select Bank--</option>
                            {
                                bankList && bankList.map((val, ind) => {
                                    return (
                                        <option value={val.id}>
                                            {val.name}
                                        </option>
                                    )
                                })
                            }
                        </select>
                    </div>

                    <div className='d-flex flex-column gap-1'>
                        <label className='form-label'>Account Holder's Name</label>
                        <input type='text' className='form-input-box' value={sendForm.investorBankDetails.accountHolderName} placeholder='Enter Account Holder Name' name='accountHolderName' onChange={handleChnage} />

                    </div>


                    <div className='d-flex flex-column gap-1'>
                        <label className='form-label'>Branch Code</label>
                        <input type='text' className='form-input-box' value={sendForm.investorBankDetails.branchCode} placeholder='Enter Branch Code' name='branchCode' onChange={handleChnage} />
                        {/* <InputBoxAlphaNumarics value={sendForm.investorBankDetails.branchCode} placeholder='Enter Branch Code' name='branchCode' setSendForm={setSendForm} range={15} /> */}

                    </div>
                    <div className='d-flex flex-column gap-1 '>
                        <label className='form-label'>Bank Account No</label>
                        <input type='text' className='form-input-box' value={sendForm.investorBankDetails.accountNo} placeholder='Enter Bank Account No' name='accountNo' onChange={handleChnage} />
                        {/* <InputBoxAlphaNumarics value={sendForm.investorBankDetails.accountNo} placeholder='Enter Bank Account No' name='accountNo'  setSendForm={setSendForm} range={15} /> */}
                    
                    </div>


                    <div className='d-flex flex-column gap-1'>
                        <label className='form-label'>IFSC Code</label>
                        <input type='text' className='form-input-box' value={sendForm.investorBankDetails.ifscCode} placeholder='Enter IFSC Code' name='ifscCode' onChange={handleChnage} />
                        {/* <InputBoxAlphaNumarics value={sendForm.investorBankDetails.ifscCode} placeholder='Enter IFSC Code' name='ifscCode'  setSendForm={setSendForm} range={15} /> */}
                   
                   </div>



                </div>
            </div>



            <div className='container-fluid landformconatiner p-4 mt-4' id="conatinerId">
                <PageHeading pagename={"Documents"} />

                <div className='d-flex flex-column gap-1 pt-3 col-4'>
                    <label className='form-label'>Select Proof Type</label>
                    <select className='form-input-box form-select pe-5' value={documentTypeId} onChange={(e) => { setDocumentTypeId(e.target.value) }}>
                        <option value="-1">--Select Proof Type--</option>
                        {
                            landDocumentTypeList && landDocumentTypeList.map((val, ind) => {
                                return (
                                    <option value={val.id}>{val.documentType}</option>
                                )
                            })
                        }
                    </select>
                </div>
                <FileUpload setAllImage={setAllImage} allImage={allImage} name="Investor Image"/>
            </div>

            <div className='d-flex flex-row justify-content-md-end pt-2 pb-2'>
                {
                    isUpdate === 0 ?

                        <button className='btn export-save-btn' onClick={handleSave}>Save Details</button>
                        :
                        <button className='btn export-save-btn' onClick={handleUpdate}>Update Details</button>

                }
            </div>

            {
                loader === 1 ? <Loader show={loader} /> : ""
            }
            {
                sideToster.show === 1 ?
                    <SideMessage handleShow={setSideToster} show={sideToster.show} type={sideToster.type} message={sideToster.message} />
                    : ""
            }

        </>
    )
}
