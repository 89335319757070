import React, { useEffect, useState } from 'react'
import Logo from "../assets/images/logo.png"
import avatar from "../assets/images/avatar.png"
import { Link, useNavigate } from 'react-router-dom'
export default function Navbar(props) {
    const [user, setUser] = useState({});

    useEffect(() => {
        const userinfo = window.sessionStorage.getItem("userLoginData");
        if (userinfo) {
            try {
                const userLoginData = JSON.parse(userinfo);
                if (userLoginData && userLoginData.userData) {
                    const userData = userLoginData.userData;
                    setUser(userData);
                }
            } catch (error) {
                console.error("Error parsing JSON:", error);
            }
        } else {
            console.error("User info is null or undefined");
        }
    }, []);
    let navigate = useNavigate()

    let handleLogout = () => {
        window.sessionStorage.clear()
        navigate("/")
    }

    return (
        <nav className="navbar navbar-expand-lg bg-light ps-3 pe-3">
            <div className="container-fluid gap-2">
                {/* <button className="navbar-toggler d-flex" type="button" onClick={props.handleOnClick} >
                    <img src={Logo} width="30px" height="30px" />
                </button> */}
                <Link className="navbar-toggler d-flex" type="button"  to="/allproject/">
                    {/* <span className="navbar-toggler-icon"></span> */}
                    <img src={Logo} width="30px" height="30px" />
                </Link>
                <button className="navbar-toggler float-xs-right ms-auto" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarTogglerDemo01">

                    <div className='d-flex flex-column'>
                        <span className="brand-name">VIFA Developers</span>
                        <span className='brand-tagline'>Developing Better LifeStyle</span>
                    </div>

                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        {/* <li className="nav-item">
                            <a className="nav-link active" aria-current="page" href="#">Home</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#">Link</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link disabled">Disabled</a>
                        </li> */}
                    </ul>
                    <div className='d-flex flex-column flex-md-row gap-3 justify-content-center align-items-md-center'>
                        {/* <div className="searchbox" role="search">
                            <input className="form-control me-2 " type="search" placeholder="Search..." aria-label="Search" />
                            <i className="bi bi-search search-icon"></i>
                        </div> */}
                        {/* <div className='d-flex flex-row justify-content-center align-items-center' style={{ backgroundColor: "#F5F6F7", width: "35px", height: "35px", borderRadius: "15px" }}>
                            <i className="bi bi-bell fs-5" style={{ fontWeight: "15px" }}></i>
                        </div> */}
                        <div className='d-flex flex-row gap-2 justify-content-center align-items-center dropdown'>
                            <div className='avatar'>
                                <img src={avatar} title='avtar' alt="avtar" />
                            </div>
                           
                            <span data-bs-toggle="dropdown" className='d-flex flex-row justify-content-between align-items-center gap-1 pointer'>
                            <span>{user.name}</span>
                                 <i className="fa-solid fa-angle-down"></i>
                                 </span>
                            <ul className="dropdown-menu mr-3">
                                <li><Link className="dropdown-item" href="#">View Profile</Link></li>
                                <li><Link className="dropdown-item" href="#">Change Passowrd</Link></li>
                                <li onClick={handleLogout}><Link className="dropdown-item" href="#">Logout <i className="bi bi-box-arrow-right"></i></Link></li>
                            </ul>
                        </div>

                    </div>

                </div>
            </div>
        </nav>
    )
}
